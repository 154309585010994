import React, { useEffect, useState } from "react";

import {
  Button,
  Space,
  Modal,
  Input,
  Form,
  Checkbox,
  message,
  Select,
  Collapse,
  Descriptions,
  Badge,
} from "antd";

import {
  GetSchools,
  GetRowers,
  GetRaces,
  GetEventEntries,
  GetPenaltyCards,
  AddNewPenaltyCard,
  DeletePenlatyCard,
} from "../../APIManager";

import redCardIcon from "../../Static/redcardicon.png";
import yellowCardIcon from "../../Static/yellowcardicon.png";

const { Panel } = Collapse;

const PenaltyCardsPage = () => {
  //API Data
  const [schools, SetSchools] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [races, SetRaces] = useState([]);
  const [eventEntries, SetEventEntries] = useState([]);
  const [penaltyCards, SetPenaltyCards] = useState([]);

  //UI Control
  const [addCardModalVisiible, SetAddCardModalVisible] = useState(false);

  useEffect(() => {
    FetchData();
  }, []);

  function FetchData() {
    GetSchools().then((res) => {
      SetSchools(res.data);
    });
    GetRowers().then((res) => {
      SetRowers(res.data);
    });
    GetRaces().then((res) => {
      SetRaces(res.data);
    });
    GetEventEntries().then((res) => {
      SetEventEntries(res.data);
    });

    GetPenaltyCards().then((res) => {
      SetPenaltyCards(res.data);
    });
  }

  function OnCardDelete(cardID) {
    DeletePenlatyCard(cardID).then((res) => {
      FetchData();
    });
  }

  return (
    <>
      <AddCardModal
        modalVisible={addCardModalVisiible}
        OnCancel={() => {
          SetAddCardModalVisible(false);
        }}
        rowers={rowers}
        schools={schools}
        races={races}
        eventEntries={eventEntries}
        FetchData={FetchData}
        OnSubmit={() => {
          FetchData();
          SetAddCardModalVisible(false);
        }}
      />
      <h1>Penalty Cards Page</h1>
      <div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              SetAddCardModalVisible(true);
            }}
          >
            Add Card
          </Button>
        </Space>
      </div>
      <div style={{ width: "100%" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Collapse accordion>
            {penaltyCards.map((card, index) => (
              <Panel
                key={`Penalty Card Panel ${index}`}
                header={
                  <>
                    <img
                      style={{ width: "2em" /* transform: "rotate(9deg)" */ }}
                      src={
                        card.Type == "Red Card" ? redCardIcon : yellowCardIcon
                      }
                    />
                    {card.Type} awarded to{" "}
                    {card.Assignment == "Rower"
                      ? card.Rower.Name
                      : card.School.schoolinitials}{" "}
                    for{" "}
                    {card.Application == "Specific Event"
                      ? card.EventName
                      : card.Application == "Specific Race"
                      ? card.RaceName
                      : "All Events"}
                  </>
                }
              >
                <Descriptions bordered>
                  <Descriptions.Item label="Card Type">
                    {card.Type}
                  </Descriptions.Item>
                  <Descriptions.Item label="Assigned to">
                    {card.Assignment}
                  </Descriptions.Item>
                  <Descriptions.Item label="Applies to">
                    {card.Application ? card.Application : "All Events"}
                  </Descriptions.Item>
                  {card.School && (
                    <Descriptions.Item label="School" span={3}>
                      {card.School.schoolname}
                    </Descriptions.Item>
                  )}
                  {card.Rower && (
                    <Descriptions.Item label="Rower" span={2}>
                      {card.Rower.Name}
                    </Descriptions.Item>
                  )}
                  {card.Rower && (
                    <Descriptions.Item label="School" span={2}>
                      {
                        schools.filter((s) => s._id == card.Rower.Team)[0]
                          ?.schoolname
                      }
                    </Descriptions.Item>
                  )}
                  {card.RaceName && (
                    <Descriptions.Item label="RaceName" span={3}>
                      {card.RaceName}
                    </Descriptions.Item>
                  )}
                  {card.EventName && (
                    <Descriptions.Item label="EventName" span={3}>
                      {card.EventName}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Reason" span={6}>
                    {card.Reason.split("\n").map((str) => (
                      <p>{str}</p>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item label="Signed By">
                    {card.SignedBy}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date and Time">
                    {`${new Date(card.createdAt).toDateString(
                      "en-US"
                    )} ${new Date(card.createdAt).toLocaleTimeString("en-US")}`}
                  </Descriptions.Item>
                </Descriptions>{" "}
                <br />
                <Button
                  onClick={() => {
                    OnCardDelete(card._id);
                  }}
                >
                  Delete
                </Button>
              </Panel>
            ))}
          </Collapse>
        </Space>
      </div>
    </>
  );
};

const AddCardModal = ({
  modalVisible,
  OnSubmit,
  OnCancel,
  rowers,
  schools,
  races,
  eventEntries,
}) => {
  const [form] = Form.useForm();

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const [assignType, SetAssignType] = useState("");
  const [application, SetApplication] = useState("");
  const [selectedRowerID, SetSelectedRowerID] = useState("");
  const [selectedSchoolID, SetSelectedSchoolID] = useState("");

  const [requiredFields, SetRequiredFields] = useState([
    "CardType",
    "AssignType",
  ]);

  const handleFormValuesChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];

    console.log(fieldName);

    if (fieldName === "AssignType") {
      const value = changedValues[fieldName];
      SetAssignType(value);
      form.setFieldsValue({
        RowerID: null,
        SchoolID: null,
        Application: null,
        RaceName: null,
        EventName: null,
      });
    }
    if (fieldName === "RowerID") {
      const value = changedValues[fieldName];
      SetSelectedRowerID(value);
      form.setFieldsValue({
        Application: null,
        SchoolID: null,
        RaceName: null,
        EventName: null,
      });
    }

    if (fieldName === "SchoolID") {
      const value = changedValues[fieldName];
      SetSelectedSchoolID(value);
      form.setFieldsValue({
        Application: null,
        RaceName: null,
        EventName: null,
      });
    }

    if (fieldName === "Application") {
      const value = changedValues[fieldName];
      SetApplication(value);
      form.setFieldsValue({
        RaceName: null,
        EventName: null,
      });
    }

    const formFields = form.getFieldsValue();

    var reqFields = ["CardType, AssignType", "Reason", "SignName"];

    if (formFields.assignType == "Rower") {
      reqFields.push("RowerID", "Application");
    } else if (formFields.assignType == "Boat") {
      reqFields.push("SchoolID", "Application");
    }

    //Specific Race Case
    if (formFields.Application == "Specific Race") {
      reqFields.push("RaceName");
    }
    //Specific Event Case
    if (formFields.Application == "Specific Event") {
      reqFields.push("EventName");
    }
    //All Events case - Nothing

    SetRequiredFields(reqFields);
  };

  function OnFinish() {
    const formFields = form.getFieldsValue();
    const {
      CardType,
      AssignType,
      RowerID,
      Application,
      RaceName,
      EventName,
      SchoolID,
      Reason,
      SignName,
    } = formFields;

    AddNewPenaltyCard(
      CardType,
      AssignType,
      Application,
      RaceName,
      EventName,
      Reason,
      SignName,
      RowerID,
      SchoolID
    ).then(() => {
      form.resetFields();
      OnSubmit();
    });
  }

  function GetSchoolFromID(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  function GetRowerRaces(rowerID) {
    if (!rowerID) {
      return [];
    }

    //Get rower
    const rower = rowers.filter((r) => r._id == rowerID)[0];

    //Get all events with rower
    const filteredEntries = eventEntries.filter((e) => {
      if (
        e.Rowers.includes(rowerID) ||
        e.Coxers.includes(rowerID) ||
        e.SubRowers.includes(rowerID) ||
        e.SubCoxers.includes(rowerID)
      ) {
        return true;
      }
      return false;
    });

    console.log(filteredEntries);

    //Get All races with rower and school
    const filteredRaces = races.filter((r) => {
      if (
        filteredEntries.map((e) => e.RaceName).includes(r.EventName) &&
        r.Participants.map((p) => p.school).includes(rower.Team._id)
      ) {
        return true;
      }
      return false;
    });

    return filteredRaces;
  }

  function GetSchoolRaces(schoolID) {
    if (!schoolID) {
      return [];
    }
    console.log(schoolID);

    //Get All races with school
    const filteredRaces = races.filter((r) => {
      if (r.Participants.map((p) => p.school).includes(schoolID)) {
        return true;
      }
      return false;
    });

    return filteredRaces;
  }

  function GetRowerEvents(rowerID) {
    if (!rowerID) {
      return [];
    }

    //Get rower
    const rower = rowers.filter((r) => r._id == rowerID)[0];

    //Get all events with rower
    const filteredEntries = eventEntries.filter((e) => {
      if (
        e.Rowers.includes(rowerID) ||
        e.Coxers.includes(rowerID) ||
        e.SubRowers.includes(rowerID) ||
        e.SubCoxers.includes(rowerID)
      ) {
        return true;
      }
      return false;
    });

    return filteredEntries;
  }

  function GetSchoolEvents(schoolID) {
    if (!schoolID) {
      return [];
    }

    const filteredEntries = eventEntries.filter((e) => e.Team == schoolID);

    return filteredEntries;
  }

  function AllFieldsFilled() {
    const formFields = form.getFieldsValue();
    var isComplete = true;
    try {
      Object.keys(formFields).forEach(function (key, index) {
        if (!formFields[key]) {
          isComplete = false;
        }
      });
    } catch (error) {
      isComplete = false;
      console.log(error);
    }

    return isComplete;
  }

  return (
    <Modal
      title={`Add new penalty card`}
      forceRender
      visible={modalVisible}
      onCancel={OnCancel}
      okText="Add User"
      cancelText="Cancel"
      width="600px"
      okButtonProps={{ hidden: true }}
      // cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={OnFinish}
        //onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={"horizontal"}
        size="large"
        onChange={() => {
          // SetMessage(form.getFieldsValue());
          console.log(form.getFieldsValue());
          //console.log(form.getFieldsError())
          // console.log(form.isFieldsTouched(["SchoolColor2"]));
        }}
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item
          label="Type of Card"
          name="CardType"
          rules={[
            {
              required: true,
              message: "Please enter type of card",
            },
          ]}
        >
          <Select
            options={[
              { value: "Yellow Card", label: "Yellow Card" },
              { value: "Red Card", label: "Red Card" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Assign card to Rower / Boat / School"
          name="AssignType"
          rules={[
            {
              required: true,
              message: "Please assigment type",
            },
          ]}
        >
          <Select
            options={[
              { value: "Rower", label: "Rower" },
              { value: "Boat", label: "Boat" },
              { value: "School", label: "School" },
            ]}
          />
        </Form.Item>
        {assignType == "Rower" && (
          <Form.Item
            label="Select Rower"
            name="RowerID"
            rules={[
              {
                required: true,
                message: "Please select a rower",
              },
            ]}
          >
            <Select
              showSearch
              options={rowers.map((r) => {
                return {
                  value: r._id,
                  label: `${r.Name} (${
                    GetSchoolFromID(r.Team._id).schoolinitials
                  })`,
                };
              })}
              filterOption={(e, j) => {
                return j.label
                  .toLocaleLowerCase()
                  .includes(e.toLocaleLowerCase());
              }}
            />
          </Form.Item>
        )}
        {assignType == "Rower" && (
          <Form.Item
            label="Card applies for Race / Event / All Events"
            name="Application"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Select
              options={[
                { value: "Specific Race", label: "Specific Race" },
                { value: "Specific Event", label: "Specific Event" },
                { value: "All Events", label: "All Events" },
              ]}
            />
          </Form.Item>
        )}
        {assignType == "Rower" && application == "Specific Race" && (
          <Form.Item
            label="Select Race"
            name="RaceName"
            rules={[
              {
                required: true,
                message: "Please select a Race",
              },
            ]}
          >
            <Select
              showSearch
              options={GetRowerRaces(selectedRowerID).map((r) => {
                return {
                  value: r.RaceName,
                  label: `${r.RaceName}`,
                };
              })}
              filterOption={(e, j) => {
                return j.label
                  .toLocaleLowerCase()
                  .includes(e.toLocaleLowerCase());
              }}
            />
          </Form.Item>
        )}
        {assignType == "Rower" && application == "Specific Event" && (
          <Form.Item
            label="Select Event"
            name="EventName"
            rules={[
              {
                required: true,
                message: "Please select an Event",
              },
            ]}
          >
            <Select
              showSearch
              options={GetRowerEvents(selectedRowerID).map((r) => {
                return {
                  value: r.RaceName,
                  label: `${r.RaceName}`,
                };
              })}
              filterOption={(e, j) => {
                return j.label
                  .toLocaleLowerCase()
                  .includes(e.toLocaleLowerCase());
              }}
            />
          </Form.Item>
        )}
        {(assignType == "Boat" || assignType == "School") && (
          <Form.Item
            label="Select School"
            name="SchoolID"
            rules={[
              {
                required: true,
                message: "Please select a school",
              },
            ]}
          >
            <Select
              showSearch
              options={schools.map((s) => {
                return {
                  value: s._id,
                  label: `${s.schoolname}`,
                };
              })}
              filterOption={(e, j) => {
                return j.label
                  .toLocaleLowerCase()
                  .includes(e.toLocaleLowerCase());
              }}
            />
          </Form.Item>
        )}
        {assignType == "Boat" && (
          <Form.Item
            label="Card applies for Race / Event"
            name="Application"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Select
              options={[
                { value: "Specific Race", label: "Specific Race" },
                { value: "Specific Event", label: "Specific Event" },
              ]}
            />
          </Form.Item>
        )}

        {assignType == "Boat" && application == "Specific Race" && (
          <Form.Item
            label="Select Race"
            name="RaceName"
            rules={[
              {
                required: true,
                message: "Please select a Race",
              },
            ]}
          >
            <Select
              showSearch
              options={GetSchoolRaces(selectedSchoolID).map((r) => {
                return {
                  value: r.RaceName,
                  label: `${r.RaceName}`,
                };
              })}
              filterOption={(e, j) => {
                return j.label
                  .toLocaleLowerCase()
                  .includes(e.toLocaleLowerCase());
              }}
            />
          </Form.Item>
        )}
        {assignType == "Boat" && application == "Specific Event" && (
          <Form.Item
            label="Select Event"
            name="EventName"
            rules={[
              {
                required: true,
                message: "Please select an Event",
              },
            ]}
          >
            <Select
              showSearch
              options={GetSchoolEvents(selectedSchoolID).map((r) => {
                return {
                  value: r.RaceName,
                  label: `${r.RaceName}`,
                };
              })}
              filterOption={(e, j) => {
                return j.label
                  .toLocaleLowerCase()
                  .includes(e.toLocaleLowerCase());
              }}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Describe reason for awarding penalty card"
          labelCol={{ span: 24 }}
          name="Reason"
          rules={[
            {
              required: true,
              message: "Please describe the reason",
            },
          ]}
        >
          <Input.TextArea spellCheck autoSize />
        </Form.Item>
        <Form.Item
          label="Sign your name "
          name="SignName"
          rules={[
            {
              required: true,
              message: "Please sign your name below",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            // console.log(form.isFieldsTouched(["Declration"]))
            return (
              <Button
                size={"large"}
                block
                type="primary"
                htmlType="submit"
                disabled={!AllFieldsFilled()}
              >
                Submit
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PenaltyCardsPage;

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      // console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}
