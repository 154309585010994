import React from 'react'
import styles from "./AppHeader.module.css"
import KBCIcon from "../../Static/isr_logo.png";
export default function AppHeader() {
    return (
        <div className = {styles.MainBar}>
            <img src = {KBCIcon}/><span className = {styles.AppHeading}>ISR Admin </span>
        </div>
    )
}
