import React, { useState, useEffect } from "react";
import styles from "./SetupNewEvent.module.css";
import {
  GetSchools,
  GetRaces,
  GetEventEntries,
  AddNewRaces,
} from "../../APIManager";

import RaceConfigs from "../Test/RaceConfigs";

import WheelComponent from "react-wheel-of-prizes";

import { GetRaceAgeGroup, GetRaceClass, GetRaceGender } from "../../utility";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  message,
  Select,
  Card,
} from "antd";

import { ListOfEvents } from "../../constants";

export default function SetupNewIndoorEvent() {
  const [schools, SetSchools] = useState([]);
  const [races, SetRaces] = useState([]);
  const [eventEntries, SetEventEntries] = useState([]);

  const [splitRaces, SetSplitRaces] = useState([]);

  useEffect(() => {
    GetSchools().then((Res) => {
      SetSchools(Res.data);
    });
    GetRaces().then((Res) => {
      SetRaces(Res.data);
    });

    GetEventEntries().then((res) => {
      SetEventEntries(res.data);
    });
  }, []);

  const [form] = Form.useForm();

  const requiredFields = ["EventName", "Lanes"];

  const UnSetupRaces = ListOfEvents.filter((e) => {
    return (
      !races.map((r) => r.EventName).includes(e) &&
      GetRaceClass(e) == "Indoor Race"
    );
  });

  const [formValues, SetFormValues] = useState({});

  const { EventName, Lanes } = formValues;

  const schoolEntries = GetSchoolEntries();
  function GetSchoolEntries() {
    var entries = eventEntries.filter((e) => e.RaceName == EventName);
    return entries;
  }

  const participatingSchools = schoolEntries?.map((e) => {
    const school = schools.filter((s) => s._id == e.Team)[0];
    return school;
  });

  // console.log(participatingSchools);

  const noOfEntries = schoolEntries?.length;

  const raceConfig = RaceConfigs[`${Lanes}L${noOfEntries}E`];

  const [formStep, SetFormStep] = useState(1);

  function RefreshSplitRaces() {
    if (!EventName || !Lanes || participatingSchools.length == 0) {
      SetSplitRaces([]);
      return;
    }
    var splitschools = chunkArray(
      shuffleArray(participatingSchools),
      Math.ceil(participatingSchools.length / Lanes)
    );

    splitschools.forEach((raceChunk, index) => {
      const RaceName = `${EventName} Race ${(index + 1 + 9)
        .toString(36)
        .toUpperCase()}`;
      const RaceType = `Race ${(index + 1 + 9).toString(36).toUpperCase()}`;
      const AgeGroup = GetRaceAgeGroup(EventName);
      const Gender = GetRaceGender(EventName);
      const Class = GetRaceClass(EventName);
      const Participants = raceChunk.map((c) => {
        return { isPlaceHolder: false, school: c };
      });
      const RaceResults = [];
      const RaceStatus = "Not Scheduled";
      const Date = null;
      splitschools[index] = {
        EventName,
        RaceName,
        RaceType,
        AgeGroup,
        Gender,
        Class,
        Participants,
        RaceResults,
        RaceStatus,
        Date,
      };
    });
    SetSplitRaces([...splitschools]);
  }

  useEffect(() => {
    RefreshSplitRaces();
  }, [EventName, Lanes]);

  return (
    <div>
      <h1 className={styles.Heading}>
        {formStep == 1 ? "Setup a new Indoor Event" : `Setup ${EventName}`}
      </h1>
      <div hidden={formStep != 1} className={styles.Form}>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={() => {
            AddNewRaces(splitRaces).then((res) => {
              window.location.reload();
            });
          }}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
          size="large"
          onValuesChange={() => {
            SetFormValues(form.getFieldsValue());
          }}
        >
          <Form.Item
            label="Event Name"
            name="EventName"
            rules={[
              {
                required: true,
                message: "Please input an Event Name",
              },
              {
                message: "This race has already been setup",
                validator: (_, value) => {
                  if (!races.map((r) => r.EventName).includes(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("This race has already been setup");
                  }
                },
              },
            ]}
          >
            <Select
              showSearch
              options={UnSetupRaces.map((e) => {
                return { value: e, label: e };
              })}
            />
          </Form.Item>
          {EventName && (
            <>
              {" "}
              <hr />
              <div>Number of entries: {noOfEntries} </div> <hr />
            </>
          )}
          <Form.Item
            label="Number of Races"
            name="Lanes"
            rules={[
              {
                required: true,
                message: "Please input number of races",
              },
            ]}
          >
            <Select
              showSearch
              options={[
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
              ]}
            />
          </Form.Item>
          <Button
            onClick={() => {
              RefreshSplitRaces();
            }}
          >
            Randomize
          </Button>
          <br />
          <br />
          <SchoolListCompoenent
            participatingSchools={participatingSchools}
            lanes={Lanes}
            splitRaces={splitRaces}
          />
          <br />
          <Form.Item shouldUpdate={true}>
            {() => {
              // console.log(form.isFieldsTouched(["Declration"]))
              return (
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !requiredFieldsTouched(form, requiredFields) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  Add Race(s)
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      // console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}
function chunkArray(arr, chunkSize) {
  if (chunkSize <= 0) throw "Invalid chunk size";
  var R = [];
  for (var i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
}

function shuffleArray(array) {
  var newArray = array;
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

const SchoolListCompoenent = ({ participatingSchools, lanes, splitRaces }) => {
  if (!lanes) {
    return (
      <div>
        {participatingSchools?.map((e) => {
          return <div>{e.username}</div>;
        })}
      </div>
    );
  } else {
    if (!splitRaces) {
      return "";
    }

    return (
      <div className={styles.RaceCardContainer}>
        {splitRaces.map((race) => {
          console.log(race.Participants);
          return (
            <Card title={race.RaceName}>
              {race.Participants.map((s) => {
                return <p>{s.school.schoolname}</p>;
              })}
            </Card>
          );
        })}
      </div>
    );
  }
};
