import React from "react";

import {
  Modal,
  Tag,
  Input,
  Button,
  Select,
  Form,
  Popconfirm,
  Card,
  message,
  Space,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useState, useEffect } from "react";
import { RaceRowerFormats } from "../../constants";
import styles from "./PontoonSheetModal.module.css";

import {
  LockPontoonSheet,
  LockSchoolPontoonSheet,
  GetCoxWeights,
  GetPenaltyCards,
} from "../../APIManager";

import redCardIcon from "../../Static/redcardicon.png";
import yellowCardIcon from "../../Static/yellowcardicon.png";

const { confirm } = Modal;

export default function NewPontoonSheetModal({
  modalVisible,
  onCloseModal,
  race,
  schools,
  entries,
  day,
}) {
  const [coxWeights, SetCoxWeights] = useState([]);
  const [penaltyCards, SetPenaltyCards] = useState([]);

  const isLocked = race.PontoonSheetLocked;

  const [markedRowers, SetMarkedRowers] = useState(
    race.PontoonSheet ? race.PontoonSheet : []
  );

  console.log(race);

  //const [markedRowers, SetMarkedRowers] = useState([]);

  useEffect(() => {
    SetMarkedRowers(race.PontoonSheet ? race.PontoonSheet : []);
    GetCoxWeights(day).then((res) => {
      SetCoxWeights(res.data);
    });

    GetPenaltyCards().then((res) => {
      SetPenaltyCards(res.data);
    });
  }, [modalVisible]);

  function GetSchool(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  if (!race || !schools[0] || !entries[0]) {
    return <></>;
  }

  function IsRowerDisabled(rower, entry) {
    const rID = rower._id;
    const type = rower.fieldType.includes("Sub") ? "Sub" : "Rower/Cox";
    const subType = rower.fieldType.includes("Cox") ? "Cox" : "Rowers";
    var isDisabled;
    for (let i = 0; i < entry.Substitutions.length; i++) {
      const s = entry.Substitutions[i];
      if (rID == s.rID) {
        return { dis: true };
      }
      if (rID == s.sID) {
        return {
          dis: false,
          pos: GetSubPosition(entry, s.sID, s.rID, subType),
        };
      }
    }
    if (type == "Sub") {
      return { dis: true };
    } else {
      return { dis: false };
    }
  }

  function GetSubPosition(entry, sID, rID, rType) {
    if (rType == "Rowers") {
      for (let i = 0; i < entry.Rowers.length; i++) {
        if (entry.Rowers[i]._id == rID) {
          return `${GetFields(race.RaceName)[rType][i]} (Sub)`;
        }
      }
    } else {
      if (rType == "Cox") {
        for (let i = 0; i < entry.Rowers.length; i++) {
          if (entry.Rowers[i]._id == rID) {
            return `${GetFields(race.RaceName)[rType][i]} (Sub Cox)`;
          }
        }
      }
    }
  }

  function OnRowerClicked(rower) {
    const { IsMarked, _id, positionName } = rower;
    if (isLocked) {
      return;
    }
    var newMarkedList = [...markedRowers];
    if (IsMarked) {
      newMarkedList = newMarkedList.filter((r) => r.id != _id);
    } else {
      newMarkedList.push({ id: _id, position: positionName });
    }
    SetMarkedRowers(newMarkedList);
  }

  var rowers = [];
  entries.forEach((entry) => {
    entry.Rowers.forEach((rower, index) => {
      rowers.push({
        ...rower,
        SchoolID: entry.Team,
        fieldType: "Rowers",
        fieldPosIndex: index,
        positionName: GetFields(race.RaceName)["Rowers"][index],
      });
    });
    entry.Coxers.forEach((rower, index) => {
      rowers.push({
        ...rower,
        SchoolID: entry.Team,
        fieldType: "Cox",
        fieldPosIndex: index,
        positionName: GetFields(race.RaceName)["Cox"][index],
      });
    });
    entry.SubRowers.forEach((rower, index) => {
      rowers.push({
        ...rower,
        SchoolID: entry.Team,
        fieldType: "SubRower",
        fieldPosIndex: index,
        positionName: GetFields(race.RaceName)["SubRower"][index],
      });
    });
    entry.SubCoxers.forEach((rower, index) => {
      rowers.push({
        ...rower,
        SchoolID: entry.Team,
        fieldType: "SubCox",
        fieldPosIndex: index,
        positionName: GetFields(race.RaceName)["SubCox"][index],
      });
    });
  });

  rowers.forEach((rower, index) => {
    //Add isMarked property
    const pontoonRecord = markedRowers.filter((r) => r.id == rower._id);
    const isMarked = pontoonRecord?.length > 0;
    rowers[index].IsMarked = isMarked;

    if (isMarked) {
      rowers[index].positionName = pontoonRecord[0].position;
    }

    var entry = entries.filter(
      (e) => e.RaceName == race.EventName && e.Team == rower.SchoolID
    )[0];
    //Diable incase of sub or substitution
    const isDisabled = IsRowerDisabled(rower, entry);
    rowers[index].isDisabled = isDisabled.dis;

    //Set Position from pontoon record if sheet is already locked
    if (isLocked && !isDisabled) {
      rowers[index].positionName = pontoonRecord[0].position;
    }
    //Set Position for sub if subbed
    else if (isDisabled?.pos) {
      rowers[index].positionName = isDisabled.pos;
    }

    //Set Cox Weights
    var coxWeight;
    var isCox = rower.positionName == "Cox" || rower.positionName == "SubCox";
    rowers[index].isCox = isCox;
    if (isCox) {
      coxWeight = coxWeights.filter((w) => w.Rower == rower._id)[0];
      rowers[index].coxWeight = coxWeight;
    }

    //Set Penalty Cards
    var cards = penaltyCards.filter((c) => {
      if (c.Assignment != "Rower") {
        return false;
      }
      if (
        c.Application == "All Events" ||
        (c.Application == "Specific Event" && c.EventName == race.EventName) ||
        (c.Application == "Specific Race" && c.RaceName == race.RaceName)
      ) {
        if (String(c.Rower._id) == String(rower._id)) {
          return true;
        }
      }
      return false;
    });
    rowers[index].Cards = cards;
  });

  var schoolPanels = [];
  race.Participants.forEach((p) => {
    var entry = entries.filter(
      (e) => e.RaceName == race.EventName && e.Team == GetSchool(p.school)._id
    )[0];
    var school = GetSchool(p.school);

    var cards = penaltyCards.filter((c) => {
      if (c.Assignment != "School" && c.Assignment != "Boat") {
        return false;
      }
      if (
        c.Application == "All Events" ||
        (c.Application == "Specific Event" && c.EventName == race.EventName) ||
        (c.Application == "Specific Race" && c.RaceName == race.RaceName)
      ) {
        if (String(c.School._id) == String(school._id)) {
          return true;
        }
      }
      return false;
    });

    schoolPanels.push({
      school: school,
      rowers: rowers.filter((r) => r.SchoolID == school._id),
      cards: cards,
      isDisqualified: p.isDisqualified
    });
  });

  function OnSubmitPontoonSheet() {
    var unMarkedSchools = [];
    schoolPanels.forEach((school) => {
      school.rowers.forEach((rower) => {
        if (!rower.IsMarked && !rower.isDisabled) {
          if (
            unMarkedSchools.filter((s) => s._id == school.school._id).length ==
            0
          ) {
            unMarkedSchools.push(school.school);
          }
        }
      });
    });

    if (unMarkedSchools.length > 0) {
      confirm({
        title: "Submit pontoon sheet with missing rowers",
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            The following teams are marked incomplete and will trigger automatic{" "}
            <strong> disqualification</strong>. Do you want to continue?
            <ul>
              {unMarkedSchools.map((s) => (
                <li>{s.schoolname}</li>
              ))}
            </ul>
          </>
        ),
        onOk() {
          LockPontoonSheet({
            RaceName: race.RaceName,
            EventName: race.EventName,
            rowers: markedRowers,
            DisqualifiedTeams: unMarkedSchools.map((s) => s._id),
          }).then(() => {
            message.success("Pontoon Sheet Locked");
            onCloseModal();
          });
        },
        onCancel() {},
        zIndex: 10001,
      });
    } else {
      confirm({
        title: "Submit pontoon sheet",
        icon: <ExclamationCircleOutlined />,
        content: <>Are you sure you want to lock the pontoon sheet?</>,
        onOk() {
          LockPontoonSheet({
            RaceName: race.RaceName,
            EventName: race.EventName,
            rowers: markedRowers,
            DisqualifiedTeams: unMarkedSchools.map((s) => s._id),
          }).then(() => {
            message.success("Pontoon Sheet Locked");
            onCloseModal();
          });
        },
        onCancel() {},
        zIndex: 10001,
      });
    }
  }

  function OnLockSchoolPontoonSheet(schoolPanel) {
    const { school, rowers } = schoolPanel;

    const schoolRowersSelected = markedRowers.filter((R) =>
      rowers.map((r) => r._id).includes(R.id)
    );

    LockSchoolPontoonSheet({
      RaceName: race.RaceName,
      EventName: race.EventName,
      rowers: schoolRowersSelected,
      SchoolID: school._id,
    }).then((res) => {
      onCloseModal();
    });
  }

  return (
    <div>
      <Modal
        title={`Pontoon Sheet for ${race.RaceName}`}
        forceRender
        visible={modalVisible}
        onOk={() => {
          OnSubmitPontoonSheet();
        }}
        onCancel={onCloseModal}
        okText="Lock Pontoon Sheet"
        cancelText="Cancel"
        width="1400px"
        okButtonProps={{ hidden: isLocked }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
        zIndex={10000}
      >
        {isLocked && (
          <Tag color="success" style={{ fontSize: "3em", padding: "0.5em" }}>
            Locked
          </Tag>
        )}
        <br />
        <br />
        <div className={styles.PanelContainer}>
          {" "}
          {schoolPanels
            .filter((s) => !s.isDisqualified)
            .map((s) => {
              var isSchoolLocked = race.LockedSchools?.includes(s.school._id);
              return (
                <Card
                  className={styles.PanelCard}
                  title={
                    <div className={styles.SchoolCardHeader}>
                      <div>{s.school.schoolname}</div>
                      {s.cards.map((c, index) => (
                        <img
                          style={{
                            width: "2em" /* transform: "rotate(9deg)" */,
                          }}
                          src={
                            c.Type == "Red Card" ? redCardIcon : yellowCardIcon
                          }
                        />
                      ))}
                      <div>
                        <Button
                          type="dashed"
                          disabled={isSchoolLocked}
                          onClick={() => {
                            OnLockSchoolPontoonSheet(s);
                          }}
                        >
                          {isSchoolLocked ? "Already Locked" : "Lock"}
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <div className={styles.gridContainer}>
                    {s.rowers.map((r) => {
                      return (
                        <RowerBox
                          rower={r}
                          OnClick={OnRowerClicked}
                          isSchoolLocked={isSchoolLocked}
                        />
                      );
                    })}
                  </div>{" "}
                </Card>
              );
            })}
        </div>
      </Modal>
    </div>
  );
}

const RowerBox = ({ rower, OnClick, isSchoolLocked }) => {
  const boxStyle = GetRowerBoxStyleClass(rower);

  return (
    <div
      className={`${styles.gridItem} ${boxStyle}`}
      onClick={() => {
        if (!rower.isDisabled && !isSchoolLocked) {
          OnClick(rower);
        }
      }}
    >
      <div className={styles.RowerPosition}>
        {rower.positionName}
        {rower.Cards.map((c, index) => (
          <img
            style={{ width: "2em" /* transform: "rotate(9deg)" */ }}
            src={c.Type == "Red Card" ? redCardIcon : yellowCardIcon}
          />
        ))}
      </div>
      <div>
        <img
          className={`${styles.RowerImage}`}
          src={`https://api.admin.isr.kbc.org.pk/DownloadFile/${encodeURIComponent(
            rower.Photograph
          )}`}

          /*  src={
            "https://c8.alamy.com/comp/2JKFG46/passport-document-id-photo-business-man-portrait-2JKFG46.jpg"
          } */
        />
      </div>
      <div className={styles.RowerName}>{rower.Name}</div>
      <div
        className={styles.RowerName}
        hidden={!rower.isCox}
        style={{ color: rower.coxWeight ? "" : "red" }}
      >
        {rower.coxWeight
          ? `Sandbags: ${rower.coxWeight.Sandbags}KG`
          : "NOT WEIGHED"}
      </div>
    </div>
  );
};

function GetRowerBoxStyleClass(rower) {
  if (rower.IsMarked) {
    return styles.MarkedRower;
  }
  if (rower.isDisabled) {
    return `${styles.AbsentRower} ${styles.GrayImage}`;
  }

  return styles.AbsentRower;
}

function GetFields(race) {
  var keys = Object.keys(RaceRowerFormats);
  var fields;
  keys.forEach((type) => {
    if (race.includes(type)) {
      fields = RaceRowerFormats[type];
      return;
    }
  });
  return fields ? fields : { Rowers: [], Cox: [], SubRower: [], SubCox: [] };
}
