import React, { useState, useEffect, forwardRef } from "react";
import { useContext } from "react";
import { UserContext } from "../../UserContext";

import styles from "./RacesSchedule.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FlipMove from "react-flip-move";

import {
  ClockCircleOutlined,
  StopOutlined,
  WarningTwoTone,
  InfoCircleOutlined,
  TrophyOutlined,
} from "@ant-design/icons";

import {
  GetSchools,
  GetRaces,
  GetEventEntries,
  GetSchedules,
  InsertRaceToSchedule,
  ChangeRaceTime,
  RemoveRaceFromSchedule,
  InsertBreakToSchedule,
  RemoveBreakFromSchedule,
} from "../../APIManager";
import { unstable_batchedUpdates } from "react-dom";

import {
  Card,
  Tag,
  Collapse,
  Space,
  Modal,
  Badge,
  Tooltip,
  Popconfirm,
  Button,
  message,
  Divider,
  Checkbox,
} from "antd";

import ChooseDayInsertModal from "./ChooseDayInsertModal";
import ChangeTimeModal from "./ChangeTimeModal";
import InsertBreakModal from "./InsertBreakModal";
import RaceDetailsModal from "./RaceDetailsModal";
import ChooseRaceResultsModal from "./ChooseRaceResultsModal";
import RaceProgressionDiagramModal from "./RaceProgressionDiagramModal";

import { GetDefaultRaceDuration, GetRaceClass } from "../../utility";

const { confirm } = Modal;
const { Panel } = Collapse;

export default function RacesSchedule() {
  const [races, SetRaces] = useState([]);
  const [schedules, SetSchedules] = useState([]);
  const [eventEntries, SetEventEntries] = useState([]);
  const [schools, SetSchools] = useState([]);
  const [user, setUser] = useContext(UserContext);

  const [viewOptions, SetViewOptions] = useState([]);

  const isReadOnly =
    !user.allowedmodules.includes("Full Schedule Access") &&
    !user.allowedmodules.includes("All");

  useEffect(() => {
    FetchData();
    GetEventEntries().then((res) => {
      SetEventEntries(res.data);
    });
    GetSchools().then((res) => {
      SetSchools(res.data);
    });
  }, []);

  const viewOptionsCheckboxes = [
    { label: "Un-Scheduled Panel", value: "UnScheduledPanel" },
    { label: "Pear", value: "Pear" },
    { label: "Orange", value: "Orange" },
  ];

  function FetchData() {
    GetRaces().then((res) => {
      SetRaces(res.data);
      GetSchedules().then((res) => {
        let sortOrder = [
          "28th Jan",
          "2nd Feb",
          "3rd Feb",
          "4th Feb",
          "5th Feb",
        ];
        let days = [...res.data];
        days.sort((a, b) => {
          return sortOrder.indexOf(a.Day) - sortOrder.indexOf(b.Day);
        });
        SetSchedules(days);
      });
    });
  }

  var scheduleWithRaceData = schedules;
  scheduleWithRaceData.forEach((s, i) => {
    s.Schedule.forEach((race, j) => {
      if (race.Name == "Break") {
        return;
      }

      var raceData = races.filter((r) => r.RaceName == race.RaceName)[0];
      scheduleWithRaceData[i].Schedule[j] = {
        ...raceData,
        ...scheduleWithRaceData[i].Schedule[j],
      };
    });
  });

  const unScheduledRaces = races.filter((r) => r.RaceStatus == "Not Scheduled");

  if (!races.length > 0 || !schedules.length > 0 || !eventEntries.length > 0) {
    return "Loading";
  }

  var allWaterRacesWithoutBreaks = [];

  schedules.forEach((s) => {
    var dayschedule = s.Schedule.filter(
      (r) => r.RaceName != "Break" && GetRaceClass(r.RaceName) == "Water Race"
    );
    dayschedule.forEach((r) => {
      allWaterRacesWithoutBreaks.push(r);
    });
  });

  for (let i = 0; i < schedules.length; i++) {
    for (let j = 0; j < schedules[i].Schedule.length; j++) {
      if (
        schedules[i].Schedule[j].RaceName == "Break" ||
        GetRaceClass(schedules[i].Schedule[j].RaceName) != "Water Race"
      ) {
        continue;
      }
      schedules[i].Schedule[j].RaceNumber =
        allWaterRacesWithoutBreaks.findIndex(
          (r) => r.RaceName == schedules[i].Schedule[j].RaceName
        ) + 1;
    }
  }

  var allIndoorRacesWithoutBreaks = [];

  schedules.forEach((s) => {
    var dayschedule = s.Schedule.filter(
      (r) => r.RaceName != "Break" && GetRaceClass(r.RaceName) == "Indoor Race"
    );
    dayschedule.forEach((r) => {
      allIndoorRacesWithoutBreaks.push(r);
    });
  });

  for (let i = 0; i < schedules.length; i++) {
    for (let j = 0; j < schedules[i].Schedule.length; j++) {
      if (
        schedules[i].Schedule[j].RaceName == "Break" ||
        GetRaceClass(schedules[i].Schedule[j].RaceName) != "Indoor Race"
      ) {
        continue;
      }
      schedules[i].Schedule[j].RaceNumber =
        allIndoorRacesWithoutBreaks.findIndex(
          (r) => r.RaceName == schedules[i].Schedule[j].RaceName
        ) + 1;
    }
  }

  return (
    <div>
      <Divider>
        {" "}
        <strong style={{ fontSize: "1.2em" }}>Race Schedule Editor</strong>
      </Divider>
      <Space>
        <Checkbox.Group
          options={viewOptionsCheckboxes}
          //defaultValue={}
          onChange={(e) => {
            SetViewOptions(e);
          }}
        />
      </Space>
      <div hidden={!viewOptions.includes("UnScheduledPanel")}>
        <UnScheduledRacesPanel
          races={unScheduledRaces}
          schedules={schedules}
          OnRefresh={FetchData}
          isReadOnly={isReadOnly}
        />
      </div>
      <br />

      <RaceProgressionDiagramModal modalVisible={true} eventName="" />

      {schedules?.map((s) => {
        return (
          <div>
            <DaySchedulePanel
              races={
                scheduleWithRaceData.filter(
                  (schedule) => schedule.Day == s.Day
                )[0].Schedule
              }
              dayname={s.Day}
              OnRaceTimeChange={(
                selectedRaceID,
                dayname,
                newTimeStart,
                newTimeEnd
              ) => {
                ChangeRaceTime(
                  selectedRaceID,
                  dayname,
                  newTimeStart,
                  newTimeEnd
                ).then((res) => {
                  FetchData();
                });
              }}
              OnRefreshData={FetchData}
              EventEntries={eventEntries}
              DayDate={s.Date}
              schools={schools}
              isReadOnly={isReadOnly}
            />
            <br />
          </div>
        );
      })}
    </div>
  );
}

const UnScheduledRacesPanel = ({ races, schedules, OnRefresh, isReadOnly }) => {
  const [insertRaceModalOpen, SetInsertRaceModalOpen] = useState(false);
  const [selectedRace, SetSelectedRace] = useState("");
  const [waitingForResponse, SetWaitingForResponse] = useState(false);

  return (
    <div>
      <ChooseDayInsertModal
        modalVisible={insertRaceModalOpen}
        onCloseModal={() => {
          SetInsertRaceModalOpen(false);
        }}
        RaceName={selectedRace}
        Days={schedules}
        OnSubmit={(day) => {
          SetWaitingForResponse(true);
          SetInsertRaceModalOpen(false);
          InsertRaceToSchedule(
            selectedRace,
            day,
            GetDefaultRaceDuration(selectedRace)
          ).then(() => {
            SetWaitingForResponse(false);
            OnRefresh();
          });
        }}
      />
      <Collapse
        className={`${styles.PanelCard} ${styles.UnScheduledPanelCard}`}
      >
        <Panel header={"Races Not Yet Scheduled"}>
          {races.map((r) => {
            const badgeColor = GetRaceTypeBadgeColor(r.RaceType);

            return (
              <div className={styles.PanelRow}>
                <div>{r.EventName}</div>
                <div>
                  <Tag
                    style={{
                      fontSize: "1em",
                      height: "1.5em",
                      borderRadius: "10px",
                    }}
                    color={badgeColor}
                  >
                    {r.RaceType}
                  </Tag>
                </div>
                <div>
                  {" "}
                  <Space size="middle">
                    <a
                      hidden={isReadOnly}
                      onClick={() => {
                        if (waitingForResponse) {
                          return;
                        }
                        SetSelectedRace(r.RaceName);
                        SetInsertRaceModalOpen(true);
                      }}
                    >
                      Insert Race
                    </a>
                  </Space>
                </div>
              </div>
            );
          })}
        </Panel>
      </Collapse>
    </div>
  );
};

const DaySchedulePanel = ({
  dayname,
  races,
  OnRaceTimeChange,
  OnRefreshData,
  EventEntries,
  DayDate,
  schools,
  isReadOnly,
}) => {
  const [selectedRace, SetSelectedRace] = useState(""); //Is set to ID for breaks, RaceName otherwise, yes i know its messed up
  const [selectedRaceID, SetSelectedRaceID] = useState("");
  const [timeModalOpen, SetTimeModalOpen] = useState(false);
  const [insertBreakModalOpen, SetInsertBreakModalOpen] = useState(false);
  const [breakTimeModalOpen, SetBreakTimeModalOpen] = useState(false);
  const [raceDetailsModalOpen, SetRaceDetailsModalOpen] = useState(false);
  const [resultsModalOpen, SetResultsModalOpen] = useState(false);

  var dayDate = new Date(DayDate);

  /*   useEffect(() => {
    SetOrderedRaces(races);
  }, [races]);
 */

  var racesWithoutBreaks = races.filter((r) => r.RaceName != "Break");

  for (let i = 0; i < races.length; i++) {
    if (races[i].RaceName == "Break") {
      continue;
    }
    races[i].Warnings = [];

    if (i == 0) {
      continue;
    }

    if (races[i - 1].RaceName != "Break") {
      var currentRaceEntries = EventEntries?.filter(
        (e) => e.RaceName == races[i].EventName
      );
      var previousRaceEntries = EventEntries?.filter(
        (e) => e.RaceName == races[i - 1].EventName
      );

      var placeHolderflag = false;

      var currentRaceRowers = [];
      races[i].Participants.forEach((p) => {
        if (p.isPlaceHolder) {
          placeHolderflag = true;
          return;
        }

        var schoolID = p.school;
        var schoolEntry = currentRaceEntries.filter(
          (e) => e.Team == schoolID
        )[0];
        if (!schoolEntry) {
          return;
        }

        currentRaceRowers.push(...schoolEntry.Rowers);
        currentRaceRowers.push(...schoolEntry.Coxers);
      });

      var previousRaceRowers = [];

      races[i - 1]?.Participants.forEach((p) => {
        if (p.isPlaceHolder) {
          placeHolderflag = true;
          return;
        }

        var schoolID = p.school;
        var schoolEntry = previousRaceEntries.filter(
          (e) => e.Team == schoolID
        )[0];

        if (!schoolEntry) {
          return;
        }

        previousRaceRowers.push(...schoolEntry?.Rowers);
        previousRaceRowers.push(...schoolEntry?.Coxers);
      });

      var overlappingRowers = currentRaceRowers.filter((value) =>
        previousRaceRowers.includes(value)
      );
      if (overlappingRowers.length > 0 && placeHolderflag) {
        races[i].Warnings.push(
          `${overlappingRowers.length} or more rowers in this race are also participating in the previous race`
        );
      } else if (overlappingRowers.length > 0) {
        races[i].Warnings.push(
          `${overlappingRowers.length} rowers in this race are also participating in the previous race`
        );
      } /* else if (placeHolderflag) {
        races[i].Warnings.push(
          `There may be some rowers that overlap between this race and the previous race depending on the results`
        );
      } */
    }

    var boattype1RaceBefore = GetBoatType(races[i - 1]?.EventName);
    var boattype2RaceBefore = GetBoatType(races[i - 2]?.EventName);
    var boattypeCurrentRace = GetBoatType(races[i].EventName);

    if (
      (boattypeCurrentRace == boattype1RaceBefore ||
        boattypeCurrentRace == boattype2RaceBefore) &&
      races[i].Class == "Water Race"
    ) {
      /*  races[i].Warnings.push(
        `There should be atleast 2 races between races that use the same boat`
      ); */
    }
  }

  return (
    <div>
      {resultsModalOpen && (
        <ChooseRaceResultsModal
          modalVisible={resultsModalOpen}
          onCloseModal={() => {
            SetResultsModalOpen(false);
          }}
          race={
            selectedRace
              ? races.filter((r) => r.RaceName == selectedRace)[0]
              : ""
          }
          OnSubmit={() => {
            OnRefreshData();
            SetResultsModalOpen(false);
          }}
          schools={schools}
          isReadOnly={isReadOnly}
          key={`Race Results Modal ${selectedRace}`}
        />
      )}
      <RaceDetailsModal
        modalVisible={raceDetailsModalOpen}
        onCloseModal={() => {
          SetRaceDetailsModalOpen(false);
        }}
        race={
          selectedRace ? races.filter((r) => r.RaceName == selectedRace)[0] : ""
        }
        allEventEntries={EventEntries}
        day={dayname}
        schools={schools}
        key={selectedRace}
        RefreshRace={OnRefreshData}
      />
      <InsertBreakModal
        modalVisible={insertBreakModalOpen}
        onCloseModal={() => {
          SetInsertBreakModalOpen(false);
        }}
        OnSubmit={(newTime) => {
          SetInsertBreakModalOpen(false);
          var newTimeStart = new Date(races[0].TimeStart);
          newTimeStart.setHours(newTime.StartHours);
          newTimeStart.setMinutes(newTime.StartMinutes);

          var newTimeEnd = new Date(races[0].TimeEnd);
          newTimeEnd.setHours(newTime.EndHours);
          newTimeEnd.setMinutes(newTime.EndMinutes);

          InsertBreakToSchedule(dayname, newTimeStart, newTimeEnd).then(() => {
            OnRefreshData();
          });
        }}
      />
      <InsertBreakModal
        modalVisible={breakTimeModalOpen}
        onCloseModal={() => {
          SetBreakTimeModalOpen(false);
        }}
        OnSubmit={(newTime) => {
          SetBreakTimeModalOpen(false);

          var newTimeStart = new Date(races[0].TimeStart);
          newTimeStart.setHours(newTime.StartHours);
          newTimeStart.setMinutes(newTime.StartMinutes);

          var newTimeEnd = new Date(races[0].TimeEnd);
          newTimeEnd.setHours(newTime.EndHours);
          newTimeEnd.setMinutes(newTime.EndMinutes);
          OnRaceTimeChange(selectedRace, dayname, newTimeStart, newTimeEnd);
        }}
      />
      <ChangeTimeModal
        modalVisible={timeModalOpen}
        onCloseModal={() => {
          SetTimeModalOpen(false);
        }}
        OnSubmit={(newTime) => {
          SetTimeModalOpen(false);
          var race = races.filter((r) => r.RaceName == selectedRace)[0];

          var newTimeStart = new Date(race.TimeStart);
          newTimeStart.setHours(newTime.StartHours);
          newTimeStart.setMinutes(newTime.StartMinutes);

          var newTimeEnd = new Date(race.TimeEnd);
          newTimeEnd.setHours(newTime.EndHours);
          newTimeEnd.setMinutes(newTime.EndMinutes);
          OnRaceTimeChange(race._id, dayname, newTimeStart, newTimeEnd);
        }}
        racename={selectedRace}
      />

      <Collapse className={styles.PanelCard}>
        <Panel
          header={`Schedule for ${dayname} (${dayDate.toLocaleDateString(
            "en-pk",
            {
              weekday: "long",
            }
          )})`}
        >
          <FlipMove>
            {races.map((r) => (
              <ScheduleRow
                r={r}
                SetSelectedRace={SetSelectedRace}
                SetTimeModalOpen={SetTimeModalOpen}
                OnRaceRemove={(racename) => {
                  RemoveRaceFromSchedule(racename, dayname).then(() => {
                    OnRefreshData();
                  });
                }}
                SetBreakTimeModalOpen={SetBreakTimeModalOpen}
                OnBreakRemove={(breakID) => {
                  RemoveBreakFromSchedule(breakID, dayname).then(() => {
                    OnRefreshData();
                  });
                }}
                OnDetailsClicked={(racename) => {
                  SetSelectedRace(racename);
                  SetRaceDetailsModalOpen(true);
                }}
                OnResultsClicked={(racename) => {
                  SetSelectedRace(racename);
                  SetResultsModalOpen(true);
                }}
                isReadOnly={isReadOnly}
                key={r._id}
              />
            ))}
          </FlipMove>
          <Button
            type="primary"
            onClick={() => {
              SetInsertBreakModalOpen(true);
            }}
            hidden={isReadOnly}
          >
            Insert Break
          </Button>
        </Panel>
      </Collapse>
    </div>
  );
};

function GetRaceTypeBadgeColor(raceType) {
  if (!raceType) {
    return "";
  }
  if (raceType.includes("Heat")) {
    return "blue";
  }
  if (raceType.includes("Repecharge")) {
    return "magenta";
  }
  if (raceType.includes("Quarter Final")) {
    return "gold";
  }
  if (raceType.includes("Semifinal")) {
    return "green";
  }
  if (raceType.includes("Final")) {
    return "purple";
  }
}

function GetRaceStatusBadgeColor(raceStatus) {
  if (!raceStatus) {
    return "";
  }
  if (raceStatus.includes("Scheduled")) {
    return "cyan";
  }
  if (raceStatus.includes("Started")) {
    return "orange";
  }
  if (raceStatus.includes("Finished")) {
    return "green";
  }
}

export function FormatTime(gmtDate) {
  var date = new Date(gmtDate);

  return date.toLocaleTimeString("en-PK", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Asia/Karachi",
  });
}

function removeByIndex(str, index) {
  return str.slice(0, index) + str.slice(index + 1);
}

const ScheduleRow = forwardRef((props, ref) => {
  const {
    r,
    SetSelectedRace,
    SetTimeModalOpen,
    OnRaceRemove,
    SetBreakTimeModalOpen,
    OnBreakRemove,
    OnDetailsClicked,
    OnResultsClicked,
    isReadOnly,
  } = props;

  const raceTypeBadgeColor = GetRaceTypeBadgeColor(r.RaceType);
  const raceStatusBadgeColor = GetRaceStatusBadgeColor(r.RaceStatus);

  if (r.RaceName == "Break") {
    return (
      <div className={styles.PanelRow} ref={ref} key={r.RaceName}>
        <div>Break</div>
        <div></div>
        <div></div>
        <div>{`${FormatTime(r.TimeStart)} ~ ${FormatTime(r.TimeEnd)}`}</div>
        <div>
          {" "}
          <Space size="middle">
            <Tooltip title="Change Time">
              {" "}
              <a
                onClick={() => {
                  SetSelectedRace(r._id);
                  SetBreakTimeModalOpen(true);
                }}
              >
                <ClockCircleOutlined />
              </a>
            </Tooltip>

            <Tooltip title="Remove Break">
              {" "}
              <Popconfirm
                placement="leftTop"
                title={
                  "Are you sure you want remove this break from the schedule?"
                }
                onConfirm={() => {
                  OnBreakRemove(r._id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <StopOutlined />
                </a>
              </Popconfirm>
            </Tooltip>
          </Space>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.PanelRow} ${
        r?.Warnings?.length > 0 ? styles.Warning : ""
      }`}
      ref={ref}
      key={r._id}
      onClick={() => {
        OnDetailsClicked(r.RaceName);
      }}
    >
      <div>
        {" "}
        <span
          className={`${styles.RaceNumber} ${
            GetRaceClass(r.RaceName) == "Indoor Race"
              ? styles.IndoorRaceNumber
              : ""
          }`}
        >
          {" "}
          {r?.RaceNumber?.toString().padStart(2, "0")}
        </span>{" "}
        {r.EventName}{" "}
        {r.Warnings?.length > 0 && (
          <Tooltip
            title={r.Warnings.map((w) => (
              <p>{w}</p>
            ))}
          >
            <WarningTwoTone style={{ marginLeft: "0.3em" }} />
          </Tooltip>
        )}
      </div>
      <div>
        <Tag
          style={{
            fontSize: "1em",
            height: "1.5em",
            borderRadius: "10px",
          }}
          color={raceTypeBadgeColor}
        >
          {r.RaceType}
        </Tag>
      </div>
      <div>
        {/*  <dotlottie-player
          src="https://lottie.host/b8dd0d20-69a4-410a-8f08-6fdcdfea7224/tVLMap1DA4.json"
          background="transparent"
          speed="1"
          style={{
            width: "4em",
            height: "2em",
            marginLeft: "-1.3em",
            marginTop: "-1.3em",
          }}
          loop
          autoplay
        ></dotlottie-player> */}

        <Tag
          style={{
            fontSize: "1em",
            height: "1.5em",
            borderRadius: "10px",
          }}
          color={raceStatusBadgeColor}
        >
          {r.RaceStatus}
        </Tag>
      </div>
      <div>{`${FormatTime(r.TimeStart)} ~ ${FormatTime(r.TimeEnd)}`}</div>
      <div>
        {" "}
        <Space
          size="middle"
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          {/*    <Tooltip title="Details">
            <a
              onClick={() => {
                OnDetailsClicked(r.RaceName);
              }}
            >
              <InfoCircleOutlined />
            </a>
          </Tooltip> */}
          {!isReadOnly && r.RaceStatus == "Scheduled" && (
            <Tooltip title="Change Time">
              {" "}
              <a
                onClick={(e) => {
                  if (r.RaceStatus == "Finished") {
                    message.info("Finshed race cannot be rescheduled");
                    return;
                  }
                  SetSelectedRace(r.RaceName);
                  SetTimeModalOpen(true);
                }}
              >
                <ClockCircleOutlined />
              </a>
            </Tooltip>
          )}
          <Tooltip title="Results">
            <a
              onClick={(e) => {
                if (IsRaceValidToBeResulted(r)) {
                  OnResultsClicked(r.RaceName);
                } else {
                  message.info(
                    "Results for a previous race need to be entered first"
                  );
                }
              }}
            >
              <TrophyOutlined />
            </a>
          </Tooltip>

          {!isReadOnly && r.RaceStatus == "Scheduled" && (
            <Tooltip title="Remove">
              <Popconfirm
                placement="leftTop"
                title={
                  "Are you sure you want remove this race from the schedule?"
                }
                onConfirm={(e) => {
                  if (r.RaceStatus == "Finished") {
                    message.info("Finshed race cannot be removed");
                    return;
                  }
                  OnRaceRemove(r.RaceName);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a
                  hidden={isReadOnly}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  <StopOutlined />
                </a>
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      </div>
    </div>
  );
});

function GetBoatType(EventName) {
  if (!EventName) {
    return "";
  }

  if (EventName.includes("Eight 8+")) {
    return "Eight 8+";
  }
  if (EventName.includes("Four 4+")) {
    return "Four 4+";
  }
  if (EventName.includes("Scull 2x")) {
    return "Scull 2x";
  }
  if (EventName.includes("Scull 4x")) {
    return "Scull 4x";
  }
  if (EventName.includes("Scull 1x")) {
    return "Scull 1x";
  }
  if (EventName.includes("Pair 2-")) {
    return "Pair 2-";
  }
}

function IsRaceValidToBeResulted(race) {
  var isValid = true;
  race.Participants.forEach((p) => {
    if (p.isPlaceHolder) {
      isValid = false;
    }
  });
  return isValid;
}
