export const RaceRowerFormats = {
  "Single Scull 1X": {
    Rowers: ["Seat 1"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "Double Scull 2X": {
    Rowers: ["Seat 1 (Bow)", "Seat 2 (Stroke)"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "Pair 2-": {
    Rowers: ["Seat 1 (Bow)", "Seat 2 (Stroke)"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "Quadruple Scull 4x": {
    Rowers: ["Seat 1 (Bow)", "Seat 2", "Seat 3", "Seat 4 (Stroke)"],
    Cox: [],
    SubRower: ["Sub #1", "Sub #2"],
    SubCox: [],
  },
  "Coxed Four 4+": {
    Rowers: ["Seat 1 (Bow)", "Seat 2", "Seat 3", "Seat 4 (Stroke)"],
    Cox: ["Cox"],
    SubRower: ["Sub #1", "Sub #2"],
    SubCox: ["Sub Cox"],
  },
  "Coxed Eight 8+": {
    Rowers: [
      "Seat 1 (Bow)",
      "Seat 2",
      "Seat 3",
      "Seat 4",
      "Seat 5",
      "Seat 6",
      "Seat 7",
      "Seat 8 (Stroke)",
    ],
    Cox: ["Cox"],
    SubRower: ["Sub #1", "Sub #2", "Sub #3", "Sub #4"],
    SubCox: ["Sub Cox"],
  },
  "1 x 500m": {
    Rowers: ["Seat 1"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "1 x 2000m": {
    Rowers: ["Seat 1"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "1 x 5000m": {
    Rowers: ["Seat 1"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "2 x 500m Relay": {
    Rowers: ["1st", "2nd"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "2 x 1000m Relay": {
    Rowers: ["1st", "2nd"],
    Cox: [],
    SubRower: ["Sub"],
    SubCox: [],
  },
  "4 x 500m Relay": {
    Rowers: ["1st", "2nd", "3rd", "4th"],
    Cox: [],
    SubRower: ["Sub #1", "Sub #2"],
    SubCox: [],
  },
};

export const ListOfEvents = [
  "Under 25 Boys Single Scull 1X",
  "Under 25 Boys Double Scull 2X",
  "Under 25 Boys Quadruple Scull 4x",
  "Under 25 Boys 1 x 500m",
  "Under 25 Boys 1 x 2000m",
  "Under 25 Boys 2 x 1000m Relay",
  "Under 25 Boys 4 x 500m Relay",
  "Under 21 Boys Single Scull 1X",
  "Under 21 Boys Double Scull 2X",
  "Under 21 Boys Pair 2-",
  "Under 21 Boys Quadruple Scull 4x",
  "Under 21 Boys Coxed Four 4+",
  "Under 21 Boys Coxed Eight 8+",
  "Under 18 Boys Single Scull 1X",
  "Under 18 Boys Double Scull 2X",
  "Under 18 Boys Pair 2-",
  "Under 18 Boys Quadruple Scull 4x",
  "Under 18 Boys Coxed Four 4+",
  "Under 18 Boys Coxed Eight 8+",
  "Under 15 Boys Single Scull 1X",
  "Under 15 Boys Double Scull 2X",
  "Under 15 Boys Quadruple Scull 4x",
  "Under 21 Boys 1 x 500m",
  "Under 21 Boys 1 x 2000m",
  "Under 21 Boys 1 x 5000m",
  "Under 21 Boys 2 x 1000m Relay",
  "Under 21 Boys 4 x 500m Relay",
  "Under 18 Boys 1 x 500m",
  "Under 18 Boys 1 x 2000m",
  "Under 18 Boys 1 x 5000m",
  "Under 18 Boys 2 x 1000m Relay",
  "Under 18 Boys 4 x 500m Relay",
  "Under 15 Boys 1 x 500m",
  "Under 15 Boys 2 x 1000m Relay",
  "Under 15 Boys 4 x 500m Relay",
  "Under 21 Girls Single Scull 1X",
  "Under 21 Girls Double Scull 2X",
  "Under 21 Girls Pair 2-",
  "Under 21 Girls Quadruple Scull 4x",
  "Under 21 Girls Coxed Four 4+",
  "Under 21 Girls Coxed Eight 8+",
  "Under 18 Girls Single Scull 1X",
  "Under 18 Girls Double Scull 2X",
  "Under 18 Girls Pair 2-",
  "Under 18 Girls Quadruple Scull 4x",
  "Under 18 Girls Coxed Four 4+",
  "Under 18 Girls Coxed Eight 8+",
  "Under 15 Girls Single Scull 1X",
  "Under 15 Girls Double Scull 2X",
  "Under 15 Girls Quadruple Scull 4x",
  "Under 21 Girls 1 x 500m",
  "Under 21 Girls 1 x 2000m",
  "Under 21 Girls 1 x 5000m",
  "Under 21 Girls 2 x 1000m Relay",
  "Under 21 Girls 4 x 500m Relay",
  "Under 18 Girls 1 x 500m",
  "Under 18 Girls 1 x 2000m",
  "Under 18 Girls 1 x 5000m",
  "Under 18 Girls 2 x 1000m Relay",
  "Under 18 Girls 4 x 500m Relay",
  "Under 15 Girls 1 x 500m",
  "Under 15 Girls 2 x 1000m Relay",
  "Under 15 Girls 4 x 500m Relay",
  "Under 25 Girls Single Scull 1X",
  "Under 25 Girls Double Scull 2X",
  "Under 25 Girls Quadruple Scull 4x",
  "Under 25 Girls 1 x 500m",
  "Under 25 Girls 1 x 2000m",
  "Under 25 Girls 2 x 1000m Relay",
  "Under 25 Girls 4 x 500m Relay",
];

export const ListOfDays = [
  "27th Jan",
  "1st Feb",
  "2nd Feb",
  "3rd Feb",
  "4th Feb",
];
