import React, { useState, useContext, useEffect, useRef } from "react";
import { getLoggedInUser } from "../../APIManager";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  Upload,
  Button,
  Typography,
  Layout,
  Form,
  Input,
  Checkbox,
  Alert,
  Radio,
  Divider,
  Modal,
  message,
  Space,
  InputNumber,
  Select
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import styles from "./CreateIDCardTemplate.module.css";

const { Title, Text } = Typography;
const { Content } = Layout;
const { Option } = Select;

const labels = [
  { label: "Photo", color: "rgba(255, 0, 0, 0.2)" },
  { label: "Name", color: "rgba(0, 255, 0, 0.2)" },
  { label: "School", color: "rgba(0, 0, 255, 0.2)" },
  { label: "Agegroup", color: "rgba(255, 255, 0, 0.2)" },
];

const MIN_RECT_SIZE = 50; // Minimum size for the rectangles
const CreateIDCardTemplate = ({ onChange }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [rectangles, setRectangles] = useState({});
  const [activeLabel, setActiveLabel] = useState(null);
  const [action, setAction] = useState(null);
  const imgRef = useRef(null);
  const startPosRef = useRef({});
  const offsetRef = useRef({});

  // Define the labels with their properties
  const labels = [
    { label: "Photo", color: "rgba(255, 0, 0, 0.3)" },
    { label: "Name", color: "rgba(0, 255, 0, 0.3)" },
    { label: "School", color: "rgba(0, 0, 255, 0.3)" },
    { label: "Agegroup", color: "rgba(255, 255, 0, 0.3)" },
    { label: "QRCode", color: "rgba(255, 165, 0, 0.3)", isSquare: true }, // QR Code rectangle
  ];

  useEffect(() => {
    const handleMouseUp = () => {
      setAction(null);
      setActiveLabel(null);
    };
    window.addEventListener("mouseup", handleMouseUp);
    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  useEffect(() => {
    onChange({ rectangles: rectangles, imageSrc: imageSrc });
  }, [rectangles]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setImageSrc(event.target.result);
      const initialRectangles = {};
      labels.forEach(({ label, color, isSquare }) => {
        initialRectangles[label] = {
          x: 0,
          y: 0,
          width: MIN_RECT_SIZE,
          height: MIN_RECT_SIZE,
          label,
          color,
          isSquare,
        };
      });
      setRectangles(initialRectangles);
    };
    reader.readAsDataURL(file);
  };

  const handleMouseDown = (label, e, corner = null) => {
    e.preventDefault();
    const rect = rectangles[label];
    const imgRect = imgRef.current.getBoundingClientRect();
    const startX = e.clientX - imgRect.left;
    const startY = e.clientY - imgRect.top;

    const offsetX = startX - rect.x;
    const offsetY = startY - rect.y;

    startPosRef.current = { startX, startY, ...rect };
    offsetRef.current = { offsetX, offsetY };
    setActiveLabel(label);
    setAction(corner ? `resize-${corner}` : "drag");
  };

  const handleResizeMouseDown = (label, e, corner) => {
    e.stopPropagation();
    handleMouseDown(label, e, corner);
  };

  const handleMouseMove = (e) => {
    if (!activeLabel || !action) return;

    const imgRect = imgRef.current.getBoundingClientRect();
    const currentX = e.clientX - imgRect.left;
    const currentY = e.clientY - imgRect.top;
    const rect = startPosRef.current;

    if (action === "drag") {
      const newX = Math.max(
        0,
        Math.min(
          currentX - offsetRef.current.offsetX,
          imgRect.width - rect.width
        )
      );
      const newY = Math.max(
        0,
        Math.min(
          currentY - offsetRef.current.offsetY,
          imgRect.height - rect.height
        )
      );

      setRectangles((prev) => ({
        ...prev,
        [activeLabel]: { ...rect, x: newX, y: newY },
      }));
    } else if (action.startsWith("resize")) {
      let newWidth = rect.width;
      let newHeight = rect.height;
      let newX = rect.x;
      let newY = rect.y;

      if (action.includes("right")) {
        newWidth = Math.max(
          MIN_RECT_SIZE,
          Math.min(currentX - rect.x, imgRect.width - rect.x)
        );
        newHeight = rect.isSquare ? newWidth : newHeight; // Maintain square for QR Code
      }
      if (action.includes("bottom")) {
        newHeight = Math.max(
          MIN_RECT_SIZE,
          Math.min(currentY - rect.y, imgRect.height - rect.y)
        );
        newWidth = rect.isSquare ? newHeight : newWidth; // Maintain square for QR Code
      }
      if (action.includes("left")) {
        newWidth = Math.max(MIN_RECT_SIZE, rect.width - (currentX - rect.x));
        newX = Math.min(
          rect.x + (currentX - rect.x),
          rect.x + rect.width - MIN_RECT_SIZE
        );
        newHeight = rect.isSquare ? newWidth : newHeight; // Maintain square for QR Code
      }
      if (action.includes("top")) {
        newHeight = Math.max(MIN_RECT_SIZE, rect.height - (currentY - rect.y));
        newY = Math.min(
          rect.y + (currentY - rect.y),
          rect.y + rect.height - MIN_RECT_SIZE
        );
        newWidth = rect.isSquare ? newHeight : newWidth; // Maintain square for QR Code
      }

      setRectangles((prev) => ({
        ...prev,
        [activeLabel]: {
          ...rect,
          width: newWidth,
          height: newHeight,
          x: newX,
          y: newY,
        },
      }));
    }
  };

  const uploadProps = {
    accept: "image/*",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageSrc(event.target.result);
        const initialRectangles = {};
        labels.forEach(({ label, color, isSquare }) => {
          initialRectangles[label] = {
            x: 0,
            y: 0,
            width: MIN_RECT_SIZE,
            height: MIN_RECT_SIZE,
            label,
            color,
            isSquare,
          };
        });
        setRectangles(initialRectangles);
      };
      reader.readAsDataURL(file);
      return false; // prevent auto upload
    },
  };

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
        </Upload>

        {imageSrc && (
          <div className={styles.scrollContainer}>
            <div
              className={styles.imageContainer}
              ref={imgRef}
              onMouseMove={handleMouseMove}
              onMouseUp={() => setAction(null)}
            >
              <img src={imageSrc} alt="Uploaded" className={styles.image} />

              {Object.entries(rectangles).map(([label, rect]) => (
                <div
                  key={label}
                  className={styles.rectangle}
                  style={{
                    top: rect.y,
                    left: rect.x,
                    width: rect.width,
                    height: rect.height,
                    backgroundColor: rect.color,
                  }}
                  onMouseDown={(e) => handleMouseDown(label, e)}
                >
                  <div className={styles.label}>{label}</div>
                  <div
                    className={`${styles.resizeHandle} ${styles.topLeft}`}
                    onMouseDown={(e) =>
                      handleResizeMouseDown(label, e, "top-left")
                    }
                  />
                  <div
                    className={`${styles.resizeHandle} ${styles.topRight}`}
                    onMouseDown={(e) =>
                      handleResizeMouseDown(label, e, "top-right")
                    }
                  />
                  <div
                    className={`${styles.resizeHandle} ${styles.bottomLeft}`}
                    onMouseDown={(e) =>
                      handleResizeMouseDown(label, e, "bottom-left")
                    }
                  />
                  <div
                    className={`${styles.resizeHandle} ${styles.bottomRight}`}
                    onMouseDown={(e) =>
                      handleResizeMouseDown(label, e, "bottom-right")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

const CreateIDCardTemplatePage = () => {
  const [form] = Form.useForm();
  const [templateNames, SetTemplateNames] = useState(["Test1"]);
  const [rectangles, SetRectangles] = useState(null);
  const [imageSrc, SetImageSrc] = useState(null);
  const [templateRectangles, SetTemplateRectangles] = useState([]);

  // Function to generate a random hex color
  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  const handleRectanglesChange = (count) => {
    const newTemplateRectangles = [];
    for (let i = 0; i < count; i++) {
      newTemplateRectangles.push({
        label: "",
        isSquare: false,
        fieldType: "Text", // Default field type
        color: getRandomColor(),
      });
    }
    console.log(newTemplateRectangles);
    SetTemplateRectangles(newTemplateRectangles);
  };

  // Validation rules for labels (you can adjust this as needed)
  const labelRules = [{ required: true, message: "Label is required" }];

  return (
    <div>
      <div className={styles.Page_Heading}>Create ID Card Template</div>
      <br />
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        //onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={"vertical"}
        size="large"
        onChange={() => {
          // SetMessage(form.getFieldsValue());
          //console.log(form.getFieldsValue());
          //console.log(form.getFieldsError())
          // console.log(form.isFieldsTouched(["SchoolColor2"]));
        }}
      >
        <Form.Item
          label="Template Name"
          name="TemplateName"
          rules={[
            {
              required: true,
              message: "Please input a School Name",
            },
            {
              message: "This name already exists",
              validator: (_, value) => {
                if (!templateNames.includes(value.trim())) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("This name already exists");
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Number of Rectangles"
          name="numRectangles"
          rules={[
            {
              required: true,
              message: "Please select the number of rectangles",
            },
          ]}
        >
          <InputNumber min={1} max={10} onChange={handleRectanglesChange} />
        </Form.Item>

        {/* Dynamically rendered rectangle items */}
        {templateRectangles.map((rectangle, index) => (
          <Space
            key={`Rectangle-Space-${index}`}
            direction="vertical"
            style={{
              marginBottom: "20px",
              border: `2px solid ${rectangle.color}`,
              padding: "10px",
            }}
          >
            <Text type="secondary">Rectangle {index + 1}</Text>

            {/* Label input */}
            <Form.Item
              label="Label"
              name={["rectangles", index, "label"]}
              rules={labelRules}
              initialValue={rectangle.label}
            >
              <Input placeholder="Enter label" />
            </Form.Item>

            {/* isSquare checkbox */}
            <Form.Item
              name={["rectangles", index, "isSquare"]}
              valuePropName="checked"
              initialValue={rectangle.isSquare}
            >
              <Checkbox>Is Square</Checkbox>
            </Form.Item>
            {/* Field Type ComboBox */}
            <Form.Item
              label="Field Type"
              name={["rectangles", index, "fieldType"]}
              initialValue={rectangle.fieldType}
            >
              <Select>
                <Option value="Text">Text</Option>
                <Option value="Image">Image</Option>
              </Select>
            </Form.Item>

            {/* Display generated color */}
            <Text>
              Color:{" "}
              <span style={{ color: rectangle.color }}>{rectangle.color}</span>
            </Text>
            <Form.Item
              name={["rectangles", index, "color"]}
              initialValue={rectangle.color}
            />
          </Space>
        ))}
        <CreateIDCardTemplate
          onChange={({ rectangles, imageSrc }) => {
            console.log({ rectangles, imageSrc });
          }}
        />
      </Form>
    </div>
  );
};

export default CreateIDCardTemplatePage;
