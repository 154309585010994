import React from "react";

import { Modal, Input, Button } from "antd";

import { useState, useEffect } from "react";

import PermissionsComponent from "./PermissionsComponent";

import { UpdateEmployeePermissions } from "../../APIManager";

export default function PermissionsModal({ user, modalVisible, onCloseModal }) {
  const [permissions, SetPermissions] = useState(user?.allowedmodules);

  useEffect(() => {
    SetPermissions(user?.allowedmodules);
  }, [user?.username]);

  return (
    <div>
      <Modal
        title={`Viewing permissions of ${user?.username} `}
        forceRender
        visible={modalVisible}
        onOk={() => {
          UpdateEmployeePermissions({
            id: user._id,
            permissions: permissions,
          }).then(() => {
            onCloseModal();
          });
        }}
        onCancel={onCloseModal}
        okText="Change"
        cancelText="Cancel"
        width="600px"
        okButtonProps={
          {
            // disabled: !newPasswordIsValid,
          }
        }
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <PermissionsComponent
          value={permissions}
          onChange={(v) => {
            SetPermissions(v);
          }}
        />
      </Modal>
    </div>
  );
}
