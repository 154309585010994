import React, { useState, useEffect } from "react";
import { Button, Result, Typography, Select, Collapse } from "antd";
import styles from "./Commentry.module.css";

import {
  GetRaces,
  GetSchools,
  GetRowers,
  GetEventEntries,
} from "../../APIManager";

const { Panel } = Collapse;

const CommentryPage = () => {
  const [races, SetRaces] = useState([]);
  const [schools, SetSchools] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [eventEntries, SetEventEntries] = useState([]);

  const [selectedRace, SetSelectedRace] = useState("");

  useEffect(() => {
    GetRaces().then((res) => {
      SetRaces(res.data);
    });
    GetSchools().then((res) => {
      SetSchools(res.data);
    });
    GetRowers().then((res) => {
      SetRowers(res.data);
    });

    GetEventEntries().then((res) => {
      SetEventEntries(res.data);
    });
  }, []);

  if (!races || !schools || !rowers) {
    return <div>Loading</div>;
  }

  var race;

  if (selectedRace) {
    race = races.filter((r) => r.RaceName == selectedRace)[0];
    console.log(race);
  }

  return (
    <>
      <h1>Commentry</h1>
      <div className={styles.MainDiv}>
        Select a race
        <Select
          style={{ width: "100%" }}
          showSearch
          options={races.map((e) => {
            return { value: e.RaceName, label: e.RaceName };
          })}
          onChange={(e) => {
            SetSelectedRace(e);
          }}
        />
        <br />
        <br />
        <br />
        {selectedRace && (
          <div>
            <Collapse accordion>
              {race.Participants.filter((p) => !p.isPlaceHolder).map((p, i) => {
                var school = schools.filter((s) => s._id == p.school)[0];
                var entry = eventEntries.filter(
                  (e) => e.RaceName == race.EventName && e.Team == p.school
                )[0];

                var students = [
                  ...entry.Rowers,
                  ...entry.Coxers,
                  ...entry.Coxers,
                  ...entry.SubCoxers,
                ];

                return (
                  <Panel
                    key={`Penalty Card Panel ${i}`}
                    header={school.schoolname}
                  >
                    {students.map((s) => {
                      var rower = rowers.filter((r) => r._id == s)[0];
                      return (
                        <div className = {styles.RowerRow}>
                          <div style={{fontWeight: "bold"}}>{rower.Name}:</div>
                          <div>{rower.Bio}</div>
                          <br/>
                        </div>
                      );
                    })}
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        )}
      </div>
    </>
  );
};

export default CommentryPage;
