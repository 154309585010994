import React from "react";
import { useState, useEffect } from "react";

import { Modal, Input, Button, Form, Checkbox, message, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  GetEntriesForEvent,
  GetSchools,
  UpdateSubstitutions,
} from "../../APIManager";

import { ListOfEvents } from "../../constants";

import styles from "./Substitutions.module.css";
import { findArgsFromCli } from "@craco/craco/lib/args";

const { confirm } = Modal;
const { Option } = Select;

export default function Substitutions({}) {
  const [enrtries, SetEntries] = useState([]);
  const [schools, SetSchools] = useState([]);
  const [selectedEvent, SetSelectedEvent] = useState("");
  const [selectedSchool, SetSelectedSchool] = useState("");
  const [awaitingResponse, SetAwaitingResponse] = useState(true);

  useEffect(() => {
    if (selectedEvent) {
      SetAwaitingResponse(true);
      SetSelectedSchool("");
      GetEntriesForEvent(selectedEvent).then((Res) => {
        SetEntries(Res.data);
        SetAwaitingResponse(false);
      });
    }
  }, [selectedEvent]);

  useEffect(() => {
    GetSchools().then((Res) => {
      SetSchools(Res.data);
    });
  }, []);
  function GetSchool(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  function RefreshEntries() {
    GetEntriesForEvent(selectedEvent).then((Res) => {
      SetEntries(Res.data);
      SetAwaitingResponse(false);
    });
  }

  return (
    <div>
      <h1>Substitutions</h1>
      <div>
        <h1>Select a race</h1>
        <Select
          showSearch
          value={selectedEvent}
          placeholder="Select a Race"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          allowClear
          style={{ width: "50ch" }}
          onChange={(e) => {
            SetSelectedEvent(e);
          }}
        >
          {ListOfEvents.map(function (item, i) {
            return <Option value={item}>{item}</Option>;
          })}
        </Select>
      </div>
      <br />
      {selectedEvent && !awaitingResponse && (
        <div>
          <h1>Select a school</h1>
          <Select
            showSearch
            value={selectedSchool}
            placeholder="Select a Race"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            style={{ width: "50ch" }}
            onChange={(e) => {
              SetSelectedSchool(e);
            }}
          >
            {enrtries.map(function (item, i) {
              console.log(enrtries);
              return (
                <Option value={item.Team}>
                  {GetSchool(item.Team).schoolname}
                </Option>
              );
            })}
          </Select>
        </div>
      )}
      {selectedEvent && !awaitingResponse && selectedSchool && (
        <div>
          <h1>Subs:</h1>{" "}
          <SubSelectComponent
            school={GetSchool(selectedSchool)}
            entry={enrtries.filter((e) => e.Team == selectedSchool)[0]}
            OnConfirm={(subs) => {
              UpdateSubstitutions({
                EventName: selectedEvent,
                SchoolID: selectedSchool,
                Subs: subs,
              }).then((res) => {
                message.success("Subs Updated");
                RefreshEntries()
              });
            }}
            key={`${selectedEvent}-${selectedSchool}`}
          />
        </div>
      )}
    </div>
  );
}

const SubSelectComponent = ({ school, entry, OnConfirm }) => {
  const [subs, SetSubs] = useState(entry.Substitutions);

  var repeatSubs = CheckRepeatSubs(subs);

  return (
    <div>
      {!repeatSubs ? (
        <></>
      ) : (
        <div className={styles.ErrorMessage}>Error: Repeated Substitute(s)</div>
      )}
      {entry.Rowers.map((r) => {
        return (
          <div className={styles.RowerRow}>
            <label>{r.Name}</label>
            <Select
              value={
                subs.filter((s) => s.rID == r._id)[0]
                  ? subs.filter((s) => s.rID == r._id)[0].sID
                  : null
              }
              placeholder="Select a Sub"
              allowClear
              style={{ width: "25ch" }}
              onChange={(e) => {
                var newSubs = [...subs];
                newSubs = newSubs.filter((s) => s.rID != r._id);
                if (e) {
                  newSubs.push({ rID: r._id, sID: e });
                }
                SetSubs(newSubs);
              }}
            >
              {entry.SubRowers.map(function (item, i) {
                return <Option value={item._id}>{item.Name}</Option>;
              })}
            </Select>
          </div>
        );
      })}
      {entry.Coxers.map((r) => {
        return (
          <div className={styles.RowerRow}>
            <label>{r.Name}</label>
            <Select
              value={
                subs.filter((s) => s.rID == r._id)[0]
                  ? subs.filter((s) => s.rID == r._id)[0].sID
                  : null
              }
              placeholder="Select a Sub Cox"
              allowClear
              style={{ width: "25ch" }}
              onChange={(e) => {
                var newSubs = [...subs];
                newSubs = newSubs.filter((s) => s.rID != r._id);
                if (e) {
                  newSubs.push({ rID: r._id, sID: e });
                }

                SetSubs(newSubs);
              }}
            >
              {entry.SubCoxers.map(function (item, i) {
                return <Option value={item._id}>{item.Name}</Option>;
              })}
            </Select>
          </div>
        );
      })}
      <Button
        type="primary"
        disabled={repeatSubs}
        onClick={() => {
          OnConfirm(subs);
        }}
      >
        Confirm
      </Button>
    </div>
  );
};

function CheckRepeatSubs(subs) {
  var oSubs = [...subs].map((s) => s.sID);

  return oSubs.length !== new Set(oSubs).size;
}
