import React, { useState, useContext, useEffect } from "react";
import { Prompt } from "react-router";
import styles from "./ControlPanelPage.module.css";
import {
  AddNewSchool,
  GetSchoolNames,
  GetSchedules,
  GetRaces,
  AddNewRaceDay,
  DeleteRaceDay,
  UnScheduleAllRaces,
} from "../../APIManager";
import { UserContext } from "../../UserContext";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  message,
  Space,
  DatePicker,
  List,
  Popconfirm,
  Typography,
  Table,
  Tooltip,
  Tag,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ChromePicker } from "react-color";
import {
  ExclamationCircleOutlined,
  DeleteFilled,
  StopOutlined,
} from "@ant-design/icons";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { UpdateCMSDoc, GetCMSDoc, SwitchScheduleClass } from "../../APIManager";

import moment from "moment";

const { confirm } = Modal;
const { TextArea } = Input;
const { Text } = Typography;
const ControlPanelPage = () => {
  const [showErrorMessage, SetShowErrorMessage] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);
  const [form] = Form.useForm();
  const requiredFields = [
    "EventYear",
    "FirstDeadline",
    "SecondDeadline",
    "RegSecEmail",
    "RowerFees",
    "SchoolFees",
    "MaxSchoolFees",
    "LateRowerFees",
    "LateSchoolFees",
  ];

  const [year, SetYear] = useState(null);

  const U25YOBLowerLimit = year ? year - 24 : null;
  const U21YOBLowerLimit = year ? year - 20 : null;
  const U18YOBLowerLimit = year ? year - 17 : null;
  const U15YOBLowerLimit = year ? year - 14 : null;

  const [formSetupCMSDoc, SetFormSetupCMSDoc] = useState(null);

  useEffect(() => {
    FetchCMS();
  }, []);

  function FetchCMS() {
    GetCMSDoc("ControlPanelFormSetup").then((res) => {
      SetFormSetupCMSDoc(res.data);

      const fetchedFirstDeadlineDate = res.data.Data.FirstDeadlineDate;
      const fetchedSecondDeadlineDate = res.data.Data.SecondDeadlineDate;
      const fetchedYear = res.data.Data.EventYear;

      const {
        RowerFees,
        SchoolFees,
        MaxSchoolFees,
        LateRowerFees,
        LateSchoolFees,
        LockOn1stDeadline,
        LockOn2ndDeadline,
      } = res.data.Data;

      SetYear(fetchedYear);

      form.setFieldsValue({
        EventYear: fetchedYear,
        FirstDeadline: moment(fetchedFirstDeadlineDate),
        SecondDeadline: moment(fetchedSecondDeadlineDate),
        RegSecEmail: res.data.Data.RegSecEmail,
        LockOn1stDeadline,
        LockOn2ndDeadline,
        RowerFees,
        SchoolFees,
        MaxSchoolFees,
        LateRowerFees,
        LateSchoolFees,
      });
    });
  }

  useEffect(() => {}, []);

  const onFinish = (values) => {
    const {
      EventYear,
      FirstDeadline,
      SecondDeadline,
      LockOn1stDeadline,
      LockOn2ndDeadline,
      RegSecEmail,
      RowerFees,
      SchoolFees,
      MaxSchoolFees,
      LateRowerFees,
      LateSchoolFees,
    } = form.getFieldsValue();

    const data = {
      EventYear,
      LockOn1stDeadline,
      LockOn2ndDeadline,
      RegSecEmail,
      FirstDeadlineText: FirstDeadline.format("dddd, MMM D, YYYY"),
      FirstDeadlineDate: FirstDeadline.millisecond(0).toISOString(),
      SecondDeadlineText: SecondDeadline.format("dddd, MMM D, YYYY"),
      SecondDeadlineDate: SecondDeadline.millisecond(0).toISOString(),
      RowerFees: Number(RowerFees),
      SchoolFees: Number(SchoolFees),
      MaxSchoolFees: Number(MaxSchoolFees),
      LateRowerFees: Number(LateRowerFees),
      LateSchoolFees: Number(LateSchoolFees),
    };

    UpdateCMSDoc({
      docname: "ControlPanelFormSetup",
      data: data,
    })
      .then((res) => {
        message.info("Sucessfully Saved");
        //FetchCMS();
      })
      .catch(() => {
        message.error("Network or Server Error");
      });
  };

  const validateWholeNumber = (_, value) => {
    if (!Number.isInteger(Number(value))) {
      return Promise.reject(new Error("Value must be a whole number"));
    }
    return Promise.resolve();
  };

  const validateMaxSchoolFees = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!Number.isInteger(Number(value))) {
        return Promise.reject(new Error("Value must be a whole number"));
      }
      const schoolFees = getFieldValue("SchoolFees");
      if (schoolFees && value < schoolFees) {
        return Promise.reject(
          new Error("Max School Entry Fee must be at least 1x School Entry Fee")
        );
      }
      if (schoolFees && value % schoolFees !== 0) {
        return Promise.reject(
          new Error(
            "Max School Entry Fee must be a multiple of School Entry Fee"
          )
        );
      }
      return Promise.resolve();
    },
  });

  return (
    <div className={styles.MainDiv}>
      <Divider>
        {" "}
        <strong>Form Setup</strong>
      </Divider>
      {formSetupCMSDoc && (
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout={"vertical"}
          size="large"
          onChange={() => {
            SetYear(form.getFieldsValue()["EventYear"]);
          }}
        >
          <Form.Item
            label="Year"
            name="EventYear"
            rules={[
              {
                required: true,
                message: "Please enter the Year",
              },
              {
                validator: (_, value) => {
                  const currentYear = new Date().getFullYear();
                  if (
                    !value ||
                    (Number(value) >= 1900 && Number(value) <= currentYear + 1)
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Please enter a valid year between 1900 and " +
                        (currentYear + 1)
                    );
                  }
                },
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          {year && (
            <div className={styles.MyTable}>
              <table>
                <tr>
                  <th>Category</th>
                  <th>By age student will turn in {year}</th>
                  <th>By student’s date of birth</th>
                  <th>Permitted categories for event entry</th>
                </tr>
                <tr>
                  <td>Under 25</td>
                  <td>24 at the oldest</td>
                  <td>
                    {U25YOBLowerLimit}, {U25YOBLowerLimit + 1},{" "}
                    {U25YOBLowerLimit + 2}, and {U25YOBLowerLimit + 3}
                  </td>
                  <td>Under 25</td>
                </tr>
                <tr>
                  <td>Under 21</td>
                  <td>20 at the oldest</td>
                  <td>
                    {U21YOBLowerLimit}, {U21YOBLowerLimit + 1},{" "}
                    {U21YOBLowerLimit + 2}
                  </td>
                  <td>Under 21</td>
                </tr>
                <tr>
                  <td>Under 18</td>
                  <td>17 at the oldest</td>
                  <td>
                    {U18YOBLowerLimit}, {U18YOBLowerLimit + 1},{" "}
                    {U18YOBLowerLimit + 2}
                  </td>
                  <td>Under 21 and Under 18</td>
                </tr>
                <tr>
                  <td>Under 15</td>
                  <td>14 at the oldest</td>
                  <td>{U15YOBLowerLimit} or after</td>
                  <td>Under 21, Under 18 and Under 15</td>
                </tr>
              </table>
            </div>
          )}
          <Space direction="horizontal" align="start" valuePropName={"date"}>
            <Form.Item
              name="FirstDeadline"
              label="First Deadline"
              rules={[
                {
                  required: true,
                  message: "Please select the first deadline date",
                },
              ]}
            >
              <DatePicker showTime={{ format: "HH:mm:ss" }} />
            </Form.Item>
            <Form.Item
              name="SecondDeadline"
              label="Second Deadline"
              rules={[
                {
                  required: true,
                  message: "Please select the Second deadline date",
                },
              ]}
            >
              <DatePicker showTime={{ format: "HH:mm:ss" }} />
            </Form.Item>
          </Space>
          <br />
          <Space direction="horizontal" align="start">
            <Form.Item
              label=""
              name="LockOn1stDeadline"
              valuePropName="checked"
            >
              <Checkbox>Lock on First Deadline</Checkbox>
            </Form.Item>
            <Form.Item
              label=""
              name="LockOn2ndDeadline"
              valuePropName="checked"
            >
              <Checkbox>Lock on Second Deadline</Checkbox>
            </Form.Item>
          </Space>
          <br />

          <Space wrap>
            <Form.Item
              name="RowerFees"
              label="Individual Entry Fee"
              rules={[
                {
                  required: true,
                  message: "Please enter Individual Entry Fee",
                },
                {
                  validator: validateWholeNumber,
                },
              ]}
            >
              <Input
                type="Number"
                placeholder="Individual Entry Fee"
                prefix="Rs."
              />
            </Form.Item>
            <Form.Item
              name="SchoolFees"
              label="School Entry Fee (per rower upto max)"
              rules={[
                {
                  required: true,
                  message: "Please enter School Entry Fee",
                },
                {
                  validator: validateWholeNumber,
                },
              ]}
            >
              <Input
                type="Number"
                placeholder="School Entry Fee"
                prefix="Rs."
              />
            </Form.Item>
            <Form.Item
              name="MaxSchoolFees"
              label="Max School Entry Fee"
              rules={[
                {
                  required: true,
                  message: "Please enter Max School Entry Fee",
                },
                validateMaxSchoolFees,
              ]}
            >
              <Input
                type="Number"
                placeholder="Max School Entry Fee"
                prefix="Rs."
              />
            </Form.Item>
            <Form.Item
              name="LateRowerFees"
              label="Individual Late Fee per day"
              rules={[
                {
                  required: true,
                  message: "Please enter Individual Late Fee per day",
                },
                {
                  validator: validateWholeNumber,
                },
              ]}
            >
              <Input
                type="Number"
                placeholder="Individual Late Fee"
                prefix="Rs."
              />
            </Form.Item>
            <Form.Item
              name="LateSchoolFees"
              label="School Late Fee per day"
              rules={[
                {
                  required: true,
                  message: "Please enter School Late Fee per day",
                },
                {
                  validator: validateWholeNumber,
                },
              ]}
            >
              <Input type="Number" placeholder="School Late Fee" prefix="Rs." />
            </Form.Item>
          </Space>
          <Form.Item
            name="RegSecEmail"
            label="Reggatta Secretary Email"
            rules={[
              {
                required: true,
                message: "Please enter Reggatta Secretary Email",
              },
              {
                type: "email",
                message: "The input is not a valid email",
              },
            ]}
          >
            <Input placeholder="Reggatta Secretary Email" />
          </Form.Item>

          <Form.Item shouldUpdate={true}>
            {() => {
              return (
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    (!formSetupCMSDoc &&
                      !requiredFieldsTouched(form, requiredFields)) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  Save
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      )}

      <Divider>
        <strong>Race Days</strong>
      </Divider>
      <SchedulesPanel />
    </div>
  );
};

const SchedulesPanel = () => {
  const [schedules, SetShedules] = useState([]);
  const [races, SetRaces] = useState([]);

  const [showAddModal, SetShowAddModal] = useState(false);

  function FetchSchedules() {
    GetSchedules().then((resA) => {
      GetRaces().then((resB) => {
        var scdls = [...resA.data];
        var rcs = [...resB.data];
        scdls.forEach((scdl, index) => {
          const innerJoin = scdl.Schedule.map((r) => {
            const race = rcs.find((race) => race.RaceName == r.RaceName);
            return race ? { ...r, ...race } : null;
          }).filter((result) => result !== null);

          scdls[index].Schedule = [...innerJoin];
        });

        SetShedules(scdls);
      });
    });
  }

  useEffect(() => {
    FetchSchedules();
  }, []);

  const columns = [
    /*     {
      title: "Day",
      dataIndex: "Day",
    }, */
    {
      title: "Date",
      key: "Date",

      sorter: (a, b) => a.Date.localeCompare(b.Date),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.Date.indexOf(value) === 0,
      render: (text, record) => (
        <span>{moment(record.Date).format("dddd, MMM D, YYYY")}</span>
      ),
    },
    {
      title: "Races Scheduled",
      key: "Races Scheduled",
      render: (text, record) => (
        <Space size="middle">{record.Schedule.length}</Space>
      ),
    },
    {
      title: "Type",
      key: "Class",
      render: (text, record) => (
        <Tag
          style={{
            fontSize: "1em",
            height: "1.5em",
            borderRadius: "10px",
          }}
          color={record.Class == "Outdoor" ? "blue" : "magenta"}
        >
          {record.Class}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const deleteDisabled = record.Schedule.length > 0;
        const unscheduleDisabled =
          record.Schedule.filter((r) => r.RaceStatus != "Scheduled").length > 0;

        const setIndoorDisabled = deleteDisabled;
        return (
          <Space size="middle">
            <Tooltip
              title={
                !deleteDisabled
                  ? ""
                  : "To enable first unschedule all races from day"
              }
            >
              {" "}
              <a
                disabled={setIndoorDisabled}
                onClick={() => {
                  SwitchScheduleClass({
                    day: record.Day,
                    newStatus: record.Class == "Indoor" ? "Outdoor" : "Indoor",
                  }).then((res) => {
                    FetchSchedules();
                  });
                }}
              >
                {record.Class == "Indoor" ? "Set Outdoor" : "Set Indoor"}
              </a>
            </Tooltip>

            <Tooltip
              title={
                !unscheduleDisabled
                  ? "Un-schedule all races"
                  : "Cannot un-schedule all races since more than 1 race has already started"
              }
            >
              {" "}
              <a
                disabled={unscheduleDisabled}
                onClick={() => {
                  UnScheduleAllRaces({
                    date: record.Date,
                    day: record.Day,
                  }).then((res) => {
                    FetchSchedules();
                  });
                }}
              >
                <StopOutlined />
              </a>
            </Tooltip>
            <Tooltip
              title={
                !deleteDisabled
                  ? "Delete Day"
                  : "Cannot delete if races scheduled"
              }
            >
              {" "}
              <a
                disabled={deleteDisabled}
                onClick={() => {
                  DeleteRaceDay({ date: record.Date, day: record.Day }).then(
                    (res) => {
                      FetchSchedules();
                    }
                  );
                }}
              >
                <DeleteFilled />
              </a>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <DateModal
        visible={showAddModal}
        onClose={() => {
          SetShowAddModal(false);
        }}
        dates={schedules}
        onDateSelect={(date) => {
          AddNewRaceDay({ date: date, day: GetDayFromDate(date) }).then(
            (res) => {
              FetchSchedules();
            }
          );
        }}
      />{" "}
      <Table
        columns={columns.map((col) => ({
          ...col,
          title: <span style={{ fontWeight: "bold" }}>{col.title}</span>,
        }))}
        dataSource={schedules}
        pagination={{
          defaultPageSize: 50,
          disabled: true,
          hideOnSinglePage: true,
        }}
        style={{ fontWeight: "bold" }}
      />
      <Button
        size={"large"}
        block
        type="ghost"
        onClick={() => {
          SetShowAddModal(true);
        }}
      >
        Add new race day
      </Button>
    </div>
  );
};

const DateModal = ({ visible, onClose, dates, onDateSelect }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Function to check if selected date is unique and trigger callback
  const handleOk = () => {
    if (!selectedDate) {
      message.warning("Please select a date.");
      return;
    }

    // Convert dates array to a set of unique date strings (ignoring time)
    const existingDates = new Set(
      dates.map((d) => moment(d.Date).format("YYYY-MM-DD"))
    );
    const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

    if (existingDates.has(formattedSelectedDate)) {
      message.error(
        "The selected date already exists. Please choose another date."
      );
      return;
    }

    onDateSelect(selectedDate.toISOString());
    onClose(); // Close the modal
  };

  return (
    <Modal
      title="Add a new race day"
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Select
        </Button>,
      ]}
    >
      <DatePicker onChange={handleDateChange} style={{ width: "100%" }} />
    </Modal>
  );
};

export default ControlPanelPage;

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      returnValue = false;
    }
  });
  return returnValue;
}

// Helper function to convert file to Base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

function GetDayFromDate(isoString) {
  const date = new Date(isoString); // Parse the ISO string into a Date object
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });

  const ordinalSuffix = (day) => {
    if (day % 10 === 1 && day !== 11) return "st";
    if (day % 10 === 2 && day !== 12) return "nd";
    if (day % 10 === 3 && day !== 13) return "rd";
    return "th";
  };

  return `${day}${ordinalSuffix(day)} ${month}`;
}
