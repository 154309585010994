import { React, useContext } from "react";
import { Table, Button, Modal } from "antd";
import { UserContext } from "../../UserContext";
import UploadedFile from "../Shared Components/UploadedFile";
import { GetRower, GetStudentEvents } from "../../APIManager";

import ReadOnlyUploadedFile from "../Shared Components/ReadOnlyUploadedFile";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";
export default function ViewStudentInformation({
  rowerid,
  modalVisible,
  closeModal,
}) {
  const history = useHistory();
  const [user, setUser] = useContext(UserContext);
  const [rower, SetRower] = useState({});
  const [evententries, SetEvententries] = useState([]);

  useEffect(() => {
    SetRower({});
    SetEvententries([]);
    GetRower(rowerid).then((res) => {
      SetRower(res.data);
    });

    GetStudentEvents(rowerid).then((res) => {
      SetEvententries(res.data.Entries);
    });
  }, [rowerid]);

  const dataSource = GetDataSource(rower);
  const columns = [
    {
      dataIndex: "Key",
      key: "Key",
    },
    {
      dataIndex: "Value",
      key: "Value",
    },
  ];

  return (
    <Modal
      title="Student Details"
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={closeModal}
      okText="Delete"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <div>
        <div>
          <h2>Student Info</h2>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
        <br />
        <div>
          <h2>Uploaded Files</h2>
          <ul>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="Photograph"
                FileURL={rower.Photograph}
              />
            </li>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="CNIC / Passport Scan"
                FileURL={rower.CNIC}
              />
            </li>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="Declaration"
                FileURL={rower.Declaration}
              />
            </li>
            {rower.Vaccination ? (
              <li>
                <ReadOnlyUploadedFile
                  FileLabel="Vaccination"
                  FileURL={rower.Vaccination}
                />
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
        <div>
          <h2>Events</h2>
          {evententries.length < 1 ? <div>No events</div> : null}
          {console.log(evententries.length)}
          {evententries.map((event) => {
            console.log(event);
            return <div>{event}</div>;
          })}
        </div>
      </div>
    </Modal>
  );

  function GetDataSource(rower) {
    var mappings = {
      Name: rower.Name,
      YOB: rower.YOB,
      "Age Group": rower.AgeGroup,
      Gender: rower.Gender,
      PhoneNumber: rower.PhoneNumber,
      Nationality: rower.Nationality,
      CNIC: rower.CNICNumber,
      "KBC Membership Status": rower.KBCMember,
      "KBC Number": rower.KBCNumber,
      "Participated in previous KBC": rower.PreviousKBC ? "Yes" : "No",
    };

    var keys = Object.keys(mappings);
    var dataSource = [];
    keys.forEach((key) => {
      dataSource.push({
        Key: key,
        Value: mappings[key],
      });
    });
    return dataSource;
  }
}
