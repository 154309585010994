import React from "react";
import { Button, Result } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { ErrorBoundary, useErrorHandler } from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Result
      status="error"
      icon={<WarningOutlined style={{ fontSize: 48, color: "red" }} />}
      title="Something went wrong."
      subTitle={error?.message || "An unexpected error occurred."}
      extra={
        <Button type="primary" onClick={resetErrorBoundary}>
          Retry
        </Button>
      }
    />
  );
};

export default ErrorFallback;
