import React from "react";
import { useState, useEffect } from "react";

import { Modal, Input, Button, Form, Checkbox, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { AddEmployeeAccount } from "../../APIManager";

import PermissionsComponent from "./PermissionsComponent";

const { confirm } = Modal;

export default function AddNewEmployee({
  modalVisible,
  onCloseModal,
  existingUsers,
}) {
  const [form] = Form.useForm();

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const onFinish = (values) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm you want to add a user",
      okText: "Add",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        SetWaitingOnResponse(true);
        AddEmployeeAccount(form.getFieldsValue())
          .then((res) => {
            SetWaitingOnResponse(false);
            onCloseModal();
            form.resetFields();
          })
          .catch(() => {
            message.warning(
              "A Server/Network Error Occured, please check your connection"
            );
            SetWaitingOnResponse(false);
          });
      },
      onCancel() {},
    });
  };

  if (waitingOnResponse) {
    return (
      <Modal
        title={`Loading`}
        forceRender
        visible={true}
        width="400px"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        Loading{" "}
      </Modal>
    );
  }

  return (
    <div>
      <Modal
        title={`Add new admin user`}
        forceRender
        visible={modalVisible}
        onCancel={onCloseModal}
        okText="Add User"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{ hidden: true }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
          size="large"
          onChange={() => {
            // SetMessage(form.getFieldsValue());
            console.log(form.getFieldsValue());
            //console.log(form.getFieldsError())
            // console.log(form.isFieldsTouched(["SchoolColor2"]));
          }}
        >
          <Form.Item
            label="User Name"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input a User Name",
              },
              {
                message: "This name already exists",
                validator: (_, value) => {
                  if (
                    !existingUsers
                      .map((u) => u.username.toUpperCase())
                      .includes(value.toUpperCase())
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("This user name already exists");
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input a password",
              },
              { min: 9, message: "Password must be minimum 9 characters." },
            ]}
          >
            <Input />
          </Form.Item>
          <div style={{ marginTop: "-0.7em", marginBottom: "1em" }}>
            {" "}
            Please note down this password as it wont be visible once user is
            created
          </div>
          <Button
            onClick={() => {
              var password = String(
                Math.floor(100000000 + Math.random() * 900000000)
              );
              form.setFieldsValue({ password: password });
            }}
          >
            Generate Random Password
          </Button>
          <Form.Item label="Permissions" name="permissions">
            <PermissionsComponent />
          </Form.Item>
          <Form.Item shouldUpdate={true}>
            {() => {
              // console.log(form.isFieldsTouched(["Declration"]))
              return (
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  Add new user
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
