import React from "react";
import { useEffect, useState, useContext } from "react";
import {
  GetSchoolUnAssignedStudents,
  GetSchoolRowers,
  GetSchoolInfo,
  UpdateSchoolPaymentStatus,
  GetRowers,
} from "../../../APIManager";
import { Alert, Popover, Button, Spin, Tag, Modal } from "antd";
import styles from "./PaymentPage.module.css";
import { GetFeeChallanDD } from "./PDFLayout";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { UserContext } from "../../../UserContext";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const PaymentComponent = ({ schoolID }) => {
  const [unassignedStudents, setUnassignedStudents] = useState({});
  const [rowers, SetRowers] = useState([]);
  const [user, SetUser] = useState({});
  const [loading, SetLoading] = useState(true);
  var noOfRowers = rowers?.length;

  useEffect(() => {
    SetLoading(true);
    GetSchoolUnAssignedStudents().then((res) => {
      setUnassignedStudents(res.data);
    });
    GetSchoolInfo(schoolID).then((res) => {
      SetUser(res.data);
      GetSchoolRowers(schoolID).then((res) => {
        SetRowers(res.data);
        SetLoading(false);
      });
    });
  }, [schoolID]);
  if (loading) {
    return (
      <div>
        <h1 style={{ fontSize: "1.5em" }}>Loading</h1>
        <Spin size="large" />
      </div>
    );
  }

  let nf = new Intl.NumberFormat("en-US");

  var rowerFees = 1500 * noOfRowers;
  var schoolfees = noOfRowers > 9 ? 15000 : 1500 * noOfRowers;
  var totalfees = rowerFees + schoolfees;

  return (
    <div>
      <div style={{ fontSize: "1.7em", marginBottom: "1em" }}>
        Payment Status:{" "}
        {!user.stagescomplete.includes("Payment") ? (
          <>
            <Tag style={{ fontSize: "1em", padding: "10px" }} color="red">
              Not Paid
            </Tag>
            <br />
            <Button
              type="primary"
              onClick={() => {
                Modal.info({
                  title: "Mark Payment as Paid",
                  width: "500px",
                  content: (
                    <div>
                      <p>
                        Are you sure you want to mark {user.schoolname}'s
                        Payment as Complete ?
                      </p>
                      <p>
                        Total Amount:{" "}
                        <strong>Rs. {nf.format(totalfees)}</strong>
                      </p>
                    </div>
                  ),
                  onOk() {
                    UpdateSchoolPaymentStatus(schoolID, true).then(() => {
                      SetLoading(true);
                      GetSchoolUnAssignedStudents().then((res) => {
                        setUnassignedStudents(res.data);
                      });
                      GetSchoolInfo(schoolID).then((res) => {
                        SetUser(res.data);
                        GetSchoolRowers(schoolID).then((res) => {
                          SetRowers(res.data);
                          SetLoading(false);
                        });
                      });
                    });
                  },
                  okText: "Confirm Payment",
                  okCancel: true,
                  cancelText: "Cancel",
                });
              }}
            >
              Mark Payment as Paid
            </Button>
          </>
        ) : (
          <>
            {" "}
            <Tag style={{ fontSize: "1em", padding: "10px" }} color="green">
              Paid
            </Tag>
            <br />
            <Button
              type="danger"
              onClick={() => {
                Modal.info({
                  title: "Mark Payment as Not Paid",
                  width: "500px",
                  content: (
                    <div>
                      <p>
                        Are you sure you want to mark {user.schoolname}'s
                        Payment as InComplete ?
                      </p>
                      <p>
                        Total Amount:{" "}
                        <strong>Rs. {nf.format(totalfees)}</strong>
                      </p>
                    </div>
                  ),
                  onOk() {
                    UpdateSchoolPaymentStatus(schoolID, false).then(() => {
                      SetLoading(true);
                      GetSchoolUnAssignedStudents().then((res) => {
                        setUnassignedStudents(res.data);
                      });
                      GetSchoolInfo(schoolID).then((res) => {
                        SetUser(res.data);
                        GetSchoolRowers(schoolID).then((res) => {
                          SetRowers(res.data);
                          SetLoading(false);
                        });
                      });
                    });
                  },
                  okText: "Confirm",
                  okCancel: true,
                  cancelText: "Cancel",
                });
              }}
            >
              Mark Payment as UnPaid
            </Button>
          </>
        )}
      </div>
      <UnAssignedError unassignedStudents={unassignedStudents} />
      <div className={styles.MainInvoiceContainer}>
        <hr />
        <h3>Individual Entry Fee: Rs. 1,500</h3>
        {noOfRowers > 9 ? (
          <h3>School Entry Fee: Rs. 15,000 (Since 10 or more rowers)</h3>
        ) : (
          <h3>
            School Entry Fee: Rs. 1,500 / Student (Since less than 10 rowers)
          </h3>
        )}
        <hr />
        <h2>Number of Rowers: {noOfRowers}</h2>
        <hr />
        <h2>
          Individual Entry Fee Sub Total: Rs. {nf.format(noOfRowers * 1500)}
        </h2>
        {noOfRowers > 9 ? (
          <h2>School Entry Fee Sub Total: Rs. 15,000 </h2>
        ) : (
          <h2>
            School Entry Fee Sub Total: Rs. {nf.format(noOfRowers * 1500)}{" "}
          </h2>
        )}
        <hr />
        <h2 className={styles.TotalFeeBox}>
          Total Fee: Rs. {nf.format(totalfees)}
        </h2>
        <Button
          type="primary"
          onClick={() => {
            var pdf = pdfMake.createPdf(
              GetFeeChallanDD(
                user.schoolname,
                new Date().toLocaleDateString("en-GB"),
                noOfRowers,
                `Rs. ${nf.format(rowerFees)}`,
                `Rs. ${nf.format(schoolfees)}`,
                `Rs. ${nf.format(totalfees)}`,
                true
              )
            );
            console.log(pdf);
            pdf.download("Fee Challan");
          }}
        >
          Download Fee Challan
        </Button>
      </div>
    </div>
  );
};

export default PaymentComponent;

const UnAssignedError = ({ unassignedStudents }) => {
  if (
    unassignedStudents.IDs === undefined ||
    unassignedStudents.IDs?.length === 0
  ) {
    return null;
  }

  const content = (
    <div style={{ maxHeight: "30vh", overflow: "scroll" }}>
      <ul>
        {unassignedStudents.Names.map((name) => {
          return <li>{name}</li>;
        })}
      </ul>
    </div>
  );
  var no = unassignedStudents.IDs?.length;
  return (
    <Alert
      message="Warning"
      //description={`You have not assigned ${no} students to any race However, They will stil be incuded in the total registration fees. It is best if you either assign them to a race or delete them from the Team Registration Page`}
      description={
        <div>
          You have not assigned <strong>{no}</strong> students to any race
          <br />
          They will still be incuded in the total registration fees. <br />
          It is best if you either assign them to a race or delete them from the
          Team Registration Page
          <br />
          <Popover content={content} title="Unaasigned Students">
            <strong>Hover here for names</strong>
          </Popover>
          ,
        </div>
      }
      type="warning"
      showIcon
    />
  );
};
