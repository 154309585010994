import React, { useState } from "react";
import { useCallback } from "react";
import { Button } from "antd";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "react-flow-renderer";
// 👇 you need to import the reactflow styles
import "react-flow-renderer/dist/style.css";

import ProgressionSystems from "./ProgressionSystems";

import TextUpdaterNode from "./CustomNode";

const nodeTypes = { textUpdater: TextUpdaterNode };

export default function Test() {
  const [index, SetIndex] = useState(0);
  const len = ProgressionSystems.length;
  const nodes = ProgressionSystems[len - 1].nodes;
  const edges = ProgressionSystems[len - 1].edges;

  return (
    <div>
      <h1>{ProgressionSystems[index].ID}</h1>
      <div style={{ height: "700px", width: "100%" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          //onNodesChange={onNodesChange}
          // onEdgesChange={onEdgesChange}
          // onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          onLoad={(instance) => setTimeout(() => instance.fitView(), 0)}
          //  style={rfStyle}
        >
          <Controls />
        </ReactFlow>
      </div>
      <Button
        onClick={() => {
          if (ProgressionSystems[index - 1]) {
            SetIndex(index - 1);
          }
        }}
      >
        Back
      </Button>
      <Button
        onClick={() => {
          if (ProgressionSystems[index + 1]) {
            SetIndex(index + 1);
          }
        }}
      >
        Next
      </Button>
    </div>
  );
}
