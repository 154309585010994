import React from "react";

import { Modal, Input, Button, Select, Form, Table, Popconfirm } from "antd";

import { useState, useEffect, useContext } from "react";

import {
  GetIndoorRaceResults,
  GetEventEntries,
  GetSchools,
  GetRowers,
  GetRaces,
  AddIndoorRaceResults,
  DeleteIndoorRaceResults,
  GetIndoorRecords,
  AddNewIndoorRecord,
} from "../../APIManager";

import { ListOfEvents } from "../../constants";

export default function IndoorResults() {
  const [raceResults, SetRaceResults] = useState([]);
  const [eventEntries, SetEventEntries] = useState([]);
  const [schools, SetSchools] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [races, SetRaces] = useState([]);
  const [indoorRecords, SetIndoorRecords] = useState([]);
  const [addModalOpen, SetModalOpen] = useState(false);

  const [awaitingResponse, SetAwaitingResponse] = useState(true);

  useEffect(() => {
    FetchData();
    GetSchools().then((res) => {
      SetSchools(res.data);
    });
    GetRowers().then((res) => {
      SetRowers(res.data);
    });
    GetRaces().then((res) => {
      SetRaces(res.data);
    });
    GetIndoorRecords().then((res) => {
      SetIndoorRecords(res.data);
    });
  }, []);

  function FetchData() {
    GetIndoorRaceResults().then((res) => {
      SetRaceResults(res.data);
      SetAwaitingResponse(false);
    });
    GetEventEntries().then((res) => {
      SetEventEntries(res.data);
    });
  }

  function GetSchoolInfo(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  if (
    awaitingResponse ||
    eventEntries.length < 1 ||
    schools.length < 1 ||
    rowers.length < 1
  ) {
    return <h1>Loading</h1>;
  }

  const listOfRaces = [
    ...new Set(
      races.filter((r) => r.Class == "Indoor Race").map((r) => r.EventName)
    ),
  ];

  const columns = [
    {
      title: "Event Name",
      dataIndex: "EventName",
      render: (text, record) => (
        <>
          <span>{record.EventName} </span>
          <Popconfirm
            placement="bottomLeft"
            title={
              "Are you sure you want to delete these race results. This action cannot be undone"
            }
            onConfirm={() => {
              DeleteIndoorRaceResults(record.EventName).then(() => {
                FetchData();
              });
            }}
            okText="Yes"
            cancelText="No"
          >
            <span style={{ color: "blue" }}> Delete</span>
          </Popconfirm>
        </>
      ),
    },
    {
      title: "1st",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[0].id).schoolinitials}
          <br />
          {record.Results[0]?.time}
        </>
      ),
    },
    {
      title: "2nd",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[1]?.id)?.schoolinitials} <br />
          {record.Results[1]?.time}
        </>
      ),
    },
    {
      title: "3rd",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[2]?.id)?.schoolinitials} <br />
          {record.Results[2]?.time}
        </>
      ),
    },
    {
      title: "4th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[3]?.id)?.schoolinitials} <br />
          {record.Results[3]?.time}
        </>
      ),
    },
    {
      title: "5th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[4]?.id)?.schoolinitials} <br />
          {record.Results[4]?.time}
        </>
      ),
    },
    {
      title: "6th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[5]?.id)?.schoolinitials} <br />
          {record.Results[5]?.time}
        </>
      ),
    },
    {
      title: "7th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[6]?.id)?.schoolinitials} <br />
          {record.Results[6]?.time}
        </>
      ),
    },
    {
      title: "8th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[7]?.id)?.schoolinitials} <br />
          {record.Results[7]?.time}
        </>
      ),
    },
    {
      title: "9th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[8]?.id)?.schoolinitials} <br />
          {record.Results[8]?.time}
        </>
      ),
    },
    {
      title: "10th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[9]?.id)?.schoolinitials} <br />
          {record.Results[9]?.time}
        </>
      ),
    },
    {
      title: "11th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[10]?.id)?.schoolinitials} <br />
          {record.Results[10]?.time}
        </>
      ),
    },
    {
      title: "12th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[11]?.id)?.schoolinitials} <br />
          {record.Results[11]?.time}
        </>
      ),
    },
    {
      title: "13th",
      key: "Results",
      render: (text, record) => (
        <>
          {GetSchoolInfo(record.Results[12]?.id)?.schoolinitials} <br />
          {record.Results[12]?.time}
        </>
      ),
    },
  ];

  return (
    <div>
      <h1 style={{ fontSize: "1.5em" }}>Indoor Race Results</h1>
      <Button
        type="primary"
        onClick={() => {
          SetModalOpen(true);
        }}
      >
        Add Race Results
      </Button>
      <Table
        columns={columns}
        dataSource={raceResults}
        pagination={{ defaultPageSize: 50 }}
      />
      <AddModal
        key={`Indoor Race Results Modal ${addModalOpen}`}
        modalVisible={addModalOpen}
        OnSubmit={(e) => {
          SetModalOpen(false);
          console.log(e);

          var { eventname, times } = e;
          var raceorder = [];
          e.schools.forEach((s) => {
            var time = times[s];
            raceorder.push({
              time: time,
              username: s,
              id: schools.filter((sc) => sc.username == s)[0]._id,
            });
          });
          raceorder.sort((a, b) => {
            if (a.time > b.time) {
              return 1;
            }
            if (a.time < b.time) {
              return -1;
            }
            return 0;
          });

          console.log(raceorder);

          AddIndoorRaceResults({
            EventName: eventname,
            Results: raceorder,
          }).then((res) => {
            FetchData();
          });

          //Records
          const record = indoorRecords.CurrentRecords.filter(
            (r) => r.EventName == eventname
          )[0];

          if (record) {
            if (raceorder[0].time < record.Time) {
              var recordSchoolID = raceorder[0].id;

              var raceObject = races.filter(
                (r) =>
                  r.EventName == eventname &&
                  r.Participants.map((p) => p.school).includes(recordSchoolID)
              )[0];

              var entry = eventEntries.filter(
                (e) => e.RaceName == eventname && e.Team == recordSchoolID
              )[0];
              var allRowers = [
                ...entry.Rowers,
                ...entry.SubRowers,
                ...entry.Coxers,
                ...entry.SubCoxers,
              ];

              var participatingRowers = allRowers.filter((r) =>
                raceObject.PontoonSheet.map((s) => s.id).includes(r)
              );
              console.log(participatingRowers);

              participatingRowers = participatingRowers.map(
                (p) => rowers.filter((r) => r._id == p)[0]
              );

              console.log(participatingRowers);
              const year = new Date().getFullYear();
              AddNewIndoorRecord(
                eventname,
                participatingRowers.map((r) => r.Name).join(", "),
                participatingRowers[0].Team.schoolname,
                raceorder[0].time,
                year
              ).then((res) => {
                FetchData();
              });
            }
          }
        }}
        onCloseModal={() => {
          SetModalOpen(false);
        }}
        listOfRaces={listOfRaces.filter((r) => {
          if (!raceResults.map((rr) => rr.EventName).includes(r)) {
            return true;
          }
          return false;
        })}
        eventEntries={eventEntries}
        schools={schools}
        records={indoorRecords.CurrentRecords}
      />
      <div></div>
    </div>
  );
}

const AddModal = ({
  modalVisible,
  OnSubmit,
  onCloseModal,
  listOfRaces,
  eventEntries,
  schools,
  records,
}) => {
  const [form] = Form.useForm();
  const [formValues, SetFormValues] = useState({});

  const { Option } = Select;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ EventName: formValues.EventName });
    SetFormValues({ EventName: formValues.EventName });
  }, [formValues.EventName]);

  var entries = eventEntries.filter((e) => e.RaceName == formValues.EventName);
  entries.forEach((entry, index) => {
    entries[index].School = schools.filter((s) => s._id == entry.Team)[0];
  });

  const record = records.filter((r) => r.EventName == formValues.EventName)[0];
  console.log(record);

  var submitValid = GetSubmitValid();

  function GetSubmitValid() {
    var schoolnames = entries.map((e) => e.School.username);
    var isValid = true;
    schoolnames.forEach((name) => {
      if (!formValues[name]) {
        isValid = false;
      } else {
        let re = /^\d{2}:\d{2}:\d$/;
        if (!re.test(formValues[name])) {
          isValid = false;
        }
      }
    });

    if (form.getFieldsError().filter(({ errors }) => errors.length).length) {
      isValid = false;
    }

    return isValid;
  }

  return (
    <>
      {" "}
      <Modal
        title={`Add race results`}
        forceRender
        visible={modalVisible}
        onOk={() => {}}
        onCancel={onCloseModal}
        okText="Add"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          disabled: !submitValid,
          onClick: () => {
            OnSubmit({
              times: formValues,
              schools: [...entries.map((e) => e.School.username)],
              eventname: formValues.EventName,
            });
          },
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <div>
          <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={() => {
              // SetFormStep(2);
            }}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="horizontal"
            size="large"
            onValuesChange={() => {
              SetFormValues(form.getFieldsValue());
            }}
          >
            <Form.Item
              label="Event Name"
              name="EventName"
              rules={[
                {
                  required: true,
                  message: "Please input an Event Name",
                },
                {
                  message: "This race has already been setup",
                  validator: (_, value) => {
                    return Promise.resolve();
                    /*   if (!races.map((r) => r.EventName).includes(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("This race has already been setup");
                  } */
                  },
                },
              ]}
            >
              <Select showSearch>
                {listOfRaces.map(function (item, i) {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Form.Item>
            {entries.map((e, index) => {
              var recordText = "";
              let re = /^\d{2}:\d{2}:\d$/;
              if (record && re.test(formValues[e.School.username])) {
                recordText =
                  formValues[e.School.username] < record.Time
                    ? "(New Record!)"
                    : "";
              }
              return (
                <>
                  {" "}
                  <Form.Item
                    label={`${e.School.username} ${recordText}`}
                    name={e.School.username}
                    rules={[
                      {
                        required: true,
                        message: "Input race time",
                      },
                      {
                        message: "Enter in the format 'MM:SS:S' ",
                        validator: (_, value) => {
                          let re = /^\d{2}:\d{2}:\d$/;
                          if (re.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Enter in the format 'MM:SS:S' "
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              );
            })}
          </Form>
        </div>
      </Modal>
    </>
  );
};
