import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import styles from "./Schools.module.css";
import ViewSchoolInformation from "./ViewSchoolInformation";
import SchoolEntries from "./SchoolEntries";
import { UserContext } from "../../UserContext";

import StudentRegistrations from "../StudentRegistrations/StudentRegistrations";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  AutoComplete,
  Space,
  Spin,
} from "antd";

import { reverse, result, find, filter } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";

import { GetRowers } from "../../APIManager";
import { Table } from "antd";
const { confirm } = Modal;
export default function SchoolRowers({
  schoolid,
  schoolname,
  modalVisible,
  onCloseModal,
}) {
  const [Rowers, SetRowers] = useState([]);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  console.log(schoolname);

  useEffect(() => {
    SetWaitingOnResponse(true);
    GetRowers().then((res) => {
      var rawrowers = res.data;
      rawrowers = rawrowers.filter((rower) => {
        return rower.Team._id === schoolid;
      });
      SetRowers(rawrowers);
      SetWaitingOnResponse(false);
    });
  }, [schoolid]);

  if (!schoolid) {
    return null;
  }

  return (
    <Modal
      title={`${schoolname} Rowers`}
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={onCloseModal}
      okText="Delete"
      cancelText="cancel"
      width="1200px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      {waitingOnResponse ? (
        <>
          {" "}
          <h1 style={{ fontSize: "1.5em" }}>Loading</h1>
          <Spin size="large" />
        </>
      ) : (
        <div>
          {Rowers.length < 1 ? (
            <>
              <h1>No Rowers Added</h1>
            </>
          ) : (
            <>
              <div>
                <StudentRegistrations students={Rowers} />
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
}
