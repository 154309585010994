import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import styles from "./Schools.module.css";
import { getLoggedInUser, AddEventEntries, GetSchools } from "../../APIManager";
import ViewSchoolInformation from "./ViewSchoolInformation";
import SchoolEntries from "./SchoolEntries";
import SchoolRowers from "./SchoolRowers";

import { UserContext } from "../../UserContext";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  AutoComplete,
  Space,
} from "antd";

import { reverse, result, find, filter } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";

import { GetRowers } from "../../APIManager";
import { Table } from "antd";
const { confirm } = Modal;
const StudentRegistrations = () => {
  const history = useHistory();
  const [user, setUser] = useContext(UserContext);

  const [isNewModalVisible, SetIsNewModalVisible] = useState(false);
  const [isDeleteModalVisible, SetIsDeleteModalVisible] = useState(false);
  const [isViewModalVisible, SetIsViewModalVisible] = useState(false);
  const [isEntriesModalVisible, SetIsEntriesModalVisible] = useState(false);
  const [isSchoolRowersModalVisible, SetSchoolRowersModalVisible] =
    useState(false);

  const [SelectedSchoolID, SetSelectedSchoolID] = useState("");

  const [students, SetStudents] = useState([]);
  const [schools, SetSchools] = useState([]);

  useEffect(() => {
    FetchStudents();
  }, []);

  function FetchStudents() {
    GetSchools().then((res) => {
      SetSchools(res.data.reverse());
    });
  }
  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.username.indexOf(value) === 0,
    },
    {
      title: "Password",
      dataIndex: "recovery",
    },
    {
      title: "Schoool Name",
      dataIndex: "schoolname",
      sorter: (a, b) => a.schoolname.localeCompare(b.schoolname),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.schoolname.indexOf(value) === 0,
    },
    {
      title: "Email Address",
      dataIndex: "emailaddress",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {/*    <a
            onClick={() => {
              SetSelectedSchoolID(record._id);
              SetIsDeleteModalVisible(true);
            }}
          >
            Delete
          </a> */}
          <a
            onClick={() => {
              SetSelectedSchoolID(record._id);
              SetIsViewModalVisible(true);
            }}
          >
            Details
          </a>
          <a
            onClick={() => {
              SetSelectedSchoolID(record._id);
              SetIsEntriesModalVisible(true);
            }}
          >
            Entries
          </a>
          <a
            onClick={() => {
              SetSelectedSchoolID(record._id);
              SetSchoolRowersModalVisible(true);
            }}
          >
            Rowers
          </a>
        </Space>
      ),
    },
  ];
  return (
    <div className={styles.MainDiv}>
      <h1 style={{ fontSize: "2em" }}>Schools</h1>
      <ViewSchoolInformation
        schoolid={SelectedSchoolID}
        modalVisible={isViewModalVisible}
        closeModal={() => {
          SetIsViewModalVisible(false);
        }}
      />
      <SchoolEntries
        schoolid={SelectedSchoolID}
        modalVisible={isEntriesModalVisible}
        closeModal={() => {
          SetIsEntriesModalVisible(false);
        }}
      />
      <SchoolRowers
        schoolid={SelectedSchoolID}
        schoolname={
          schools.find((school) => {
            return school._id === SelectedSchoolID;
          })?.schoolname
        }
        modalVisible={isSchoolRowersModalVisible}
        onCloseModal={() => {
          SetSchoolRowersModalVisible(false);
        }}
      />
      <Table
        columns={columns}
        dataSource={schools}
        pagination={{ defaultPageSize: 50 }}
      />
    </div>
  );
};
export default StudentRegistrations;
