import React, { useState, useContext, useEffect } from "react";
import { Prompt } from "react-router";
import { UserContext } from "../../UserContext";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  message,
  Space,
  DatePicker,
  List,
  Popconfirm,
  Typography,
  Table,
  Tooltip,
  Tag,
  Select,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ChromePicker } from "react-color";
import {
  ExclamationCircleOutlined,
  DeleteFilled,
  StopOutlined,
} from "@ant-design/icons";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  UpdateCMSDoc,
  GetCMSDoc,
  GetRestorePoints,
  CreateRestorePoint,
  RestoreCollection,
  RestoreAllCollections,
  DownloadAllBackups,
  DownloadCollectionBackup,
} from "../../APIManager";

import moment from "moment";

const { confirm } = Modal;
const { TextArea } = Input;
const { Text } = Typography;
const BackupAndRestorePage = () => {
  return (
    <div>
      <DownloadBackup />
      <br />
      <RestorePoints />
      <br />
    </div>
  );
};

const DownloadBackup = () => {
  const [selectedCollection, SetSelectedCollection] = useState(null);

  return (
    <div>
      <Divider>
        <strong>Download Backup</strong>
      </Divider>
      <Space>
        <Select
          placeholder="Select a name"
          style={{ minWidth: 200 }}
          onChange={(e) => {
            SetSelectedCollection(e);
            console.log(e);
          }}
        >
          {/* Replace with real options */}
          <Select.Option value="awardnominations">
            awardnominations
          </Select.Option>
          <Select.Option value="cms">cms</Select.Option>
          <Select.Option value="coxweights">coxweights</Select.Option>
          <Select.Option value="disqualifications">
            disqualifications
          </Select.Option>
          <Select.Option value="filedocuments">filedocuments</Select.Option>
          <Select.Option value="indoorrecords">indoorrecords</Select.Option>
          <Select.Option value="indoorresults">indoorresults</Select.Option>
          <Select.Option value="penaltycards">penaltycards</Select.Option>
          <Select.Option value="raceentries">raceentries</Select.Option>
          <Select.Option value="races">races</Select.Option>
          <Select.Option value="rowers">rowers</Select.Option>
          <Select.Option value="s3objects">s3objects</Select.Option>
          <Select.Option value="schedules">schedules</Select.Option>
          <Select.Option value="users">users</Select.Option>
        </Select>
        <Button
          type="primary"
          disabled={!selectedCollection}
          onClick={() => {
            DownloadCollectionBackup(selectedCollection);
          }}
        >
          Download
        </Button>
        <Button type="primary" onClick={DownloadAllBackups}>
          Download All
        </Button>
      </Space>
    </div>
  );
};

const RestorePoints = () => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [restores, SetRestores] = useState([]);

  const [form] = Form.useForm();

  function handleAdd(values) {
    const { Collections, Name } = values;

    const exists = restores.some((item) => item.Name === Name);
    if (exists) {
      message.error("An entry with the same restore name  already exists.");
      return;
    }

    CreateRestorePoint({ Collections, Name }).then((res) => {
      form.resetFields();
      setAddModalVisible(false);
      FetchRestorePoints();
    });
  }

  function handleRestoreAllCollections(Name) {
    RestoreAllCollections({ Name })
      .then(() => {
        message.success(
          `Parent restore point "${Name}" restored successfully.`
        );
      })
      .catch((err) => {
        message.error(`Failed to restore parent restore point: ${err.message}`);
      });
  }

  function handleRestoreCollection(Name, Collection) {
    RestoreCollection({ Name, Collection })
      .then(() => {
        message.success(
          `Child restore point "${Name}" in collection "${Collection}" restored successfully.`
        );
      })
      .catch((err) => {
        message.error(`Failed to restore child restore point: ${err.message}`);
      });
  }

  function FetchRestorePoints() {
    GetRestorePoints().then((res) => {
      SetRestores(res.data);
    });
  }

  useEffect(() => {
    FetchRestorePoints();
  }, []);

  // Group data by year
  const groupedData = restores.reduce((acc, item) => {
    const restoreGroup = acc.find((group) => group.Name === item.Name);
    if (restoreGroup) {
      restoreGroup.children.push(item);
    } else {
      acc.push({
        Name: item.Name,
        createdAt: item.createdAt, // Copy over date from the first item
        CreatedBy: item.CreatedBy, // Copy over createdBy from the first item
        Type: item.Type, // Copy over type from the first item
        children: [item],
      });
    }
    return acc;
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (_, record) => record.Name || record.Name, // For root and nested rows
    },
    {
      title: "Collection",
      dataIndex: "Collection",
      key: "Collection",
      render: (_, record) => (record.children ? null : record.Collection), // Hide for parent rows
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "Date",
      render: (createdAt) => new Date(createdAt).toLocaleString(), // Format date
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      render: (type) => <Tag color={getTagColor(type)}>{type}</Tag>, // Render Type with Tag
    },
    {
      title: "Created By",
      dataIndex: "CreatedBy",
      key: "CreatedBy",
      render: (CreatedBy) => CreatedBy?.username || "Unknown", // Handle missing CreatedBy
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <span>
          <Popconfirm
            title="Are you sure to restore this entry?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              if (record.children) {
                // Parent row
                handleRestoreAllCollections(record.Name);
              } else {
                // Child row
                handleRestoreCollection(record.Name, record.Collection);
              }
            }}
          >
            <Button type="link" onClick={() => {}}>
              Restore
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure to delete this entry?"
            okText="Yes"
            cancelText="No"
          >
            {/* Additional delete functionality if needed */}
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Modal
        title="Create restore point"
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleAdd}>
          <Form.Item
            name="Collections"
            label="Collections"
            rules={[{ required: true, message: "Please select a name" }]}
          >
            <Select
              placeholder="Select collections to include in backup"
              mode="multiple"
              style={{ width: "100%" }}
              prop
            >
              <Select.Option value="awardnominations">
                awardnominations
              </Select.Option>
              <Select.Option value="cms">cms</Select.Option>
              <Select.Option value="coxweights">coxweights</Select.Option>
              <Select.Option value="disqualifications">
                disqualifications
              </Select.Option>
              <Select.Option value="filedocuments">filedocuments</Select.Option>
              <Select.Option value="indoorrecords">indoorrecords</Select.Option>
              <Select.Option value="indoorresults">indoorresults</Select.Option>
              <Select.Option value="oldcollections">
                oldcollections
              </Select.Option>
              <Select.Option value="penaltycards">penaltycards</Select.Option>
              <Select.Option value="raceentries">raceentries</Select.Option>
              <Select.Option value="races">races</Select.Option>
              <Select.Option value="rowers">rowers</Select.Option>
              <Select.Option value="s3objects">s3objects</Select.Option>
              <Select.Option value="schedules">schedules</Select.Option>
              <Select.Option value="users">users</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="Name"
            label="Restore Point Name"
            rules={[
              { required: true, message: "Enter name for this restore point" },
            ]}
          >
            <Input placeholder="Enter name for this restore point" />
          </Form.Item>
        </Form>
      </Modal>
      <Divider>
        <strong>Create Restore Point</strong>
      </Divider>
      <Table
        columns={columns}
        dataSource={groupedData.reverse()}
        rowKey={(record) => record.Name || record.id} // Unique keys for both group and child rows
        style={{ marginTop: 16 }}
      />
      <br />
      <br />
      <Button
        type="primary"
        onClick={() => {
          setAddModalVisible(true);
        }}
      >
        Create restore point
      </Button>
    </div>
  );
};

const getTagColor = (type) => {
  switch (type) {
    case "User Created":
      return "blue";
    case "Automatic":
      return "green";
    default:
      return "gray";
  }
};

export default BackupAndRestorePage;
