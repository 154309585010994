export function GetRaceAgeGroup(EventName) {
  if (EventName.includes("25")) {
    return "U25";
  }
  if (EventName.includes("21")) {
    return "U21";
  }
  if (EventName.includes("18")) {
    return "U18";
  }
  if (EventName.includes("15")) {
    return "U15";
  }
}

export function GetRaceGender(EventName) {
  if (EventName.includes("Boys")) {
    return "Male";
  }
  if (EventName.includes("Girls")) {
    return "Female";
  }
}

export function GetRaceClass(EventName) {
  if (
    EventName.includes("Scull") ||
    EventName.includes("Pair") ||
    EventName.includes("Coxed")
  ) {
    return "Water Race";
  }
  if (
    !EventName.includes("Scull") &&
    !EventName.includes("Pair") &&
    !EventName.includes("Coxed")
  ) {
    return "Indoor Race";
  }
}

export function GetDefaultRaceDuration(racename) {
  if (racename.includes("4 x 500m")) {
    return 15;
  }
  if (racename.includes("2 x 1000m")) {
    return 10;
  }
  if (racename.includes("5000m")) {
    return 30;
  }
  if (racename.includes("2000m")) {
    return 15;
  }
  if (racename.includes("500m")) {
    return 10;
  }
  if (
    racename.includes("Heat") ||
    racename.includes("Semi") ||
    racename.includes("Quarter")
  ) {
    return 10;
  }
  if (racename.includes("Final")) {
    return 15;
  }
  if (racename.includes("Repecharge")) {
    return 15;
  }
}
