import { React, useContext } from "react";
import { Table, Button, Modal } from "antd";
import { UserContext } from "../../UserContext";
import { GetSchoolInfo } from "../../APIManager";

import ReadOnlyUploadedFile from "../Shared Components/ReadOnlyUploadedFile";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";
export default function ViewSchoolInformation({
  schoolid,
  modalVisible,
  closeModal,
}) {
  const history = useHistory();
  const [school, SetSchool] = useState({});

  useEffect(() => {
    if (schoolid) {
      GetSchoolInfo(schoolid).then((res) => {
        SetSchool(res.data);
      });
    }
  }, [schoolid]);

  if (!schoolid || !school.username) {
    return null;
  }
  const dataSource = GetDataSource(school);
  const columns = [
    {
      dataIndex: "Key",
      key: "Key",
    },
    {
      dataIndex: "Value",
      key: "Value",
      render: (text, record) => {
        if (record?.Key.includes("Rowing Color")) {
          return (
            <div style={{ backgroundColor: record.Value }}>{record.Value}</div>
          );
        } else {
          return record.Value;
        }
      },
    },
  ];

  return (
    <Modal
      title="School Details"
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={closeModal}
      okText="Delete"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <div>
        <div>
          <h2>School Info</h2>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
          <br />
          <h2>School File Uploads</h2>
          <ul>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="School Declaration"
                FileURL={school.schooldeclaration}
              />
            </li>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="School Logo"
                FileURL={school.schoollogo}
              />
            </li>
          </ul>
        </div>
        <br />
      </div>
    </Modal>
  );

  function GetDataSource(school) {
    var mappings = {
      "School Name": school.schoolname,
      "User Name": school.username,
      Password: school.recovery,
      "School Initials": school.schoolinitials,
      University: school.university ? "Yes" : "No",
      International: school.international ? "Yes" : "No",
      "School Address": school.address,
      "School Email Address": school.emailaddress,
      Website: school.website,
      "AGM Voter": school.agmvotename,
      "Principal Name": school.principal?.Name,
      "Principal Title": school.principal?.Title,
      "Principal Email": school.principal?.Email,
      "Principal Phone Number": school.principal?.PhoneNumber,
      "Rowing Coordinator Name": school.rowingcoordinator?.Name,
      "Rowing Coordinator Title": school.rowingcoordinator?.Title,
      "Rowing Coordinator Email": school.rowingcoordinator?.Email,
      "Rowing Coordinator Phone Number": school.rowingcoordinator?.PhoneNumber,
      "Coach Name": school.coach?.Name,
      "Coach Email": school.coach?.Email,
      "Coach Phone Number": school.coach?.PhoneNumber,
      "Rowing Color 1": school.rowingcolors[0],
      "Rowing Color 2": school.rowingcolors[1],
      "Rowing Color 3": school.rowingcolors[2],
      "Best Cox Nomination": school.bestcoxnomination,
      Justification: school.bestcoxnominationjustification,
    };

    var keys = Object.keys(mappings);
    var dataSource = [];
    keys.forEach((key) => {
      dataSource.push({
        Key: key,
        Value: mappings[key],
      });
    });
    return dataSource;
  }
}
