import React, { useState, useContext, useEffect } from "react";
import { getLoggedInUser } from "../../APIManager";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  Card,
  Col,
  Row,
  List,
  Skeleton,
  Statistic,
} from "antd";
import styles from "./HomePage.module.css";
import { GetRowers, GetSchools } from "../../APIManager";
import { ListOfEvents } from "../../constants";

import PieChart from "./pieChart";
import ColumnChart from "./columnChart";

import { GetRaceClass } from "../../utility";

const HomePage = () => {
  const [schools, SetSchools] = useState([]);
  const [rowers, SetRowers] = useState([]);

  const U25Rowers = rowers.filter((rower) => {
    return rower.AgeGroup === "Under 25";
  });

  const U21Rowers = rowers.filter((rower) => {
    return rower.AgeGroup === "Under 21";
  });
  const U18Rowers = rowers.filter((rower) => {
    return rower.AgeGroup === "Under 18";
  });
  const U15Rowers = rowers.filter((rower) => {
    return rower.AgeGroup === "Under 15";
  });

  const FemaleRowers = rowers.filter((rower) => {
    return rower.Gender === "Female";
  });
  const MaleRowers = rowers.filter((rower) => {
    return rower.Gender === "Male";
  });

  useEffect(() => {
    GetRowers().then((res) => {
      SetRowers(res.data);
    });

    GetSchools().then((res) => {
      SetSchools(res.data);
    });
  }, []);

  return (
    <div>
      <div className={styles.NumbersContainer}>
        <Row gutter={16}>
          <Col span={6}>
            <Statistic title="Schools" value={schools.length} />
          </Col>
          <Col span={6}>
            <Statistic title="Rowers" value={rowers.length} />
          </Col>
          <Col span={6}>
            <Statistic title="Events" value={ListOfEvents.length} />
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={6}>
            <Statistic title="Under 25 Rowers" value={U25Rowers.length} />
          </Col>
          <Col span={6}>
            <Statistic title="Under 21 Rowers" value={U21Rowers.length} />
          </Col>
          <Col span={6}>
            <Statistic title="Under 18 Rowers" value={U18Rowers.length} />
          </Col>
          <Col span={6}>
            <Statistic title="Under 15 Rowers" value={U15Rowers.length} />
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={6}>
            <Statistic title="Male Rowers" value={MaleRowers.length} />
          </Col>
          <Col span={6}>
            <Statistic title="Female Rowers" value={FemaleRowers.length} />
          </Col>
        </Row>
      </div>
      <Charts rowers={rowers} schools={schools} ListOfEvents={ListOfEvents} />
    </div>
  );
};

const Charts = ({ rowers, schools, ListOfEvents }) => {
  var entriesByEvent = [];

  ListOfEvents.forEach((e) => {
    entriesByEvent.push({
      type: e,
      value: 0,
    });
  });

  schools.forEach((s) => {
    s.evententries.forEach((entry) => {
      var index = entriesByEvent.findIndex((e) => e.type == entry);
      if (index != -1) {
        entriesByEvent[index].value = entriesByEvent[index].value + 1;
      }
    });
  });

  console.log(entriesByEvent);

  const rowersByAge = [
    {
      type: "Under 15",
      value: rowers.filter((r) => r.AgeGroup === "Under 15").length,
    },
    {
      type: "Under 18",
      value: rowers.filter((r) => r.AgeGroup === "Under 18").length,
    },
    {
      type: "Under 21",
      value: rowers.filter((r) => r.AgeGroup === "Under 21").length,
    },
    {
      type: "Under 25",
      value: rowers.filter((r) => r.AgeGroup === "Under 25").length,
    },
  ];

  const rowersByGender = [
    {
      type: "Male",
      value: rowers.filter((r) => r.Gender === "Male").length,
    },
    {
      type: "Female",
      value: rowers.filter((r) => r.Gender === "Female").length,
    },
  ];

  const noOfEntriesBySchool = schools.map((s) => {
    return {
      type: s.schoolinitials,
      value: s.evententries.length,
      color: s.rowingcolors[0],
    };
  });

  const noOfRowersBySchool = schools.map((s) => {
    return {
      type: s.schoolinitials,
      value: rowers.filter((r) => r.Team.schoolname == s.schoolname).length,
      color: s.rowingcolors[0],
    };
  });

  return (
    <>
      {" "}
      <div className={styles.ChartsContainer}>
        <div>
          <PieChart data={rowersByAge} h1="Rowers by Age" />
        </div>
        <div>
          {" "}
          <PieChart data={rowersByGender} h1="Rowers by Gender" />
        </div>
      </div>
      <div className={styles.ChartsContainer}>
        <div>
          <ColumnChart
            data={entriesByEvent.filter(
              (e) => GetRaceClass(e.type) == "Water Race"
            )}
            h1="Entries by Event (Outdoor)"
          />
        </div>
      </div>
      <div className={styles.ChartsContainer}>
        <div>
          <ColumnChart
            data={entriesByEvent.filter(
              (e) => GetRaceClass(e.type) == "Indoor Race"
            )}
            h1="Entries by Event (Indoor)"
          />
        </div>
      </div>
      <div className={styles.ChartsContainer}>
        <div>
          <ColumnChart
            data={noOfEntriesBySchool}
            h1="No. of Entries By School"
            colorFromSchool
            schools={schools}
          />
        </div>
      </div>
      <div className={styles.ChartsContainer}>
        <div>
          <ColumnChart
            data={noOfRowersBySchool}
            h1="No. of Rowers By School"
            colorFromSchool
            schools={schools}
          />
        </div>
      </div>
    </>
  );
};

export default HomePage;
