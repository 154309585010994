import axios from "axios";

const DEVLOPMENT_MODE = false;
var rootPath = "";
if (DEVLOPMENT_MODE === true) {
  ///rootPath = "http://192.168.100.32:4002";
  //rootPath = "http://192.168.100.15:5003";
  rootPath = "http://127.0.0.1:5003";
} else {
  rootPath = "http://isrtestadminapi.kbc.org.pk";
  rootPath = "https://isradminapi.kbc.org.pk";
}

rootPath = process.env.REACT_APP_API_URL;
//const path = "http://localhost:4000";
//const path = "192.168.100.5:4000";
export function getLoggedInUser() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/LoggedInUser",
  });
  return data;
}

export function Login(username, password) {
  var data = axios({
    method: "POST",
    data: {
      username: username,
      password: password,
    },
    withCredentials: true,
    url: rootPath + "/login",
  });
  return data;
}

export function Signup(props) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      fullname: props.fullname,
      username: props.username,
      phonenumber: props.phonenumber,
      city: props.city,
      school: props.school,
      password: props.password,
    },
    withCredentials: true,
    url: rootPath + "/signup",
  });
  return data;
}

export function AddSchoolInfo(form) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      schoolname: form.SchoolFullName,
      schoolinitials: form.SchoolInitials,
      address: form.SchoolAddress,
      emailaddress: form.SchoolEmailAddress,
      website: form.SchoolWebsite,
      rowingcolors: [form.SchoolColor1, form.SchoolColor2, form.SchoolColor3],
      coach: {
        Name: form.CoachFullName,
        Email: form.CoachEmailAddress,
        PhoneNumber: form.CoachPhoneNumber,
      },
      principal: {
        Name: form.PrincipalFullName,
        Title: form.PrincipalTitle,
        Email: form.PrincipalEmailAddress,
        PhoneNumber: form.PrincipalPhoneNumber,
        ContactType: form.PrincipalContactType,
      },
      rowingcoordinator: {
        Name: form.RowingCoordinatorFullName,
        Title: form.RowingCoordinatorTitle,
        Email: form.RowingCoordinatorEmailAddress,
        PhoneNumber: form.RowingCoordinatorPhoneNumber,
        ContactType: form.RowingCoordinatorContactType,
      },
      agmvotename: form.AGMName,
      schooldeclaration: form.SchoolDeclaration,
      bestcoxnomination: form.COXNomination,
      schoollogo: form.SchoolLogo,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolInfo",
  });
  return data;
}

export function UpdateSchoolInfo(form) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      schoolname: form.SchoolFullName,
      schoolinitials: form.SchoolInitials,
      address: form.SchoolAddress,
      emailaddress: form.SchoolEmailAddress,
      website: form.SchoolWebsite,
      rowingcolors: [form.SchoolColor1, form.SchoolColor2, form.SchoolColor3],
      coach: {
        Name: form.CoachFullName,
        Email: form.CoachEmailAddress,
        PhoneNumber: form.CoachPhoneNumber,
      },
      principal: {
        Name: form.PrincipalFullName,
        Title: form.PrincipalTitle,
        Email: form.PrincipalEmailAddress,
        PhoneNumber: form.PrincipalPhoneNumber,
        ContactType: form.PrincipalContactType,
      },
      rowingcoordinator: {
        Name: form.RowingCoordinatorFullName,
        Title: form.RowingCoordinatorTitle,
        Email: form.RowingCoordinatorEmailAddress,
        PhoneNumber: form.RowingCoordinatorPhoneNumber,
        ContactType: form.RowingCoordinatorContactType,
      },
      agmvotename: form.AGMName,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolInfo",
  });
  return data;
}

export function AddEventEntries(EventEntries) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      evententries: EventEntries,
    },
    withCredentials: true,
    url: rootPath + "/AddEventEntries",
  });
  return data;
}
export function AddNewRower(form) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      Name: form.StudentFullName,
      YOB: form.StudentYOB,
      Gender: form.StudentGender,
      PhoneNumber: form.StudentPhoneNumber,
      Photograph: form.StudentPicture,
      Nationality: form.StudentNationality,
      CNIC: form.StudentCNIC,
      Declaration: form.StudentDeclaration,
      Vaccination: form.StudentVaccination,
      KBCMember: form.KBCMember,
      KBCNumber: form.StudentKBCNumber,
      PreviousKBC: form.PreviousRegatta,
      COX: form.COX,
      COXSeminar: form.CoxSeminar,
      COXTest: form.CoxTest,
      PreviousCOX: form.CoxPreviousRegatta,
    },
    withCredentials: true,
    url: rootPath + "/AddNewRower",
  });
  return data;
}

export function AddNewRaceEntry(
  RaceName,
  AgeGroup,
  Gender,
  Class,
  Rowers,
  Coxers,
  SubRowers,
  SubCoxers
) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      RaceName: RaceName,
      AgeGroup: AgeGroup,
      Gender: Gender,
      Class: Class,
      Rowers: Rowers,
      Coxers: Coxers,
      SubRowers: SubRowers,
      SubCoxers: SubCoxers,
    },
    withCredentials: true,
    url: rootPath + "/AddNewRaceEntry",
  });
  return data;
}

export function GetEventEntries() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetEventEntries",
  });
  return data;
}

export function UpdateTeamRoles(captain, vicecaptain) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      captain: captain,
      vicecaptain: vicecaptain,
    },
    withCredentials: true,
    url: rootPath + "/UpdateTeamRoles",
  });
  return data;
}

export function DownloadFile(FileURL) {
  window.open(rootPath + `/DownloadFile/${encodeURIComponent(FileURL)}`);
}

export function UpdateSchoolBestCoxNomination(file) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file: file,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolBestCoxNomination",
  });
  return data;
}
export function UpdateSchoolLogo(file) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file: file,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolLogo",
  });
  return data;
}

export function UpdateSchoolDeclaration(file) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file: file,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolDeclaration",
  });
  return data;
}

//Deprecated - Moved to Route
export function GetRower(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/rowers/${id}`,
  });
  return data;
}

export function GetIsRowerValidToDelete(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/isStudentValidToDelete/${id}`,
  });
  return data;
}

export function GetDeleteStudent(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/deletestudent/${id}`,
  });
  return data;
}

export function UpdateRaceEntry(
  RaceName,
  AgeGroup,
  Gender,
  Class,
  Rowers,
  Coxers,
  SubRowers,
  SubCoxers
) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      RaceName: RaceName,
      AgeGroup: AgeGroup,
      Gender: Gender,
      Class: Class,
      Rowers: Rowers,
      Coxers: Coxers,
      SubRowers: SubRowers,
      SubCoxers: SubCoxers,
    },
    withCredentials: true,
    url: rootPath + "/UpdateRaceEntry",
  });
  return data;
}

export function GetStudentEvents(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/rowers/rower/evententries/${id}`,
  });
  return data;
}

export function GetUnAssignedStudents() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/UnAssgignedStudents",
  });
  return data;
}

export function GetFormCheckList() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/FormCheckList",
  });
  return data;
}

export function SubmitFinalForm() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/FinalSubmitForm",
  });
  return data;
}

export function AddNewSchool(form) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/api/schools/create",
    data: form,
  });
  return data;
}

export function GetSchools() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/api/schools",
  });
  return data;
}

export function GetSchoolNames() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/api/schools/names",
  });
  return data;
}

export function GetSchoolInfo(schoolID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/schools/id/${schoolID}`,
  });
  return data;
}

export function GetSchoolRaceEntries(schoolID) {
  console.log("get school entries");
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/schools/school/evententries/${schoolID}`,
  });
  return data;
}

//Deprecated - Moved to Route
export function GetRowers() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/api/rowers",
  });
  return data;
}

export function GetFormStatus() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/api/formstatus",
  });
  return data;
}

export function GetEventEntriesReport() {
  window.open(rootPath + `/api/reports/download/EventEntries`);
}

export function GetEntriesPerEventReport() {
  window.open(rootPath + `/api/reports/download/EntriesPerEvent`);
}

export function GetSchoolContactPersonsReport() {
  window.open(rootPath + `/api/reports/Download/SchoolContactPersons`);
}

export function GetSchoolCaptainsReport() {
  window.open(rootPath + `/api/reports/Download/SchoolCaptains`);
}

export function GetSchoolRowersReport() {
  window.open(rootPath + `/api/reports/download/SchoolRowers`);
}

export function GetSchoolEventEntriesReport() {
  window.open(rootPath + `/api/reports/download/SchoolEntriesDetails`);
}

export function GetSchoolRowers(schoolID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/schools/school/rowers/${schoolID}`,
  });
  return data;
}

export function GetSchoolUnAssignedStudents(schoolID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/UnSchoolAssgignedStudents/${schoolID}`,
  });
  return data;
}

export function UpdateSchoolPaymentStatus(schoolID, PaymentStatus) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      schoolID: schoolID,
      PaymentStatus: PaymentStatus,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolPaymentStatus",
  });
  return data;
}

export function UpdateSchoolPaymentWaivedStatus(schoolID, waivedStatus) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      schoolID: schoolID,
      waivedStatus: waivedStatus,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolPaymentWaivedStatus",
  });
  return data;
}

export function UpdateSchoolStagesComplete(schoolID, stageName, newStatus) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      schoolID,
      stageName,
      newStatus,
    },
    withCredentials: true,
    url: rootPath + "/api/formstatus/UpdateStagesComplete",
  });
  return data;
}

export function GetPontoonSheet(racename) {
  window.open(rootPath + `/api/reports/download/PontoonSheet/${racename}`);
}

export function GetSchoolRowersKBCReport() {
  window.open(rootPath + `/api/reports/Download/SchoolRowersWithKBCNum`);
}

export function GetIDCardsSheet() {
  window.open(rootPath + `/api/reports/Download/IDCardsSheet`);
}

export function GetCoxwainsReport() {
  window.open(rootPath + `/api/reports/Download/Coxes`);
}

export function UpdateSchoolFlagStatus(schoolID, FlagStatus) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      schoolID: schoolID,
      FlagStatus: FlagStatus,
    },
    withCredentials: true,
    url: rootPath + "/api/formstatus/updateschoolflagstatus",
  });
  return data;
}

export function GetAdminUsers() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetAdminUsers`,
  });
  return data;
}

export function UpdateEmployeePassword(userid, password) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      id: userid,
      password: password,
    },
    withCredentials: true,
    url: rootPath + "/ChangeEmployeePassword",
  });
  return data;
}

export function DeleteEmployeeAccount(userid) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      id: userid,
    },
    withCredentials: true,
    url: rootPath + "/DeleteEmployeeAccount",
  });
  return data;
}
export function AddEmployeeAccount({ username, password, permissions }) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      username,
      password,
      permissions,
    },
    withCredentials: true,
    url: rootPath + "/addnewemployee",
  });
  return data;
}

export function UpdateEmployeePermissions({ id, permissions }) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      id,
      permissions,
    },
    withCredentials: true,
    url: rootPath + "/changeemployeepermissions",
  });
  return data;
}

export function GetRaces() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/Races`,
  });
  return data;
}

export function AddNewRaces(raceObjects) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      raceObjects,
    },
    withCredentials: true,
    url: rootPath + "/AddNewRaces",
  });
  return data;
}

export function GetSchedules() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/schedules`,
  });
  return data;
}

export function InsertRaceToSchedule(racename, day, raceduration) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      racename,
      day,
      raceduration,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/race/insert",
  });
  return data;
}

export function ChangeRaceTime(raceID, day, newTimeStart, newTimeEnd) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      raceID,
      day,
      newTimeStart,
      newTimeEnd,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/race/updatetime",
  });
  return data;
}

export function RemoveRaceFromSchedule(racename, day) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      racename,
      day,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/race/remove",
  });
  return data;
}

export function InsertBreakToSchedule(day, timestart, timeend) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      day,
      timestart,
      timeend,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/break/insert",
  });
  return data;
}

export function RemoveBreakFromSchedule(breakID, day) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      breakID,
      day,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/break/remove",
  });
  return data;
}

export function AddNewRaceDay({ date, day }) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      date: date,
      day: day,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/AddNewRaceDay",
  });
  return data;
}
export function DeleteRaceDay({ date, day }) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      date: date,
      day: day,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/DeleteRaceDay",
  });
  return data;
}

export function SwitchScheduleClass({ day, newStatus }) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      day: day,
      newStatus,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/switchclass",
  });
  return data;
}

export function UnScheduleAllRaces({ date, day }) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      date: date,
      day: day,
    },
    withCredentials: true,
    url: rootPath + "/api/schedules/UnScheduleAllRaces",
  });
  return data;
}

export function UpdateCMSDoc({ docname, data }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      docname: docname,
      data: data,
    },
    withCredentials: true,
    url: rootPath + "/UpdateCMSDoc",
  });
  return data;
}

export function GetCMSDoc(docname) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetCMSDoc/${docname}`,
  });
  return data;
}

export function GetEntriesForEvent(eventName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetEntriesForEvent/${eventName}`,
  });
  return data;
}

export function AddRaceResults({ raceName, raceResults }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      raceName,
      raceResults,
    },
    withCredentials: true,
    url: rootPath + "/AddRaceResults",
  });
  return data;
}

export function GetRacesForEvent(eventName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetRacesForEvent/${eventName}`,
  });
  return data;
}

export function UpdateSubstitutions({ EventName, SchoolID, Subs }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      EventName,
      SchoolID,
      Subs,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSubstitutions",
  });
  return data;
}

export function LockPontoonSheet({
  RaceName,
  EventName,
  rowers,
  DisqualifiedTeams,
}) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      EventName,
      RaceName,
      rowers,
      DisqualifiedTeams,
    },
    withCredentials: true,
    url: rootPath + "/LockPontoonSheet",
  });
  return data;
}

export function LockSchoolPontoonSheet({
  RaceName,
  EventName,
  rowers,
  DisqualifiedTeams,
  SchoolID,
}) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      EventName,
      RaceName,
      rowers,
      DisqualifiedTeams,
      SchoolID,
    },
    withCredentials: true,
    url: rootPath + "/LockSchoolPontoonSheet",
  });
  return data;
}

export function VerifyCoxWeightStatus({ coxID, day }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      coxID,
      day,
    },
    withCredentials: true,
    url: rootPath + "/VerifyCoxWeightStatus",
  });
  return data;
}

export function LockCoxWeight({ coxID, day, weight, sandbag, signedBy }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      coxID,
      day,
      weight,
      sandbag,
      signedBy,
    },
    withCredentials: true,
    url: rootPath + "/LockCoxWeight",
  });
  return data;
}

export function GetCoxWeights(day) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetCoxWeights/${day}`,
  });
  return data;
}

export function GetStartSheetReport(day) {
  window.open(rootPath + `/GetStartSheet/${day}`);
}

export function GetResultsSheet(day) {
  window.open(rootPath + `/GetResultsSheet/${day}`);
}

export function GetIndoorRaceResults() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetIndoorRaceResults`,
  });
  return data;
}

export function AddIndoorRaceResults({ EventName, Results }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      EventName,
      Results,
    },
    withCredentials: true,
    url: rootPath + "/AddIndoorRaceResults",
  });
  return data;
}

export function DeleteIndoorRaceResults(EventName) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      EventName,
    },
    withCredentials: true,
    url: rootPath + "/DeleteIndoorRaceResults",
  });
  return data;
}

export function GetSchoolPaymentDetails() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/formstatus/paymentdetails`,
  });
  return data;
}

export function AddNewPenaltyCard(
  Type,
  Assignment,
  Application,
  RaceName,
  EventName,
  Reason,
  SignedBy,
  RowerID,
  SchoolID
) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Type,
      Assignment,
      Application,
      RaceName,
      EventName,
      Reason,
      SignedBy,
      RowerID,
      SchoolID,
    },
    withCredentials: true,
    url: rootPath + "/AddNewPenaltyCard",
  });
  return data;
}

export function GetPenaltyCards() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetPenaltyCards`,
  });
  return data;
}

export function DeletePenlatyCard(cardID) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      cardID,
    },
    withCredentials: true,
    url: rootPath + "/DeletePenaltyCard",
  });
  return data;
}

export function AddNewIndoorRecord(
  EventName,
  RowerName,
  SchoolName,
  Time,
  Year
) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      EventName,
      RowerName,
      SchoolName,
      Time,
      Year,
    },
    withCredentials: true,
    url: rootPath + "/AddNewIndoorRecord",
  });
  return data;
}

export function GetIndoorRecords() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetIndoorRecords`,
  });
  return data;
}

export function DeleteIndoorRecord(recordID) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      recordID,
    },
    withCredentials: true,
    url: rootPath + "/DeleteIndoorRecord",
  });
  return data;
}

export function AddNewDisqualification(
  Type,
  EventName,
  RaceName,
  Reason,
  SignedBy,
  SchoolID
) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Type,
      EventName,
      RaceName,
      Reason,
      SignedBy,
      SchoolID,
    },
    withCredentials: true,
    url: rootPath + "/AddNewDisqualification",
  });
  return data;
}

export function GetDisqualifications() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetDisqualifications`,
  });
  return data;
}

export function DeleteDisqualification(dqID) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      dqID,
    },
    withCredentials: true,
    url: rootPath + "/DeleteDisqualification",
  });
  return data;
}

export function GetPointsTable() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetPointsTable`,
  });
  return data;
}

export function GetCoxWeightStatus(day) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      day,
    },
    withCredentials: true,
    url: rootPath + "/GetCoxWeightStatus",
  });
  return data;
}

export function UploadNewFileDocument({
  Name,
  Tags,
  Section,
  FileBase64,
  FileName,
}) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Name,
      Tags,
      Section,
      FileBase64,
      FileName,
    },
    withCredentials: true,
    url: rootPath + "/api/filedocuments/upload",
  });
  return data;
}

export function GetFileDocuments() {
  // console.log(props);

  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/api/filedocuments",
  });
  return data;
}

export function DeleteFileDocument(id) {
  // console.log(props);

  var data = axios({
    method: "POST",
    withCredentials: true,
    data: {
      id: id,
    },
    url: rootPath + "/api/filedocuments/delete",
  });
  return data;
}

export function UploadLogo({ LogoName, FileName, FileBase64 }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      LogoName,
      FileName,
      FileBase64,
    },
    withCredentials: true,
    url: rootPath + "/UploadLogo",
  });
  return data;
}

export function GetOldCollections() {
  // console.log(props);

  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/api/oldcollections/",
  });
  return data;
}

export function AddOldCollection({ Name, Year, Data }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Name,
      Year,
      Data,
    },
    withCredentials: true,
    url: rootPath + "/api/oldcollections/addcollection",
  });
  return data;
}

export function DeleteOldCollection({ Name, Year }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Name,
      Year,
    },
    withCredentials: true,
    url: rootPath + "/api/oldcollections/deletecollection",
  });
  return data;
}

export function CreateRestorePoint({ Name, Collections }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Name,
      Collections,
    },
    withCredentials: true,
    url: rootPath + "/api/restorepoints/create",
  });
  return data;
}

export function GetRestorePoints() {
  // console.log(props);

  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/api/restorepoints/",
  });
  return data;
}

export function RestoreCollection({ Name, Collection }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Name,
      Collection,
    },
    withCredentials: true,
    url: rootPath + "/api/restorepoints/restorecollection",
  });
  return data;
}

export function RestoreAllCollections({ Name }) {
  // console.log(props);

  var data = axios({
    method: "POST",
    data: {
      Name,
    },
    withCredentials: true,
    url: rootPath + "/api/restorepoints/restoreallcollections",
  });
  return data;
}

export function DownloadAllBackups() {
  window.open(rootPath + `/api/backups/download-all`);
}

export function DownloadCollectionBackup(collectionName) {
  window.open(
    rootPath + `/api/backups/download/${encodeURIComponent(collectionName)}`
  );
}
