import React from "react";

import { Modal, Input, Button, Select, Form, Popconfirm } from "antd";

import { StopOutlined } from "@ant-design/icons";

import { useState, useEffect } from "react";

import { GetRacesForEvent, GetDisqualifications } from "../../APIManager";

import ProgressionSystems from "../Test/ProgressionSystems";
import TextUpdaterNode from "../Test/CustomNode";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "react-flow-renderer";

export default function RaceProgressionDiagramModal({
  modalVisible,
  onCloseModal,
  eventName,
  schools,
}) {
  const [races, SetRaces] = useState();

  const [disqualifications, SetDisqualifications] = useState([]);
  const [loadingData, SetLoadingData] = useState(true);

  function GetSchool(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  useEffect(() => {}, []);

  useEffect(() => {
    SetLoadingData(true);
    GetRacesForEvent(eventName).then((res) => {
      SetRaces(res.data);
      SetLoadingData(false);
    });

    GetDisqualifications().then((res) => {
      SetDisqualifications(res.data);
    });
  }, [eventName, modalVisible]);
  if (!schools || loadingData) {
    return <></>;
  }

  var progressionConfig = ProgressionSystems.filter((p) => {
    if (p.Lanes == races[0].Lanes && p.Entries == races[0].Entries) {
      return true;
    } else {
      return false;
    }
  })[0];

  progressionConfig.nodes.forEach((node, nodeIndex) => {
    var associatedRace = races.filter((r) => r.RaceType == node.id)[0];
    node.data.SchoolNames.forEach((position, positionIndex) => {
      //  var participant = associatedRace.Participants[positionIndex];
      //   var configSchool =
      //   progressionConfig.nodes[nodeIndex].data.SchoolNames[positionIndex];

      if (associatedRace.Participants[positionIndex].isPlaceHolder) {
        return; //Enabling branch will introfuce long forms
        progressionConfig.nodes[nodeIndex].data.SchoolNames[positionIndex] =
          associatedRace.Participants[positionIndex].school;
      } else {
        var appendedText = "";
        if (associatedRace.RaceResults.length > 0) {
          appendedText = ` (${ordinal_suffix_of(
            associatedRace.RaceResults.findIndex(
              (r) => r == associatedRace.Participants[positionIndex].school
            ) + 1
          )})`;

          const dq = disqualifications.filter(
            (d) =>
              d.RaceName == associatedRace.RaceName &&
              d.School._id == associatedRace.Participants[positionIndex].school
          )[0];
          if (dq) {
            appendedText = ` (${dq.ShortType})`;
          }
        }
        progressionConfig.nodes[nodeIndex].data.SchoolNames[positionIndex] = (
          <span
            style={{
              textDecoration: associatedRace.Participants[positionIndex]
                .isDisqualified
                ? "line-through"
                : "none",
            }}
          >
            {GetSchool(associatedRace.Participants[positionIndex].school)
              .schoolinitials + appendedText}
          </span>
        );
      }
    });
  });
  const nodeTypes = { textUpdater: TextUpdaterNode };

  return (
    <div>
      <Modal
        title={`Progression for ${eventName}`}
        forceRender
        visible={modalVisible}
        onOk={() => {
          //OnSubmit(selectedDay);
        }}
        onCancel={onCloseModal}
        okText="Confirm Results"
        cancelText="Cancel"
        width="1200px"
        okButtonProps={{
          hidden: true,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <div style={{ height: "500px", width: "100%" }}>
          <ReactFlow
            nodes={progressionConfig.nodes}
            edges={progressionConfig.edges}
            //onNodesChange={onNodesChange}
            // onEdgesChange={onEdgesChange}
            // onConnect={onConnect}
            nodeTypes={nodeTypes}
            fitView
            onLoad={(instance) => setTimeout(() => instance.fitView(), 0)}
            //  style={rfStyle}
          >
            <Controls />
          </ReactFlow>
        </div>
      </Modal>
    </div>
  );
}

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}
