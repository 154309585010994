import React, { useState, useEffect, forwardRef } from "react";
import styles from "./LeaderboardPage.module.css";

import kbcLogo from "../../Static/isr_logo.png";

import { GetPointsTable } from "../../APIManager";
import { useForm } from "antd/lib/form/Form";

import { Radio } from "antd";

import FlipMove from "react-flip-move";

export default function LeaderboardPage() {
  const [pointsData, SetPointsData] = useState([]);
  const [selectedGroup, SetSelectedGroup] = useState("U25MPoints");

  useEffect(() => {
    GetPointsTable().then((res) => {
      SetPointsData(res.data);
    });
  }, []);

  return (
    <div>
      <div className={styles.segmentedButtons}>
        <Radio.Group
          onChange={(e) => {
            SetSelectedGroup(e.target.value);
            console.log(e.target.value);
          }}
          defaultValue="U25MPoints"
          buttonStyle="solid"
        >
          <Radio.Button value="U25MPoints">Under 25 Boys</Radio.Button>
          <Radio.Button value="U25FPoints">Under 25 Girls</Radio.Button>
          <Radio.Button value="U21MPoints">Under 21 Boys</Radio.Button>
          <Radio.Button value="U21FPoints">Under 21 Girls</Radio.Button>
          <Radio.Button value="U18MPoints">Under 18 Boys</Radio.Button>
          <Radio.Button value="U18FPoints">Under 18 Girls</Radio.Button>
          <Radio.Button value="U15MPoints">Under 15 Boys</Radio.Button>
          <Radio.Button value="U15FPoints">Under 15 Girls</Radio.Button>
        </Radio.Group>
      </div>
      <div className={styles.leaderboard}>
        <div className={styles.headerRow}>
          <div className={styles.position}>Position</div>
          <div>School</div>
          <div className={styles.points}>Points</div>
        </div>
        <FlipMove>
          {pointsData
            .sort((a, b) => b[selectedGroup] - a[selectedGroup])
            .map((s, index) => (
              <LeaderboardRow
                school={s}
                index={index}
                pointsField={selectedGroup}
                key={s.Username}
              />
            ))}
        </FlipMove>
      </div>
    </div>
  );
}

const LeaderboardRow = forwardRef((props, ref) => {
  const { school, index, pointsField } = props;
  return (
    <div className={`${styles.row} ${styles.gradientBorder}`} ref={ref}>
      <div className={styles.position}>{ordinal_suffix_of(index + 1)}</div>
      <div className={styles.schoolInfo}>
        <img src={kbcLogo} alt="School 1 Logo" className={styles.schoolLogo} />
        <div className={styles.schoolName}>{school.School.schoolname}</div>
      </div>
      <div className={styles.Points}>{school[pointsField]}</div>
    </div>
  );
});

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}
