import React from "react";
import { Column } from "@ant-design/plots";

export default function ColumnChart({ data, h1, colorFromSchool, schools }) {
  const ddata = [
    {
      type: "分类一",
      value: 270,
    },
    {
      type: "分类二",
      value: 25,
    },
    {
      type: "分类三",
      value: 18,
    },
    {
      type: "分类四",
      value: 15,
    },
    {
      type: "分类五",
      value: 10,
    },
    {
      type: "其他",
      value: 5,
    },
  ];
  const config = {
    data: data ? data : ddata,
    xField: "type",
    yField: "value",
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    columnStyle: {
      borderW: "1px",
    },
    meta: {
      type: {
        alias: "类别",
      },
      sales: {
        alias: "销售额",
      },
    },
    color: ({ type }) => {
      if (type && colorFromSchool) {
        var index = schools.findIndex((s) => s.schoolinitials == type);
        return schools[index].rowingcolors[0];
      }
      console.log(type);

      return "#5B8FF9";
    },
  };
  return (
    <div>
      <h1 style={{ width: "100%", textAlign: "center", fontSize: "1.4em" }}>
        {h1}
      </h1>
      <Column {...config} />
    </div>
  );
}
