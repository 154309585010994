import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  message,
  Tree,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import BundledEditor from "../MCEditor/MCEditor";
import axios from "axios";

import {
  GetOldCollections,
  AddOldCollection,
  DeleteOldCollection,
} from "../../APIManager";

const OldCollections = () => {
  const [oldCollections, SetOldCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const codeEditorRef = useRef(null);
  const [editorValue, SetEditorValue] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      GetOldCollections().then((res) => {
        SetOldCollections(res.data);
      });
    } catch (error) {
      message.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (Name, Year) => {
    try {
      DeleteOldCollection({ Name, Year }).then((res) => {
        message.success("Deleted successfully");
        fetchData();
      });
    } catch (error) {
      message.error("Failed to delete");
    }
  };

  const handleView = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    SetEditorValue(JSON.stringify(selectedRecord?.Data, null, 2));
  };

  const handleAdd = async (values) => {
    const { name, year, file } = values;
    const jsonFile = file[0]?.originFileObj;

    if (!jsonFile) {
      message.error("Please upload a valid JSON file.");
      return;
    }

    try {
      const text = await jsonFile.text();
      const jsonData = JSON.parse(text);

      const exists = oldCollections.some(
        (item) => item.name === name && item.year === year
      );
      if (exists) {
        message.error("An entry with the same name and year already exists.");
        return;
      }

      AddOldCollection({ Name: name, Year: year, Data: jsonData }).then(() => {
        message.success("Added successfully");
        fetchData();
        setAddModalVisible(false);
      });
    } catch (error) {
      message.error("Invalid JSON file");
    }
  };

  // Group data by year
  const groupedData = oldCollections.reduce((acc, item) => {
    const yearGroup = acc.find((group) => group.year === item.Year);
    if (yearGroup) {
      yearGroup.children.push(item);
    } else {
      acc.push({
        year: item.Year,
        children: [item],
      });
    }
    return acc;
  }, []);

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (_, record) => record.year || record.Year, // For root and nested rows
    },
    { title: "Name", dataIndex: "Name", key: "Name" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record.children ? null : ( // Only show actions for individual entries
          <span>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => handleView(record)}
            >
              View
            </Button>
            <Popconfirm
              title="Are you sure to delete this entry?"
              onConfirm={() => handleDelete(record.Name, record.Year)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popconfirm>
          </span>
        ),
    },
  ];

  const treeData = convertJsonToTreeData(selectedRecord?.Data);

  console.log(treeData);

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setAddModalVisible(true)}
      >
        Add Old Collection
      </Button>
      <Table
        columns={columns}
        dataSource={groupedData}
        loading={loading}
        rowKey={(record) => record.year || record.id} // Unique keys for both group and child rows
        style={{ marginTop: 16 }}
      />

      {/* Modals for viewing and adding collections */}
      <Modal
        title="View JSON"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        key={editorValue}
      >
        <Tree treeData={treeData} />
      </Modal>

      <Modal
        title="Add Old Collection"
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleAdd}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please select a name" }]}
          >
            <Select placeholder="Select a name">
              {/* Replace with real options */}
              <Select.Option value="awardnominations">
                awardnominations
              </Select.Option>
              <Select.Option value="cms">cms</Select.Option>
              <Select.Option value="coxweights">coxweights</Select.Option>
              <Select.Option value="disqualifications">
                disqualifications
              </Select.Option>
              <Select.Option value="filedocuments">filedocuments</Select.Option>
              <Select.Option value="indoorrecords">indoorrecords</Select.Option>
              <Select.Option value="indoorresults">indoorresults</Select.Option>
              <Select.Option value="penaltycards">penaltycards</Select.Option>
              <Select.Option value="raceentries">raceentries</Select.Option>
              <Select.Option value="races">races</Select.Option>
              <Select.Option value="rowers">rowers</Select.Option>
              <Select.Option value="s3objects">s3objects</Select.Option>
              <Select.Option value="schedules">schedules</Select.Option>
              <Select.Option value="users">users</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="year"
            label="Year"
            rules={[
              { required: true, message: "Please input the year" },
              {
                pattern: /^[0-9]{4}$/,
                message: "Year must be a 4-digit number",
              },
            ]}
          >
            <Input placeholder="Enter year (e.g., 2023)" />
          </Form.Item>
          <Form.Item
            name="file"
            label="Upload JSON"
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)} // Ensure fileList format
            rules={[{ required: true, message: "Please upload a JSON file" }]}
          >
            <Upload accept=".json" beforeUpload={() => false}>
              <Button>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

// Function to convert JSON to Ant TreeData
const convertJsonToTreeData = (data, parentKey = "") => {
  // If it's an array, process each item
  if (Array.isArray(data)) {
    return data.map((item, index) => {
      const key = `${parentKey}${index}`;

      // If the item is an array or object, recurse into it, otherwise show as a leaf node
      return {
        title: (
          <div>
            {typeof item === "object" ? (
              <span>Object</span> // Indicate that this is an object
            ) : (
              item // Display the primitive value (string, number, etc.)
            )}
          </div>
        ),
        key,
        children: convertJsonToTreeData(item, `${key}-`), // Recurse into array items
      };
    });
  } else if (typeof data === "object" && data !== null) {
    // If it's an object, process each key-value pair
    return Object.entries(data).map(([key, value]) => {
      const keyPath = `${parentKey}${key}`;

      // Process the value depending on its type
      return {
        title: (
          <div>
            <strong>{key}:</strong>
            {typeof value === "object" ? (
              // Indicate that this is an object and recurse into it
              <span>Object</span>
            ) : (
              <div>{value}</div> // Display the value directly
            )}
          </div>
        ),
        key: keyPath,
        children:
          Array.isArray(value) || typeof value === "object"
            ? convertJsonToTreeData(value, `${keyPath}-`) // Only recurse if the value is an object or array
            : undefined, // Don't create unnecessary children if the value is a primitive
      };
    });
  } else {
    // If it's a primitive (string, number, etc.), return as a leaf node
    return [
      {
        title: data,
        key: parentKey,
      },
    ];
  }
};

export default OldCollections;
