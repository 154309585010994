import React, { useState, useEffect } from "react";
import {
  GetFormStatus,
  UpdateSchoolFlagStatus,
} from "../../APIManager";

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Table, Space } from "antd";

export default function NewFLagsPage() {
  const [formStatus, SetFormStatus] = useState([]);

  useEffect(() => {
    FetchDetails();
  }, []);

  function FetchDetails() {
    GetFormStatus().then((res) => {
      SetFormStatus(res.data);
    });
  }

  const columns = [
    {
      title: "Schoool Name",
      dataIndex: "schoolname",
      sorter: (a, b) => a.schoolname.localeCompare(b.schoolname),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.schoolname.indexOf(value) === 0,
    },
    {
      title: "Flag",
      dataIndex: "FlagComplete",
      key: "FlagComplete",
      render: (text, record) => {
        return CheckRenderer(text);
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {/*    <a
            onClick={() => {
              SetSelectedSchoolID(record._id);
              SetIsDeleteModalVisible(true);
            }}
          >
            Delete
          </a> */}

          <a
            onClick={() => {
              UpdateSchoolFlagStatus(
                record.schoolid,
                !record.FlagComplete
              ).then((res) => {
                FetchDetails();
              });
            }}
          >
            Change Status
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <br />
      <div>
        {" "}
        <Table
          columns={columns}
          dataSource={formStatus}
          pagination={{ defaultPageSize: 50 }}
        />
      </div>
    </div>
  );
}

function CheckRenderer(text) {
  if (text === true) {
    return <CheckCircleTwoTone twoToneColor="#52c41a" />;
  } else {
    return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
  }
}
