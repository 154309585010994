import React, { useState, useContext, useEffect } from "react";
import styles from "./TeamEventRegistrations.module.css";
import {
  getLoggedInUser,
  AddEventEntries,
  GetSchoolRaceEntries,
  GetSchoolInfo,
} from "../../../APIManager";
import AddNewAssigment from "./AddNewAssignment";
import UpdateAssignment from "./UpdateAssignment";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  AutoComplete,
  Space,
  Tag,
} from "antd";

import { map } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Table } from "antd";
const { confirm } = Modal;
const TeamEventRegistrations = ({ schoolID }) => {
  const [isAssignModalOpen, SetIsAssignModalOpen] = useState(false);
  const [isUpdateModalOpen, SetIsUpdateModalOpen] = useState(false);

  const [selectedRaceToAssign, SetSelectedRaceToAssign] = useState("");

  const [raceEntries, SetRaceEntries] = useState([]);

  const [schoolInfo, SetSchoolInfo] = useState({});

  const eventEntries = schoolInfo.evententries;

  useEffect(() => {
    FetchEntries();
    GetSchoolInfo(schoolID).then((res) => {
      SetSchoolInfo(res.data);
      console.log(res.data);
    });
  }, [schoolID]);

  function FetchEntries() {
    GetSchoolRaceEntries(schoolID).then((res) => {
      console.log(res.data);
      SetRaceEntries(res.data);
    });
  }
  console.log(schoolInfo);
  if (!schoolInfo.evententries || !schoolID) {
    return null;
  }
  const proccesedEventEntries = ProcessEntries(schoolInfo.evententries);
  return (
    <div className={styles.MainDiv}>
      <AddNewAssigment
        visible={isAssignModalOpen}
        onCancel={onAssignCancel}
        race={selectedRaceToAssign}
        onSubmit={() => {
          SetIsAssignModalOpen(false);
          FetchEntries();
          //Refresh page data
        }}
        raceEntries={raceEntries}
      />
      {
        <UpdateAssignment
          visible={isUpdateModalOpen}
          onCancel={onAssignCancel}
          race={selectedRaceToAssign}
          onSubmit={() => {
            SetIsUpdateModalOpen(false);
            FetchEntries();
            //Refresh page data
          }}
          raceEntries={raceEntries}
        />
      }
      <CategoryBlock
        Label="Boys Under 25 Water Races"
        Entries={proccesedEventEntries.U25MaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 25 Water Races"
        Entries={proccesedEventEntries.U25FemaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Boys Under 25 Indoor Races"
        Entries={proccesedEventEntries.U25MaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 25 Indoor Races"
        Entries={proccesedEventEntries.U25FemaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Boys Under 21 Water Races"
        Entries={proccesedEventEntries.U21MaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Boys Under 21 Indoor Races"
        Entries={proccesedEventEntries.U21MaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 21 Water Races"
        Entries={proccesedEventEntries.U21FemaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 21 Indoor Races"
        Entries={proccesedEventEntries.U21FemaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Boys Under 18 Water Races"
        Entries={proccesedEventEntries.U18MaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Boys Under 18 Indoor Races"
        Entries={proccesedEventEntries.U18MaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 18 Water Races"
        Entries={proccesedEventEntries.U18FemaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 18 Indoor Races"
        Entries={proccesedEventEntries.U18FemaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Boys Under 15 Water Races"
        Entries={proccesedEventEntries.U15MaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Boys Under 15 Indoor Races"
        Entries={proccesedEventEntries.U15MaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 15 Water Races"
        Entries={proccesedEventEntries.U15FemaleWR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
      <CategoryBlock
        Label="Girls Under 15 Indoor Races"
        Entries={proccesedEventEntries.U15FemaleIR}
        OnAssign={OnAssign}
        raceEntries={raceEntries}
      />
    </div>
  );

  function OnAssign(race, isEdit) {
    if (isEdit) {
      SetIsUpdateModalOpen(true);
    } else {
      SetIsAssignModalOpen(true);
    }
    SetSelectedRaceToAssign(race);
  }

  function onAssignCancel() {
    SetIsAssignModalOpen(false);
    SetIsUpdateModalOpen(false);
    SetSelectedRaceToAssign("");
  }
};

export default TeamEventRegistrations;

const CategoryBlock = ({
  Label,
  Gender,
  AgeGroup,
  Entries,
  OnAssign,
  raceEntries,
}) => {
  if (Entries.length < 1) {
    return null;
  }
  return (
    <div className={styles.CategoryBlock}>
      <label className={styles.SubHeading}>{Label}</label>
      {Entries.map(function (item, i) {
        var rowers;
        var coxers;
        var subrowers;
        var subcoxers;
        raceEntries?.forEach((entry) => {
          if (entry.RaceName === item) {
            rowers = map(entry.Rowers, "Name");
            coxers = map(entry.Coxers, "Name");
            subrowers = map(entry.SubRowers, "Name");
            subcoxers = map(entry.SubCoxers, "Name");
          }
        });
        return (
          <RaceBlock
            key={i}
            Label={item}
            onAssign={OnAssign}
            rowers={rowers}
            coxers={coxers}
            subrowers={subrowers}
            subcoxers={subcoxers}
          />
        );
      })}
    </div>
  );
};

const RaceBlock = ({
  Label,
  onAssign,
  rowers,
  coxers,
  subrowers,
  subcoxers,
  schoolInfo,
}) => {
  console.log(rowers);

  if (rowers?.length >= 1) {
    return (
      <div className={styles.RaceBlock}>
        <div className={styles.RaceBlockName}>{Label}</div>
        <div style={{ marginBottom: "1em", lineHeight: "2em" }}>
          <span hidden={!rowers}>
            Rowers:{" "}
            {rowers.map(function (name, i) {
              return (
                <Tag color="green" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
          <span hidden={coxers?.length < 1}>
            Cox:{" "}
            {coxers.map(function (name, i) {
              return (
                <Tag color="blue" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
          <span hidden={subrowers?.length < 1}>
            Sub Rowers:{" "}
            {subrowers.map(function (name, i) {
              return (
                <Tag color="magenta" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
          <span hidden={subcoxers?.length < 1}>
            Sub Cox:{" "}
            {subcoxers.map(function (name, i) {
              return (
                <Tag color="magenta" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.RaceBlock}>
        <div className={styles.RaceBlockName}>{Label}</div>
        <div>No Rowers have been assigned yet</div>
      </div>
    );
  }
};

function ProcessEntries(entries) {
  var entiresbycat = {
    U25MaleIR: [],
    U25FemaleIR: [],

    U25MaleWR: [],
    U25FemaleWR: [],

    U21MaleWR: [],
    U21MaleIR: [],

    U21FemaleWR: [],
    U21FemaleIR: [],

    U18MaleWR: [],
    U18MaleIR: [],

    U18FemaleWR: [],
    U18FemaleIR: [],

    U15MaleWR: [],
    U15MaleIR: [],

    U15FemaleWR: [],
    U15FemaleIR: [],
  };
  entries.forEach((e) => {
    var arrayname = "";
    if (e.includes("25")) {
      arrayname = arrayname + "U25";
    }
    if (e.includes("21")) {
      arrayname = arrayname + "U21";
    }
    if (e.includes("18")) {
      arrayname = arrayname + "U18";
    }
    if (e.includes("15")) {
      arrayname = arrayname + "U15";
    }
    if (e.includes("Boys")) {
      arrayname = arrayname + "Male";
    }
    if (e.includes("Girls")) {
      arrayname = arrayname + "Female";
    }
    if (e.includes("Scull") || e.includes("Pair") || e.includes("Coxed")) {
      arrayname = arrayname + "WR";
    }
    if (!e.includes("Scull") && !e.includes("Pair") && !e.includes("Coxed")) {
      arrayname = arrayname + "IR";
    }
    entiresbycat[arrayname].push(e);
  });
  return entiresbycat;
  console.log(entiresbycat);
}
