import React from "react";
import { Pie } from "@ant-design/plots";

export default function PieChart({ data, h1 }) {
  const ddata = [
    {
      type: "分类一",
      value: 270,
    },
    {
      type: "分类二",
      value: 25,
    },
    {
      type: "分类三",
      value: 18,
    },
    {
      type: "分类四",
      value: 15,
    },
    {
      type: "分类五",
      value: 10,
    },
    {
      type: "其他",
      value: 5,
    },
  ];
  const config = {
    appendPadding: 10,
    data: data ? data : ddata,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return (
    <div>
      <h1 style={{ width: "100%", textAlign: "center", fontSize: "1.4em" }}>
        {h1}
      </h1>
      <Pie {...config} />
    </div>
  );
}
