import React, { useState, useEffect } from "react";
import styles from "./SetupNewEvent.module.css";
import {
  GetSchools,
  GetRaces,
  GetEventEntries,
  AddNewRaces,
} from "../../APIManager";

import RaceConfigs from "../Test/RaceConfigs";

import WheelComponent from "react-wheel-of-prizes";
import TextTransition, { presets } from "react-text-transition";

import { GetRaceAgeGroup, GetRaceClass, GetRaceGender } from "../../utility";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  message,
  Select,
  Space,
} from "antd";

import { ListOfEvents } from "../../constants";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "react-flow-renderer";

import ProgressionSystems from "../Test/ProgressionSystems";
import TextUpdaterNode from "../Test/CustomNode";
import { configConsumerProps } from "antd/lib/config-provider";

export default function SetupNewOutdoorEventNew() {
  const [schools, SetSchools] = useState([]);
  const [races, SetRaces] = useState([]);
  const [eventEntries, SetEventEntries] = useState([]);

  useEffect(() => {
    GetSchools().then((Res) => {
      SetSchools(Res.data);
    });
    GetRaces().then((Res) => {
      SetRaces(Res.data);
    });

    GetEventEntries().then((res) => {
      SetEventEntries(res.data);
    });
  }, []);

  const [form] = Form.useForm();

  const requiredFields = ["EventName", "Lanes"];

  const UnSetupRaces = ListOfEvents.filter((e) => {
    return (
      !races.map((r) => r.EventName).includes(e) &&
      GetRaceClass(e) == "Water Race"
    );
  });

  const [formValues, SetFormValues] = useState({});

  const { EventName, Lanes } = formValues;

  const schoolEntries = GetSchoolEntries();
  function GetSchoolEntries() {
    var entries = eventEntries.filter((e) => e.RaceName == EventName);
    return entries;
  }

  const participatingSchools = schoolEntries?.map((e) => {
    const school = schools.filter((s) => s._id == e.Team)[0];
    return school;
  });

  console.log(participatingSchools);

  const noOfEntries = schoolEntries?.length;

  const raceConfig = RaceConfigs[`${Lanes}L${noOfEntries}E`];

  const [formStep, SetFormStep] = useState(1);

  return (
    <div>
      <h1 className={styles.Heading}>
        {formStep == 1 ? "Setup a new Out Door Event" : `Setup ${EventName}`}
      </h1>
      <div hidden={formStep != 1} className={styles.Form}>
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={() => {
            SetFormStep(2);
          }}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
          size="large"
          onValuesChange={() => {
            SetFormValues(form.getFieldsValue());
          }}
        >
          <Form.Item
            label="Event Name"
            name="EventName"
            rules={[
              {
                required: true,
                message: "Please input an Event Name",
              },
              {
                message: "This race has already been setup",
                validator: (_, value) => {
                  if (!races.map((r) => r.EventName).includes(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("This race has already been setup");
                  }
                },
              },
            ]}
          >
            <Select
              showSearch
              options={UnSetupRaces.map((e) => {
                return { value: e, label: e };
              })}
            />
          </Form.Item>
          {EventName && (
            <>
              {" "}
              <hr />
              <div>Number of entries: {noOfEntries} </div> <hr />
            </>
          )}
          <Form.Item
            label="Number of Lanes"
            name="Lanes"
            rules={[
              {
                required: true,
                message: "Please input number of lanes",
              },
            ]}
          >
            <Select
              showSearch
              options={[
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
              ]}
            />
          </Form.Item>
          <div>
            {participatingSchools?.map((e) => {
              return <div>{e.username}</div>;
            })}
          </div>
          <Form.Item shouldUpdate={true}>
            {() => {
              // console.log(form.isFieldsTouched(["Declration"]))
              return (
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !requiredFieldsTouched(form, requiredFields) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  Next
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </div>
      {formStep == 2 && (
        <FormPage2
          Lanes={Lanes}
          noOfEntries={noOfEntries}
          schoolInitials={participatingSchools.map((s) => s.schoolinitials)}
          schools={schools}
          eventName={EventName}
          participatingSchools={participatingSchools}
        />
      )}
    </div>
  );
}
const FormPage2 = ({
  Lanes,
  noOfEntries,
  schoolInitials,
  participatingSchools,
  schools,
  eventName,
}) => {
  const [positions, SetPositions] = useState(new Array(noOfEntries).fill(null));
  const [modalVisible, SetModalVisible] = useState(false);
  const [randomizerModalVisible, SetRandomizerModalVisible] = useState(false);
  const [spinwheelVisible, SetSpinwheelVisible] = useState(false);

  const [selectedRace, SetSelectedRace] = useState("");
  const [selectedPosition, SetSelectedPosition] = useState();

  const [selectedSchool, SetSelectedSchool] = useState("");

  const [schoolError, SetSchoolError] = useState("");

  const [submitEnabled, SetSubmitEnabled] = useState(false);

  console.log(positions);

  useEffect(() => {
    var state = true;

    schoolInitials.forEach((s) => {
      if (!positions.includes(s)) {
        state = false;
      }
    });
    SetSubmitEnabled(state);
  }, [positions]);

  useEffect(() => {
    if (isNaN(positions[selectedPosition])) {
      SetSelectedSchool(positions[selectedPosition]);
    } else {
      SetSelectedSchool(null);
    }
  }, [selectedRace, selectedPosition]);

  function onSchoolChange(newValue) {
    SetSelectedSchool(newValue);
    if (
      positions.indexOf(newValue) == -1 ||
      positions.indexOf(newValue) == selectedPosition
    ) {
      SetSchoolError("");
    } else {
      SetSchoolError(
        "This school has already been assigned a lane in the race"
      );
    }
  }

  function OnRandomize() {
    SetRandomizerModalVisible(true);
  }
  function OnInsertRandom() {
    //Find First unfilled slot as insertion index
    var insertionIndex = null;
    for (let index = 0; index < positions.length; index++) {
      if (!positions[index]) {
        insertionIndex = index;
        console.log(index);
        break;
      }
    }

    if (insertionIndex == null) {
      console.log("All slots are filled");
      return;
      // All slots are filled
    }

    var schoolinitials = participatingSchools
      .map((s) => s.schoolinitials)
      .filter((s) => !positions.includes(s));

    const randomInitial =
      schoolinitials[Math.floor(Math.random() * schoolinitials.length)];

    var newPositions = [...positions];
    newPositions[insertionIndex] = randomInitial;
    SetPositions(newPositions);
  }

  function OnResetAll() {
    SetPositions(new Array(noOfEntries).fill(null));
  }

  function onConfirmSetup() {
    console.log(positions, Lanes);
    var entriesByHeat = []; //chunkArray(positions, Lanes);
    var progressionSystem = ProgressionSystems.filter(
      (p) => p.ID == `${Lanes}LE${noOfEntries}`
    )[0];

    var i = 0;
    progressionSystem.nodes
      .filter((n) => n.id.includes("Heat"))
      .forEach((node, Nindex) => {
        entriesByHeat.push([]);
        node.data.SchoolNames.forEach((schoolname, Sindex) => {
          entriesByHeat[Nindex].push(positions[i]);
          i++;
        });
      });

    var raceconfig = RaceConfigs[`${Lanes}L${noOfEntries}E`];
    raceconfig.forEach((race, index) => {
      if (race.RaceName.includes("Heat")) {
        raceconfig[index].RacePositions = entriesByHeat[index].map(
          (i) => schools.filter((s) => s.schoolinitials == i)[0]._id
        );
      }
    });

    //TODO Make this io tree treversal code
    raceconfig.forEach((race, index) => {
      if (race.PlacementHeat) {
        raceconfig.forEach((r, i) => {
          r.RacePositions.forEach((raceposition, j) => {
            if (raceposition.RaceName == race.RaceName) {
              raceconfig[i].RacePositions[j] =
                race.RacePositions[raceposition.Position - 1];
            }
          });
        });
      }
    });
    var raceObjects = raceconfig.map((r) => {
      const EventName = eventName;
      const RaceName = `${EventName} ${r.RaceName}`;
      const RaceType = r.RaceName;
      const AgeGroup = GetRaceAgeGroup(eventName);
      const Gender = GetRaceGender(eventName);
      const Class = GetRaceClass(eventName);
      const Participants = r.RacePositions.map((p) => {
        if (typeof p === "string") {
          return { isPlaceHolder: false, school: p };
        } else {
          return {
            isPlaceHolder: true,
            school: `${p.RaceName} Position ${p.Position}`,
          };
        }
      });
      const RaceResults = r.PlacementHeat
        ? Participants.map((p) => p.school)
        : [];
      const RaceStatus = r.PlacementHeat ? "Placement Heat" : "Not Scheduled";
      const Date = null;

      return {
        EventName,
        RaceName,
        RaceType,
        AgeGroup,
        Gender,
        Class,
        Participants,
        RaceResults,
        RaceStatus,
        Date,
        Lanes,
        Entries: noOfEntries,
      };
    });
    console.log(raceObjects);
    SetSubmitEnabled(false);
    AddNewRaces(raceObjects).then((Res) => {
      window.location.reload();
    });
  }

  return (
    <>
      <div
        onClick={() => {
          console.log(positions);
        }}
      >
        asdsad
      </div>
      {spinwheelVisible && (
        <SpinWheel
          segments={schoolInitials.filter((s) => !positions.includes(s))}
          onFinished={(s) => {
            onSchoolChange(s);
            SetSpinwheelVisible(false);
          }}
          onCancel={() => {
            SetSpinwheelVisible(false);
          }}
        />
      )}
      <Modal
        title={`Randomize`}
        forceRender
        visible={randomizerModalVisible}
        onCancel={() => {
          SetRandomizerModalVisible(false);
        }}
        onOk={() => {}}
        okText="Confirm"
        cancelText="Cancel"
        width="1000px"
        okButtonProps={{ disabled: schoolError }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <RandomizerComponent
          schools={participatingSchools}
          key={`${randomizerModalVisible} randomize modal`}
          progressionSystemName={`${Lanes}LE${noOfEntries}`}
          onSubmit={(e) => {
            SetPositions(e);
            SetRandomizerModalVisible(false);
          }}
        />
      </Modal>
      <Modal
        title={`Assign position`}
        forceRender
        visible={modalVisible}
        onCancel={() => {
          SetModalVisible(false);
        }}
        onOk={() => {
          if (!schoolError) {
            var newPositions = positions;
            newPositions[selectedPosition] = selectedSchool;
            SetPositions([...newPositions]);
            SetModalVisible(false);
          }
        }}
        okText="Confirm"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{ disabled: schoolError }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <div>Selected Race: {selectedRace}</div>
        <div>Selected Position: {selectedPosition + 1}</div>
        <div style={{ width: "100%" }}>
          {" "}
          <Select
            value={selectedSchool}
            style={{ width: "100%" }}
            showSearch
            options={schoolInitials.map((e) => {
              return { value: e, label: e };
            })}
            onChange={(school) => {
              onSchoolChange(school);
            }}
          />
        </div>
        <label>{schoolError}</label>
        <br />
        <Button
          onClick={() => {
            SetSpinwheelVisible(true);
          }}
        >
          Spin Wheel
        </Button>
        <br />
        <br />
        <Button
          onClick={() => {
            SetSelectedSchool(null);
            var newPositions = positions;
            newPositions[selectedPosition] = null; //(selectedPosition + 1).toString();
            SetPositions([...newPositions]);
            SetModalVisible(false);
          }}
        >
          Reset Assignment
        </Button>
      </Modal>
      <ProgressionDiagram
        progressionSystemName={`${Lanes}LE${noOfEntries}`}
        onDoubleClick={(e) => {
          /*  var newPositions = [...positions];
          newPositions[e.positionClicked] = (Math.random() + 1)
            .toString(36)
            .substring(7);

          console.log(e);
          SetPositions([...newPositions]); */
          SetSelectedPosition(e.positionClicked);
          SetSelectedRace(e.RaceLabel);
          SetModalVisible(true);
        }}
        positions={positions}
        noOfLanes={Lanes}
        CID={JSON.stringify(positions)}
      />
      <br />
      <Space>
        <Button type="primary" onClick={OnRandomize}>
          Randomize
        </Button>
        <Button type="primary" onClick={OnInsertRandom}>
          Insert Random
        </Button>
        <Button type="primary" onClick={OnResetAll}>
          Reset All
        </Button>
        <Button
          type="primary"
          disabled={!submitEnabled}
          onClick={onConfirmSetup}
        >
          Confirm Setup
        </Button>
      </Space>
    </>
  );
};

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      // console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}

const ProgressionDiagram = ({
  progressionSystemName,
  onDoubleClick,
  positions,
  noOfLanes,
  CID,
}) => {
  const nodeTypes = { textUpdater: TextUpdaterNode };

  var progressionSystem = window.structuredClone(
    ProgressionSystems.filter((p) => p.ID == progressionSystemName)[0]
  );

  console.log(progressionSystem.nodes);

  const nodes = progressionSystem.nodes;
  const edges = progressionSystem.edges;

  progressionSystem.nodes.forEach((node, index) => {
    progressionSystem.nodes[index].data.Positions = [
      ...progressionSystem.nodes[index].data.SchoolNames,
    ];

    if (node.id.includes("Heat")) {
      progressionSystem.nodes[index].data = {
        ...node.data,
        OnEdit: onDoubleClick,
        niPosition: index * noOfLanes,
      };
    }
  });

  positions.forEach((updatedPos, index) => {
    if (updatedPos) {
      progressionSystem.nodes.forEach((node, Nodeindex) => {
        if (!node.id.includes("Heat")) {
          return;
        }

        node.data.Positions.forEach((pos, pIndex) => {
          if (parseInt(pos) - 1 == index) {
            progressionSystem.nodes[Nodeindex].data.SchoolNames[pIndex] =
              updatedPos;
          }
        });
      });
    }
  });

  return (
    <div>
      <h1>{progressionSystem.ID}</h1>
      <div style={{ height: "500px", width: "100%" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          //onNodesChange={onNodesChange}
          // onEdgesChange={onEdgesChange}
          // onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          onLoad={(instance) => setTimeout(() => instance.fitView(), 0)}
          //  style={rfStyle}
        >
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};

const SpinWheel = ({ segments, onFinished, onCancel }) => {
  const [winner, SetWinner] = useState("");

  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
  ];
  return (
    <Modal
      title={`Random Spin`}
      forceRender
      visible
      onCancel={onCancel}
      onOk={() => {
        onFinished(winner);
      }}
      okText="Confirm"
      cancelText="Cancel"
      width="1200px"
      height="400px"
      okButtonProps={{}}
      // cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <div className={styles.spinwheeldiv}>
        <WheelComponent
          segments={segments}
          segColors={segColors}
          onFinished={(w) => SetWinner(w)}
          primaryColor="black"
          contrastColor="white"
          buttonText="Spin"
          isOnlyOnce={false}
          size={250}
          upDuration={50}
          downDuration={1500}
          fontFamily="Arial"
        />
      </div>
    </Modal>
  );
};

function chunkArray(arr, chunkSize) {
  if (chunkSize <= 0) throw "Invalid chunk size";
  var R = [];
  for (var i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
}

const RandomizerComponent = ({ schools, progressionSystemName, onSubmit }) => {
  var progressionSystem = window.structuredClone(
    ProgressionSystems.filter((p) => p.ID == progressionSystemName)[0]
  );

  var positionNames = [];
  progressionSystem.nodes.forEach((node, index) => {
    if (node.id.includes("Heat")) {
      node.data.SchoolNames.forEach((name) => {
        positionNames.push(`${node.id} Position ${name}: `);
      });
    }
  });

  const schoolnames = schools.map((s) => s.schoolname);

  const [index, setIndex] = React.useState(0);

  const [rowers, setRowers] = useState(schoolnames);
  const [timer, setTimer] = useState(0);

  const [start, SetStart] = useState(false);
  const [done, SetDone] = useState(false);

  console.log(rowers);

  // Function to randomize the rowers array
  const randomizeRowers = () => {
    const randomizedRowers = [...rowers]; // Create a copy of the original array
    // Perform randomization logic here
    // For example, you can shuffle the array using Fisher-Yates algorithm
    for (let i = randomizedRowers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [randomizedRowers[i], randomizedRowers[j]] = [
        randomizedRowers[j],
        randomizedRowers[i],
      ];
    }
    setRowers(randomizedRowers); // Update the state with the randomized array
  };

  useEffect(() => {
    if (!start) {
      return;
    }
    // Start the timer to randomize rowers every 5 seconds
    const intervalId = setInterval(() => {
      randomizeRowers();
    }, 900);

    // Increment the timer every 5 seconds
    const timerIntervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 5);
    }, 900);

    // Stop the timer and clear intervals after 20 seconds
    if (timer >= 50) {
      clearInterval(intervalId);
      clearInterval(timerIntervalId);
      OnComplete(); // Call the Done() function after 20 seconds
    }

    // Clean up the intervals when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearInterval(timerIntervalId);
    };
  }, [rowers, timer, start]);

  function OnComplete() {
    SetDone(true);
  }

  return (
    <div>
      <div>
        {schoolnames.map((sn, index) => {
          return (
            <>
              <div className={styles.RandomizerContainer}>
                {" "}
                <span className={styles.RandomizerPositionName}>
                  {" "}
                  {positionNames[index]}
                </span>
                <span>
                  <TextTransition
                    text={rowers[index]}
                    springConfig={presets.wobbly}
                    style={{ display: "inline-flex" }}
                    className={styles.RandomizerSchoolName}
                  />
                </span>
              </div>
            </>
          );
        })}
      </div>
      <Button
        onClick={() => {
          SetStart(true);
        }}
      >
        Start Shuffle
      </Button>
      <Button
        type="primary"
        disabled={!done}
        onClick={() => {
          var newPositions = [];

          rowers.forEach((r) => {
            newPositions.push(
              schools.filter((s) => s.schoolname == r)[0].schoolinitials
            );
          });
          onSubmit(newPositions);
        }}
      >
        Insert
      </Button>

      {done ? "Done" : "Not DOne"}
    </div>
  );
};
