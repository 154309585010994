import { React, useContext } from "react";
import { Table, Button, Modal, Select } from "antd";
import { UserContext } from "../../UserContext";
import {
  GetSchools,
  GetRowers,
  GetSchoolContactPersonsReport,
} from "../../APIManager";

import PaymentComponent from "./Payment/PaymentComponent";

import ReadOnlyUploadedFile from "../Shared Components/ReadOnlyUploadedFile";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
export default function PaymentsPage() {
  const [schools, SetSchools] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [selectedSchool, SetSelectedSchool] = useState("");
  const { Option, OptGroup } = Select;
  useEffect(() => {
    GetSchools().then((res) => {
      SetSchools(res.data);
    });
    GetRowers().then((res) => {
      SetRowers(res.data);
    });
  }, []);
  return (
    <div>
      <RevenueSummary rowers={rowers} schools={schools} />
      <div>
        <h1 style={{ fontSize: "2em" }}>Payments</h1>
        <div>
          <Select
            showSearch
            placeholder="Select a school"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            style={{ width: "50ch" }}
            onChange={(e) => {
              SetSelectedSchool(e);
            }}
          >
            {schools.map(function (item, i) {
              return <Option value={item._id}>{item.schoolname}</Option>;
            })}
          </Select>
        </div>
        <br />
        <div>
          {!selectedSchool ? (
            <h2>Select a school to view payment details</h2>
          ) : (
            <div>
              <PaymentComponent schoolID={selectedSchool} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const RevenueSummary = ({ rowers, schools }) => {
  if (rowers.length === 0 || schools.length === 0) {
    return null;
  }
  console.log(rowers);

  var skipSchools = ["KPT School","Ghulaman e Abbas","Kiran Foundation","Asian International School","British School in Colombo","Diyannawa Rowing Academy"];

  schools.forEach((school) => {
    school.rowers = [];
  });

  rowers.forEach((rower) => {
    if (skipSchools.includes(String(rower.Team.schoolname))) {
      return;
    }
    var schoolindex = schools.findIndex((school) => {
      return String(school._id) == String(rower.Team._id);
    });
    schools[schoolindex].rowers.push(rower._id);
  });

  var totalAmmount = 0;
  schools.forEach((school) => {
    var schoolfees = 0;
    var rowerfees = 0;
    if (school.rowers.length < 10) {
      schoolfees = school.rowers.length * 1500;
    } else {
      schoolfees = 15000;
    }
    rowerfees = school.rowers.length * 1500;

    totalAmmount = totalAmmount + schoolfees + rowerfees;
  });

  console.log(totalAmmount);
  return <div style={{ fontSize: "1.5em" }}>Total Revenue: {totalAmmount}</div>;
};
