import { React, useContext, useRef } from "react";
import {
  Table,
  Button,
  Modal,
  Select,
  Tag,
  message,
  Divider,
  Form,
  Input,
  Space,
  Upload,
  Image,
  Typography,
} from "antd";

import BundledEditor from "../MCEditor/MCEditor";

import { UserContext } from "../../UserContext";
import {
  GetSchools,
  UpdateSchoolFlagStatus,
  UploadNewFileDocument,
  GetFileDocuments,
  DeleteFileDocument,
  UploadLogo,
} from "../../APIManager";
import { UploadOutlined } from "@ant-design/icons";

import { ErrorBoundary } from "react-error-boundary";

import ErrorFallback from "../ErrorFallback/ErrorFallback";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { UpdateCMSDoc, GetCMSDoc } from "../../APIManager";
import ReactQuill from "react-quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";

const { Option } = Select;

export default function AnnouncementsBox() {
  const [editorValue, SetEditorValue] = useState("");
  const [localInstructEditorValue, SetLocalInstructEditorValue] = useState("");

  const [intInstructEditorValue, SetIntInstructEditorValue] = useState("");
  const [eventInstructEditorValue, SetEventInstructEditorValue] = useState("");

  const [cmsdoc, SetCMSDoc] = useState("");
  const [instructionsDoc, SetInstructionsDoc] = useState("");
  const [eventInstructDoc, SetEventInstructDoc] = useState("");

  const [cpConfig, SetCPConfig] = useState({});

  const announEditorRef = useRef(null);
  const localInstructEditorRef = useRef(null);
  const intInstructEditorRef = useRef(null);
  const eventInstructEditorRef = useRef(null);

  useEffect(() => {
    FetchCMS();
  }, []);

  function FetchCMS() {
    GetCMSDoc("ClientHomePageAnnouncement").then((res) => {
      SetEditorValue(res.data.Data.Announcement);
      SetCMSDoc(res.data);
    });

    GetCMSDoc("ClientInstructionsPageInstructions").then((res) => {
      SetLocalInstructEditorValue(res.data.Data.LocalInstructions);
      SetIntInstructEditorValue(res.data.Data.InternationalInstructions);
      SetInstructionsDoc(res.data);
    });

    GetCMSDoc("EventInstructionsPageInstructions").then((res) => {
      SetEventInstructEditorValue(res.data.Data.Instructions);
      SetEventInstructDoc(res.data);
    });

    GetCMSDoc("ControlPanelFormSetup").then((res) => {
      SetCPConfig(res.data);
    });
  }

  function OnSubmit() {
    UpdateCMSDoc({
      docname: "ClientHomePageAnnouncement",
      data: {
        Announcement: announEditorRef.current.getContent(),
      },
    })
      .then((res) => {
        message.info("Sucessfully Saved");
        FetchCMS();
      })
      .catch(() => {
        message.error("Network or Server Error");
      });
  }

  function OnLocalIntructSubmit() {
    UpdateCMSDoc({
      docname: "ClientInstructionsPageInstructions",
      data: {
        LocalInstructions: localInstructEditorRef.current.getContent(),
        InternationalInstructions:
          instructionsDoc.Data.InternationalInstructions,
      },
    })
      .then((res) => {
        message.info("Sucessfully Saved");
        FetchCMS();
      })
      .catch(() => {
        message.error("Network or Server Error");
      });
  }

  function OnIntIntructSubmit() {
    UpdateCMSDoc({
      docname: "ClientInstructionsPageInstructions",
      data: {
        LocalInstructions: instructionsDoc.Data.LocalInstructions,
        InternationalInstructions: intInstructEditorRef.current.getContent(),
      },
    })
      .then((res) => {
        message.info("Sucessfully Saved");
        FetchCMS();
      })
      .catch(() => {
        message.error("Network or Server Error");
      });
  }

  function OnEventInstructSubmit() {
    UpdateCMSDoc({
      docname: "EventInstructionsPageInstructions",
      data: {
        Instructions: eventInstructEditorRef.current.getContent(),
      },
    })
      .then((res) => {
        message.info("Sucessfully Saved");
        FetchCMS();
      })
      .catch(() => {
        message.error("Network or Server Error");
      });
  }

  return (
    <div>
      <Divider>
        <strong>Logos</strong>
      </Divider>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
        <LogosPanel cpConfig={cpConfig} onSubmit={FetchCMS} />
      </ErrorBoundary>
      <br />
      <Divider>
        <strong>Documents</strong>
      </Divider>
      <FileDocumentsPanel />
      <br />
      <Divider>
        <strong>Announcements</strong>
      </Divider>
      <div>
        <BundledEditor
          onInit={(_evt, editor) => (announEditorRef.current = editor)}
          initialValue={editorValue}
          init={{
            height: 200,
            plugins: [
              "advlist",
              "anchor",
              "autolink",
              "help",
              "image",
              "link",
              "lists",
              "searchreplace",
              "table",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
        <div>
          Last edited by {cmsdoc.LastUpdatedBy?.username} on{" "}
          {formatDate(cmsdoc.updatedAt)}
          {() => {
            return "Hello";
          }}
        </div>
      </div>
      <Button
        onClick={() => {
          OnSubmit();
        }}
        type="primary"
      >
        Save
      </Button>
      <Button
        onClick={() => {
          SetEditorValue(cmsdoc.Data.Announcement);
        }}
        style={{ marginLeft: "1em" }}
      >
        Cancel Changes
      </Button>
      <br /> <br />
      <Divider>
        <strong>Local Instructions</strong>
      </Divider>
      <div>
        <BundledEditor
          onInit={(_evt, editor) => (localInstructEditorRef.current = editor)}
          initialValue={localInstructEditorValue}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "anchor",
              "autolink",
              "help",
              "image",
              "link",
              "lists",
              "searchreplace",
              "table",
              "wordcount",
            ],
            image_dimensions: false,
            image_class_list: [
              { title: "Responsive", value: "img-responsive" },
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
        <div>
          Last edited by {instructionsDoc.LastUpdatedBy?.username} on{" "}
          {formatDate(instructionsDoc.updatedAt)}
          {() => {
            return "Hello";
          }}
        </div>
      </div>
      <Button
        onClick={() => {
          OnLocalIntructSubmit();
        }}
        type="primary"
      >
        Save
      </Button>
      <br /> <br />
      <Divider>
        <strong>International Instructions</strong>
      </Divider>
      <div>
        <BundledEditor
          onInit={(_evt, editor) => (intInstructEditorRef.current = editor)}
          initialValue={intInstructEditorValue}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "anchor",
              "autolink",
              "help",
              "image",
              "link",
              "lists",
              "searchreplace",
              "table",
              "wordcount",
            ],
            image_dimensions: false,
            image_class_list: [
              { title: "Responsive", value: "img-responsive" },
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
        <div>
          Last edited by {instructionsDoc.LastUpdatedBy?.username} on{" "}
          {formatDate(instructionsDoc.updatedAt)}
          {() => {
            return "Hello";
          }}
        </div>
      </div>
      <Button
        onClick={() => {
          OnIntIntructSubmit();
        }}
        type="primary"
      >
        Save
      </Button>
      <br /> <br />
      <Divider>
        <strong>Event Instructions</strong>
      </Divider>
      <div>
        <BundledEditor
          onInit={(_evt, editor) => (eventInstructEditorRef.current = editor)}
          initialValue={eventInstructEditorValue}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "anchor",
              "autolink",
              "help",
              "image",
              "link",
              "lists",
              "searchreplace",
              "table",
              "wordcount",
            ],
            image_dimensions: false,
            image_class_list: [
              { title: "Responsive", value: "img-responsive" },
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
        <div>
          Last edited by {eventInstructDoc.LastUpdatedBy?.username} on{" "}
          {formatDate(eventInstructDoc.updatedAt)}
          {() => {
            return "Hello";
          }}
        </div>
      </div>
      <Button
        onClick={() => {
          OnEventInstructSubmit();
        }}
        type="primary"
      >
        Save
      </Button>
    </div>
  );
}

const LogosPanel = ({ cpConfig, onSubmit }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Initialize images from cpConfig on component mount
    if (cpConfig?.Data) {
      const initialImages = [
        { Name: "KBC Logo", Logo: cpConfig.Data.KBCLogo, LogoName: "KBCLogo" },
        { Name: "ISR Logo", Logo: cpConfig.Data.ISRLogo, LogoName: "ISRLogo" },
      ];
      setImages(initialImages);
    }
  }, [cpConfig]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Content = reader.result.split(",")[1];
        resolve(base64Content);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const handleChangeImage = async (index, file, logoName) => {
    try {
      const fileName = file.name;
      const base64Image = await getBase64(file);

      UploadLogo({
        LogoName: logoName,
        FileName: fileName,
        FileBase64: base64Image,
      })
        .then((res) => {
          message.success("Uploaded logo successfully!");
          onSubmit(); // Call FetchCMS to update the parent state
          // Update the image URL with a timestamp to force a refresh
          setImages((prevImages) =>
            prevImages.map((img, idx) =>
              idx === index
                ? {
                    ...img,
                    Logo: {
                      ...img.Logo,
                      url: `${img.Logo.url}?timestamp=${new Date().getTime()}`,
                    },
                  }
                : img
            )
          );
        })
        .catch(() => {
          message.error("Could not upload logo");
        });
    } catch (error) {
      console.error("Error converting file to Base64:", error);
    }
  };

  if (!images.length) {
    return <div>Loading</div>;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        {images.map((i, index) => (
          <Space key={index} direction="vertical">
            <Typography style={{ fontWeight: "bold" }}>{i.Name}</Typography>
            <Image
              width={150}
              height={150}
              src={i.Logo.url}
              alt={`Image ${index + 1}`}
              style={{ border: "1px solid #ddd", borderRadius: "8px" }}
            />
            <Upload
              showUploadList={false}
              beforeUpload={(file) => {
                handleChangeImage(index, file, i.LogoName);
                return false;
              }}
              accept="image/*"
            >
              <Button icon={<UploadOutlined />} style={{ marginTop: "10px" }}>
                Change Image
              </Button>
            </Upload>
          </Space>
        ))}
      </div>
    </div>
  );
};

const FileDocumentsPanel = () => {
  const [fileModalOpen, SetFileModalOpen] = useState(false);
  const [files, SetFiles] = useState([]);

  function FetchFiles() {
    GetFileDocuments().then((res) => {
      SetFiles(res.data);
    });
  }

  useEffect(() => {
    FetchFiles();
  }, []);

  const [waitingForResponse, SetWaitingForResponse] = useState(false);

  const columns = [
    /*     {
      title: "Day",
      dataIndex: "Day",
    }, */
    {
      title: "File Name",
      dataIndex: "S3Object",
      sorter: (a, b) => a.fileName?.localeCompare(b.fileName),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) =>
        record.S3Object.fileName?.indexOf(value) === 0,
      render: (text, record) => record.S3Object.fileName,
    },
    {
      title: "Section",
      dataIndex: "Section",
    },
    {
      title: "Size",
      dataIndex: "S3Object",
      sorter: (a, b) => a.S3Object.size - b.S3Object.size,
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.S3Object.size?.indexOf(value) === 0,
      render: (text, record) => formatBytes(record.S3Object.size),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              window.open(record.S3Object.url, "_blank");
            }}
          >
            Open
          </a>
          <a
            disabled={waitingForResponse}
            onClick={() => {
              if (waitingForResponse) {
                return;
              }
              SetWaitingForResponse(true);
              DeleteFileDocument(record._id)
                .then((res) => {
                  FetchFiles();
                  message.success(`File was deleted succesfully`);
                  SetWaitingForResponse(false);
                })
                .catch(() => {
                  message.error(
                    `Ann error occured while trying to delete the file`
                  );
                  SetWaitingForResponse(false);
                });
            }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Button
        onClick={() => {
          SetFileModalOpen(true);
        }}
      >
        Upload new Document
      </Button>
      <FileDocumentModal
        visible={fileModalOpen}
        onSubmit={(e) => {
          SetFileModalOpen(false);
          FetchFiles();
        }}
        onClose={() => {
          SetFileModalOpen(false);
        }}
        files={files}
      />

      <Table
        columns={columns.map((col) => ({
          ...col,
          title: <span style={{ fontWeight: "bold" }}>{col.title}</span>,
        }))}
        dataSource={files}
        pagination={{
          defaultPageSize: 50,
          disabled: true,
          hideOnSinglePage: true,
        }}
        style={{ fontWeight: "bold" }}
      />
    </div>
  );
};

// Function to sanitize filenames
const sanitizeFileName = (name) => {
  let sanitized = name.replace(/[^a-zA-Z0-9 _-]/g, ""); // Remove invalid characters including periods
  if (sanitized.length > 255) {
    sanitized = sanitized.substring(0, 255);
  }
  if (sanitized.trim().length === 0) {
    sanitized = "";
  }
  return sanitized;
};

const FileDocumentModal = ({ visible, onClose, onSubmit, files }) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [fileBase64, setFileBase64] = useState("");
  const [fileName, setFileName] = useState("");
  const [nameError, setNameError] = useState(null);

  const [waitingForResponse, SetWaitingForResponse] = useState(false);

  const handleTagAdd = (value) => {
    if (value && !tags.includes(value)) {
      setTags([...tags, value]);
    }
  };

  const handleTagRemove = (removedTag) => {
    setTags(tags.filter((tag) => tag !== removedTag));
  };

  const handleFileUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Extract Base64 portion
        setFileBase64(base64String);
        setFileName(file.name); // Save original file name
        message.success(`${file.name} converted to Base64.`);
        resolve();
      };
      reader.onerror = () => {
        message.error(`Failed to convert ${file.name} to Base64.`);
        reject();
      };
      reader.readAsDataURL(file); // Convert file to Base64
    });
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    const sanitized = sanitizeFileName(value);

    const existingFileNames = files.map((f) => f.Name);

    if (sanitized.trim().length === 0) {
      setNameError("Name cannot be empty or only special characters.");
    } else if (existingFileNames.includes(sanitized.trim())) {
      setNameError("This filename already exists");
    } else {
      setNameError(null);
    }

    // Update the sanitized value in the form
    form.setFieldsValue({ Name: sanitized });
  };

  const onFinish = async (values) => {
    const payload = {
      ...values,
      Tags: tags,
      FileBase64: fileBase64, // Include Base64 file in the payload
      FileName: fileName, // Include original file name in the payload
    };

    if (!fileBase64 || !fileName) {
      message.error("Please upload a file before submitting.");
      return;
    }

    if (nameError) {
      message.error("Validation error in name field");
      return;
    }

    SetWaitingForResponse(true);
    UploadNewFileDocument(payload)
      .then((res) => {
        SetWaitingForResponse(false);
        message.success("Uploaded document sucessfuly!");
        onSubmit();
      })
      .catch(() => {
        SetWaitingForResponse(false);
        message.error("Could not upload document");
      });
  };

  return (
    <Modal
      title="Add New File Document"
      visible={visible}
      onCancel={onClose}
      onOk={() => form.submit()}
      okText={waitingForResponse ? "Uploading" : "Submit"}
      cancelText="Cancel"
      okButtonProps={{ disabled: waitingForResponse }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          Section: "Announcements",
        }}
      >
        {/* Name Field */}
        <Form.Item
          label="Name"
          name="Name"
          rules={[
            { required: true, message: "Please enter the document name!" },
          ]}
          validateStatus={nameError ? "error" : "success"}
          help={nameError}
        >
          <Input
            placeholder="Enter document name"
            onChange={handleNameChange}
          />
        </Form.Item>

        {/* Tags Field */}
        <Form.Item label="Tags">
          <Space>
            <Input
              placeholder="Add a tag"
              onPressEnter={(e) => {
                handleTagAdd(e.target.value);
                e.target.value = "";
              }}
            />
            <Button type="primary" onClick={() => handleTagAdd()}>
              Add Tag
            </Button>
          </Space>
          <div style={{ marginTop: "10px" }}>
            {tags.map((tag) => (
              <Tag
                key={tag}
                closable
                onClose={() => handleTagRemove(tag)}
                style={{ marginBottom: "5px" }}
              >
                {tag}
              </Tag>
            ))}
          </div>
        </Form.Item>

        {/* Section Field */}
        <Form.Item
          label="Section"
          name="Section"
          rules={[{ required: true, message: "Please select a section!" }]}
        >
          <Select>
            <Option value="Announcements">Announcements</Option>
            <Option value="Documents to print">Documents to print</Option>
          </Select>
        </Form.Item>

        {/* File Upload */}
        <Form.Item
          label="Upload File"
          rules={[{ required: true, message: "Please upload a file!" }]}
        >
          <Upload
            accept=".png,.jpg,.jpeg,.pdf,.docx"
            showUploadList={false}
            beforeUpload={(file) => {
              handleFileUpload(file);
              return false; // Prevent automatic upload
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          {fileName && (
            <div style={{ marginTop: "10px", color: "#555" }}>
              <strong>Selected File:</strong> {fileName}
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(dateString) {
  var date = new Date(dateString);

  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-") +
    " " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":")
  );
}

/**
 * Converts bytes to the highest appropriate unit.
 * @param {number} bytes - The size in bytes to convert.
 * @param {number} decimals - The number of decimal places to include (default is 2).
 * @returns {string} - The formatted size string.
 */
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
  );
}
