import React from "react";
import { useState, useEffect } from "react";

import {
  Modal,
  Input,
  Button,
  Form,
  Checkbox,
  message,
  Select,
  Alert,
  Table,
  notification,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import {
  GetEntriesForEvent,
  GetSchools,
  UpdateSubstitutions,
  GetRowers,
  VerifyCoxWeightStatus,
  LockCoxWeight,
  GetCoxWeightStatus,
} from "../../APIManager";

import { ListOfEvents } from "../../constants";

import { ListOfDays } from "../../constants";

import styles from "./CoxWeight.module.css";
import { useForm } from "antd/lib/form/Form";

const { confirm } = Modal;
const { Option } = Select;

export default function CoxWeight({}) {
  const [entries, SetEntries] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [schools, SetSchools] = useState([]);
  const [selectedCox, SetSelectedCox] = useState("");
  const [selectedDay, SetSelectedDate] = useState("27th Jan");
  const [selectedSchool, SetSelectedSchool] = useState("");
  const [awaitingResponse, SetAwaitingResponse] = useState(true);

  const [errorMessage, SetErrorMessage] = useState("");
  const [warningMessage, SetWarningMessage] = useState("");
  const [showWeightLock, SetShowWeightLock] = useState(false);

  const [coxWeightStatus, SetCoxWeightStatus] = useState([]);

  const [weight, SetWeight] = useState(0);
  const [signedBy, SetSignedBy] = useState("");

  useEffect(() => {
    SetWarningMessage("");
    SetErrorMessage("");
    SetWeight(0);
  }, [selectedCox]);

  useEffect(() => {
    GetSchools().then((Res) => {
      SetSchools(Res.data);
    });
    GetRowers().then((res) => {
      SetRowers(res.data);
    });
  }, []);

  useEffect(() => {
    GetCoxWeightStatus(selectedDay).then((res) => {
      SetCoxWeightStatus(res.data);
      console.log(res.data);
    });
  }, [selectedDay]);

  function GetSchool(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  function RefreshEntries() {
    GetEntriesForEvent(selectedCox).then((Res) => {
      SetEntries(Res.data);
      SetAwaitingResponse(false);
    });
  }

  const schoolCoxes = schools.map((s) => s.coxes).flat();
  const coxes = rowers
    .filter((r) => schoolCoxes.includes(r._id))
    .sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));

  const columns = [
    {
      title: "Cox",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Weighed",
      dataIndex: "IsWeighed",
      key: "IsWeighed",
      render: (text, record) => {
        if (text === false) {
          return CheckRenderer(text);
        }
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.IsWeighed === value,
    },
    {
      title: "Races Scheduled Today",
      dataIndex: "RacesScheduledToday",
      key: "RacesScheduledToday",
      render: (text, record) => {
        if (text === false) {
          return CheckRenderer(text);
        }
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.IsWeighed === value,
    },
    {
      title: "Hours to First Race",
      dataIndex: "HoursToRace",
      key: "HoursToRace",
      render: (text, record) => {
        if (record.RacesScheduledToday) {
          return Math.round(text * 10) / 10;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Weight Details",
      dataIndex: "WeightDetails",
      key: "WeightDetails",
      render: (text, record) => {
        const weight = record?.WeightDetails?.Weight;
        const sandbag = record?.WeightDetails?.Sandbags;
        const signedBy = record?.WeightDetails?.SignedBy;

        if (weight) {
          const dateLocked = new Date(record?.WeightDetails?.DateLocked);

          return (
            <div>
              <div>
                <strong>Weight: </strong>
                {weight} KG{" "}
              </div>
              <div>
                <strong>Sandbags: </strong>
                {sandbag} KG{" "}
              </div>
              <div>
                <strong>Locked By: </strong>
                {signedBy}
              </div>
              <div>
                <strong>Time: </strong>
                {dateLocked.toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </div>
            </div>
          );
        } else return "-";
      },
    },
  ];

  const rowProps = {
    onClick: (record) => {
      SetSelectedCox(record._id);
      console.log(record);
    },
  };

  return (
    <div>
      <div>
        <h1>Select Day</h1>
        <Select
          value={selectedDay}
          placeholder="Select a Day"
          style={{ width: "50ch" }}
          onChange={(e) => {
            SetSelectedDate(e);
          }}
        >
          {ListOfDays.map(function (item, i) {
            return <Option value={item}>{item}</Option>;
          })}
        </Select>
      </div>
      <br />
      <div>
        <Table
          dataSource={coxWeightStatus}
          columns={columns}
          pagination={false}
          style={{ fontSize: "2em" }}
          className={styles.Table}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (record.IsWeighed) {
                  notification.error({
                    message: `${record.Name} has already been weighed today`,
                    description: "",
                    placement: "topRight",
                    duration: 5,
                    maxCount: 1,
                  });
                  return;
                }
                if (!record.RacesScheduledToday) {
                  notification.error({
                    message: `${record.Name} has no races scheduled for today today`,
                    description: "",
                    placement: "topRight",
                    duration: 5,
                    maxCount: 1,
                  });
                  return;
                }
                SetSelectedCox(record._id);
                if (record.HoursToRace < 1) {
                  notification.warning({
                    message: `${record.Name} has their first even within 1 hour`,
                    description: "",
                    placement: "topRight",
                    duration: 5,
                    maxCount: 1,
                  });
                  SetShowWeightLock(true);
                  SetWarningMessage(
                    `${record.Name} has their first even within 1 hour`
                  );
                } else if (record.HoursToRace > 2.1) {
                  notification.error({
                    message: `${record.Name}'s First Race is after ${
                      Math.round(record.HoursToRace * 10) / 10
                    } hours `,
                    description:
                      "Rowers may only be weighed within 2 hours of their first race",
                    placement: "topRight",
                    duration: 5,
                    maxCount: 1,
                  });
                  SetShowWeightLock(false);
                } else {
                  SetWarningMessage("");
                  SetShowWeightLock(true);
                }

                /*           VerifyCoxWeightStatus({
                  coxID: record._id,
                  day: selectedDay,
                }).then((res) => {
                  if (res.data.Error) {
                    notification.error({
                      message: res.data.Message,
                      description: res.data.Message,
                      placement: "topRight",
                      duration: 5,
                      maxCount: 1,
                    });
                    SetErrorMessage(res.data.Message);
                    SetWarningMessage("");
                    SetShowWeightLock(false);
                  } else {
                    SetErrorMessage("");

                    var hours = res.data.Hours;
                    console.log(hours);
                    if (hours < 1) {
                      notification.warning({
                        message: `${record.Name} has their first even within 1 hour`,
                        description: "",
                        placement: "topRight",
                        duration: 5,
                        maxCount: 1,
                      });
                      SetShowWeightLock(true);
                      SetWarningMessage(
                        `${record.Name} has their first even within 1 hour`
                      );
                    } else if (hours > 16.5) {
                      notification.error({
                        message: `${record.Name}'s First Race is after ${
                          Math.round(hours * 10) / 10
                        } hours `,
                        description:
                          "Rowers may only be weighed within 2 hours of their first race",
                        placement: "topRight",
                        duration: 5,
                        maxCount: 1,
                      });
                      SetShowWeightLock(false);
                    } else {
                      SetWarningMessage("");
                      SetShowWeightLock(true);
                    }
                  }
                }); */
              }, // click row
            };
          }}
        />
      </div>
      <br />
      <div hidden>
        <h1>Select a Cox</h1>
        <Select
          showSearch
          value={selectedCox}
          placeholder="Select a Cox"
          filterOption={(input, option) => {
            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          allowClear
          style={{ width: "50ch" }}
          onChange={(e) => {
            SetSelectedCox(e);
          }}
        >
          {coxes.map(function (item, i) {
            return (
              <Option value={item._id}>
                {`${item.Name} (${GetSchool(item.Team._id)?.schoolinitials})`}
              </Option>
            );
          })}
        </Select>
      </div>
      <br />
      <div hidden={errorMessage.length < 1}>
        <Alert
          message="Error"
          //description={`You have not assigned ${no} students to any race However, They will stil be incuded in the total registration fees. It is best if you either assign them to a race or delete them from the Team Registration Page`}
          description={<div>{errorMessage}</div>}
          type="error"
          showIcon
        />
      </div>
      <div hidden={warningMessage.length < 1}>
        <Alert
          message="Warning"
          //description={`You have not assigned ${no} students to any race However, They will stil be incuded in the total registration fees. It is best if you either assign them to a race or delete them from the Team Registration Page`}
          description={<div>{warningMessage}</div>}
          type="warning"
          showIcon
        />
      </div>
      <br />
      <Button
        hidden
        type="primary"
        //disabled={repeatSubs}
        onClick={() => {
          VerifyCoxWeightStatus({
            coxID: selectedCox,
            day: selectedDay,
          }).then((res) => {
            if (res.data.Error) {
              SetErrorMessage(res.data.Message);
              SetWarningMessage("");
              SetShowWeightLock(false);
            } else {
              SetErrorMessage("");
              SetShowWeightLock(true);
              var hours = res.data.Hours;
              if (hours < 1) {
                SetWarningMessage(
                  "Rower has their first cox event in less than 1 hour"
                );
              } else if (hours > 3) {
                SetWarningMessage(
                  `Rower has their first cox event after more than 3 hours. Specifically after ${
                    Math.round(hours * 10) / 10
                  } hours`
                );
              } else {
                SetWarningMessage("");
              }
            }
          });
        }}
      >
        Check Details
      </Button>
      <br />
      <br />
      <Modal
        title={`Lock COX Weight for ${
          coxes.filter((c) => c._id == selectedCox)[0]?.Name
        }`}
        forceRender
        visible={showWeightLock}
        onOk={() => {}}
        onCancel={() => {
          SetShowWeightLock(false);
        }}
        okText="Delete"
        cancelText="cancel"
        width="1200px"
        okButtonProps={{
          hidden: true,
        }}
        cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        {" "}
        {showWeightLock && (
          <>
            <div hidden={errorMessage.length < 1}>
              <Alert
                message="Error"
                //description={`You have not assigned ${no} students to any race However, They will stil be incuded in the total registration fees. It is best if you either assign them to a race or delete them from the Team Registration Page`}
                description={<div>{errorMessage}</div>}
                type="error"
                showIcon
              />
            </div>
            <div hidden={warningMessage.length < 1}>
              <Alert
                message="Warning"
                //description={`You have not assigned ${no} students to any race However, They will stil be incuded in the total registration fees. It is best if you either assign them to a race or delete them from the Team Registration Page`}
                description={<div>{warningMessage}</div>}
                type="warning"
                showIcon
              />
            </div>
            <br />
            <br />
            <Input
              addonBefore="Weight in KG"
              type="number"
              value={weight}
              onChange={(e) => {
                if (e.target.value) {
                  SetWeight(Math.round(e.target.value * 2) / 2);
                } else {
                  SetWeight(0);
                }
              }}
            />
            <br />
            <br />
            <label
              style={{ fontSize: "1.4em", fontWeight: "bold" }}
              hidden={weight < 35}
            >
              Sandbags:{" "}
              {weight > 50
                ? "No Sandbags Required"
                : `${50 - weight}KG Required`}
            </label>
            <label
              style={{ fontSize: "1.4em", fontWeight: "bold", color: "Red" }}
              hidden={weight >= 35}
            >
              Underweight
            </label>
            <br />
            <br />
            <Input
              addonBefore="Sign your Name"
              type="text"
              value={signedBy}
              onChange={(e) => {
                SetSignedBy(e.target.value);
              }}
            />
            <br />
            <br />

            <Button
              type="primary"
              disabled={errorMessage.length > 1 || weight < 25 || !signedBy}
              onClick={() => {
                LockCoxWeight({
                  coxID: selectedCox,
                  day: selectedDay,
                  weight: weight,
                  sandbag: weight > 50 ? 0 : 50 - weight,
                  signedBy: signedBy,
                }).then(() => {
                  window.location.reload();
                });
              }}
            >
              Lock Weight
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
}

function CheckRepeatSubs(subs) {
  var oSubs = [...subs].map((s) => s.sID);

  return oSubs.length !== new Set(oSubs).size;
}

function CheckRenderer(text) {
  if (text === true) {
    return <CheckCircleTwoTone twoToneColor="#52c41a" />;
  } else {
    return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
  }
}

const filters = [
  {
    text: "Completed",
    value: true,
  },
  {
    text: "Incomplete",
    value: false,
  },
];
