import React, { useState, useContext, useEffect } from "react";
import styles from "./StudentRegistrations.module.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  DatePicker,
  Select,
  Space,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AddNewRower } from "../../APIManager";

const { Option } = Select;
const { confirm } = Modal;
const AddNewStudent = ({ visible, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const [showVacUpload, SetShowVacUpload] = useState(false);
  const [studentNationality, SetStudentNationality] = useState("Pakistani");
  const [isKBCMember, SetIsKBCMember] = useState(false);
  const [isCox, SetIsCox] = useState(false);
  const [submitEnabled, SetSubmitEnabled] = useState(false);

  const [errorMessage, SetErrorMessage] = useState();
  const [showErrorMessage, SetShowErrorMessage] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const constFields = [
    "StudentFullName",
    "StudentYOB",
    "StudentGender",
    "StudentPhoneNumber",
    "StudentPicture",
    "StudentNationality",
    "StudentCNIC",
    "StudentDeclaration",
    "KBCMember",
    "PreviousRegatta",
    "COX",
  ];
  const [requiredFields, SetRequiredFields] = useState([
    "StudentFullName",
    "StudentYOB",
    "StudentGender",
    "StudentPhoneNumber",
    "StudentPicture",
    "StudentCNIC",
    "StudentNationality",
    "StudentDeclaration",
    "KBCMember",
    "PreviousRegatta",
    "COX",
  ]);

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    form.setFieldsValue({
      StudentNationality: "Pakistani",
    });
  }, [visible]);

  const onFinish = (values) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm you want to add this student",
      okText: "Submit New Student",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        SetWaitingOnResponse(true);
        AddNewRower(form.getFieldsValue())
          .then((res) => {
            onSubmit();
            SetWaitingOnResponse(false);
          })
          .catch(() => {
            SetShowErrorMessage(true);
            message.warning(
              "A Server/Network Error Occured, please check your connection"
            );
            SetWaitingOnResponse(false);
          });
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    var fields = constFields;
    if (showVacUpload) {
      fields.push("StudentVaccination");
    } else {
      form.resetFields(["StudentVaccination"]);
    }
    if (isCox) {
      fields.push("CoxSeminar", "CoxTest", "CoxPreviousRegatta");
    } else {
      form.resetFields(["CoxSeminar"]);
      form.resetFields(["CoxTest"]);
      form.resetFields(["CoxPreviousRegatta"]);
    }
    if (isKBCMember) {
      fields.push("StudentKBCNumber");
    } else {
      form.resetFields(["StudentKBCNumber"]);
    }
    SetRequiredFields(fields);
  }, [showVacUpload, isCox, isKBCMember]);

  const handleFormValuesChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "KBCMember") {
      const value = changedValues[fieldName];
      SetIsKBCMember(value);
    }
    if (fieldName === "COX") {
      const value = changedValues[fieldName];
      SetIsCox(value);
    }
  };

  function onModalCancel() {
    confirm({
      title: "Unsaved Progress",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to cancel. Data entered will be lost",
      okText: "Cancel New Student",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        onCancel();
      },
      onCancel() {},
    });
  }

  return (
    <Modal
      title="Add New Student"
      forceRender
      visible={visible}
      onOk={onSubmit}
      onCancel={onModalCancel}
      okText="Submit"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <Form
        form={form}
        layout={"vertical"}
        size="large"
        onValuesChange={handleFormValuesChange}
        onChange={(form) => {}}
        autoComplete={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="Student Full Name"
          name="StudentFullName"
          rules={[
            { required: true, message: "Please input Student Full Name" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Student Year of Birth"
          name="StudentYOB"
          shouldUpdate
          rules={[
            { required: true, message: "Please input Student Year of Birth" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !(value < 2003 || value > 2010)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(`Please enter a valid DOB between 2003 and 2010`)
                );
              },
            }),
          ]}
        >
          <CategorySelect SetShowVacUpload={SetShowVacUpload} />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="StudentGender"
          rules={[{ required: true, message: "Please input Gender" }]}
        >
          <Select>
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Student Phone Number"
          name="StudentPhoneNumber"
          rules={[
            {
              required: true,
              message: "Please input your Student Phone Number",
            },
            {
              pattern: new RegExp(/^03[0-9]{2}[0-9]{7}$/),
              message: "Please enter a valid number in the format: 03001234567",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Upload Student Picture"
          name="StudentPicture"
          rules={[
            {
              required: true,
              message: "Please upload Student Picture",
            },
          ]}
        >
          <PhotoFileUpload />
        </Form.Item>
        <Form.Item
          label="Nationality"
          name="StudentNationality"
          rules={[{ required: true, message: "Please input Nationality" }]}
        >
          <NationalitySelect SetStudentNationality={SetStudentNationality} />
        </Form.Item>
        <CNICInput nationality={studentNationality} />
        <Form.Item
          label="Upload Scanned CNIC/Smart Card/Passport"
          name="StudentCNIC"
          rules={[
            {
              required: true,
              message: "Please upload Scanned CNIC/Smart Card/Passport",
            },
          ]}
        >
          <FileUpload />
        </Form.Item>
        {showVacUpload ? (
          <Form.Item
            label="Upload Vaccination Certificate if above 17 Years of Age"
            name="StudentVaccination"
            dependencies={["StudentYOB"]}
            shouldUpdate
            rules={[
              {
                required: true,
                message: "Please upload Vaccination Proof",
              },
            ]}
          >
            <FileUpload />
          </Form.Item>
        ) : (
          <></>
        )}
        <Form.Item
          label="Upload Scanned Student Declaration"
          name="StudentDeclaration"
          rules={[
            {
              required: true,
              message: "Please upload Scanned Student Declaration",
            },
          ]}
        >
          <FileUpload />
        </Form.Item>
        <Form.Item
          label={"Is Student KBC Member"}
          name={"KBCMember"}
          rules={[
            {
              required: true,
              message: "Please seelect either 'Yes' or 'No'",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {isKBCMember ? (
          <Form.Item
            label="Student KBC Membership Number"
            name="StudentKBCNumber"
            rules={[
              {
                required: isKBCMember,
                message: "Please input your Student KBC Membership Number",
              },
              {
                pattern: new RegExp(/^[0-9]{8}$/),
                message:
                  "Please enter a valid membership number in the format: 12345678",
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : null}
        <Form.Item
          label={"Has student taken part in any previous KBC Regatta?"}
          name={"PreviousRegatta"}
          rules={[
            {
              required: true,
              message: "Please select either 'Yes' or 'No'",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={"Is student a COX?"}
          name={"COX"}
          rules={[
            {
              required: true,
              message: "Please select either 'Yes' or 'No'",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {isCox ? (
          <>
            <Form.Item
              label={"Has student attended COX Seminar?"}
              name={"CoxSeminar"}
              rules={[
                {
                  required: isCox,
                  message: "Please select either 'Yes' or 'No'",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={"Has student passed COX Test?"}
              name={"CoxTest"}
              rules={[
                {
                  required: isCox,
                  message: "Please select either 'Yes' or 'No'",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={"Has student coxed in any previous regatta"}
              name={"CoxPreviousRegatta"}
              rules={[
                {
                  required: isCox,
                  message: "Please select either 'Yes' or 'No'",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Space
                style={{ justifyContent: "flex-end", width: "100%" }}
                width="100%"
                align="end"
              >
                <Button type="default" onClick={onModalCancel}>
                  Cancel
                </Button>
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !requiredFieldsTouched(form, requiredFields) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length ||
                    waitingOnResponse
                  }
                >
                  Submit
                </Button>
              </Space>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewStudent;
const CNICInput = (nationality) => {
  var isPaki = nationality.nationality === "Pakistani";
  return (
    <Form.Item
      label={`Student ${isPaki ? "Citzenship Number" : "Passport Number"}`}
      name="StudentCNICNumber"
      rules={[
        {
          required: true,
          message: `Please enter Student ${
            isPaki ? "Citzenship Number" : "Passport Number"
          }`,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            var cnicRegex = new RegExp(/^[0-9]{5}-[0-9]{7}-[0-9]$/);
            if (!value || !isPaki || cnicRegex.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(
                `Please enter a valid CNIC in the format 42301-1234567-8`
              )
            );
          },
        }),
      ]}
    >
      <Input />
    </Form.Item>
  );
};
function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      returnValue = false;
    }
  });
  return returnValue;
}

function YOBCategory(DOB) {
  if (DOB >= 2008) {
    return "Under 15";
  }
  if (DOB >= 2005) {
    return "Under 18";
  }
  if (DOB >= 2002) {
    return "Under 21";
  }
}

const CategorySelect = ({ value, onChange, SetShowVacUpload }) => {
  return (
    <div>
      <DatePicker
        allowClear={false}
        picker="year"
        onChange={(v) => {
          onChange(v?.year());
          if (v && v?.year() < 2004) {
            SetShowVacUpload(true);
          } else {
            SetShowVacUpload(false);
          }
        }}
      />
      {value ? <div>Student Category: {YOBCategory(value)}</div> : <></>}
    </div>
  );
};

const NationalitySelect = ({ value, onChange, SetStudentNationality }) => {
  return (
    <Select
      value={value}
      onChange={(value) => {
        onChange(value);
        SetStudentNationality(value);
      }}
    >
      <Option value="Pakistani">Pakistani</Option>
      <Option value="Foreign National">Foreign National</Option>
    </Select>
  );
};

const PhotoFileUpload = ({ value, onChange, onUpload }) => {
  return (
    <ImgCrop rotate aspect={35 / 45}>
      <Upload
        maxCount={1}
        accept=".png,.jpg,.jpeg"
        listType="picture-card"
        onRemove={() => {
          onChange("");
        }}
        onChange={() => {}}
        beforeUpload={(file) => {
          let reader = new FileReader();
          reader.addEventListener("load", function (e) {
            let text = e.target.result;
            onChange(text);
          });
          reader.readAsDataURL(file);
          //Dont Prevent upload to avoid thumbnail bug hence returning true
          return true;
        }}
      >
        <Button>Select File</Button>
      </Upload>
    </ImgCrop>
  );
};

const FileUpload = ({ value, onChange, onUpload }) => {
  return (
    <Upload
      maxCount={1}
      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onRemove={() => {
        onChange("");
      }}
      onChange={() => {}}
      beforeUpload={(file) => {
        let reader = new FileReader();
        reader.addEventListener("load", function (e) {
          let text = e.target.result;
          onChange(text);
        });
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
      }}
    >
      <Button>Select File</Button>
    </Upload>
  );
};
