import React from "react";

import { Modal, Input, Button, Select, Form, Popconfirm } from "antd";

import { StopOutlined } from "@ant-design/icons";

import { useState, useEffect } from "react";

import {
  GetSchools,
  AddRaceResults,
  GetDisqualifications,
} from "../../APIManager";

import styles from "./ChooseRaceResults.module.css";

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

export default function ChooseRaceResultsModal({
  modalVisible,
  onCloseModal,
  OnSubmit,
  race,
  schools,
  isReadOnly,
}) {
  const [raceOrder, SetRaceOrder] = useState([]);

  const [disqualifications, SetDisqualifications] = useState([]);
  const [dataFetched, SetDataFetched] = useState(false);

  useEffect(() => {
    GetDisqualifications().then((res) => {
      SetDisqualifications(res.data);
      SetDataFetched(true);
    });
  }, []);

  useEffect(() => {
    if (race?.RaceResults?.length > 0) {
      SetRaceOrder(race.RaceResults);
    }
  }, [race, modalVisible]);

  useEffect(() => {
    if (!race || schools.length < 1) {
      return;
    }

    if (race.RaceResults.length > 0) {
      const RaceResults = global
        .structuredClone(race.RaceResults)
        .map((p) => GetSchoolInfo(p));

      console.log(RaceResults);

      RaceResults.forEach((p, index) => {
        const dq = disqualifications.filter(
          (d) => d.RaceName == race.RaceName && d.School._id == p._id
        )[0];

        if (dq) {
          RaceResults[index].dq = dq;
        }
        var isDisqualified = race.Participants.filter(
          (pp) => pp.school == p._id
        )[0].isDisqualified;

        RaceResults[index].isDisqualified = isDisqualified;
      });

      SetRaceOrder(RaceResults);
    } else {
      const RaceDetails = global.structuredClone(race);

      RaceDetails.Participants.forEach((p, index) => {
        if (p.isPlaceHolder) {
          return;
        } else {
          RaceDetails.Participants[index].school = GetSchoolInfo(p.school);
          const dq = disqualifications.filter(
            (d) => d.RaceName == race.RaceName && d.School._id == p.school._id
          )[0];

          if (dq) {
            RaceDetails.Participants[index].school.dq = dq;
          } else {
            RaceDetails.Participants[index].school.dq = null;
          }
          if (RaceDetails.Participants[index].isDisqualified) {
            RaceDetails.Participants[index].school.isDisqualified = true;
          } else {
            RaceDetails.Participants[index].school.isDisqualified = false;
          }
        }
      });

      // Sorting logic
      console.log(RaceDetails.Participants);
      RaceDetails.Participants.sort((a, b) => {
        // Check if DQ property exists in each object

        if (a.isPlaceHolder || b.isPlaceHolder) {
          return 0;
        }

        const hasDQA = a.school.dq || a.isDisqualified;
        const hasDQB = b.school.dq || b.isDisqualified;

        console.log(hasDQA);
        console.log(hasDQB);

        // Compare based on the presence of DQ property
        if (hasDQA && !hasDQB) {
          console.log("brrrrrrrrrrrrrrrrrrr");
          return 1; // If 'a' has DQ but 'b' doesn't, 'a' comes after 'b'
        } else if (!hasDQA && hasDQB) {
          return -1; // If 'b' has DQ but 'a' doesn't, 'b' comes after 'a'
        } else {
          return 0; // If both have DQ or neither have DQ, maintain their order
        }
      });
      SetRaceOrder(RaceDetails.Participants.map((p) => p.school));
    }
  }, [schools, race, modalVisible, disqualifications]);

  function GetSchoolInfo(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  if (!race) {
    return <></>;
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      raceOrder,
      result.source.index,
      result.destination.index
    );

    SetRaceOrder(items);
  }

  const resultsSet = race?.RaceResults?.length > 1;

  return (
    <div>
      <Modal
        title={`${resultsSet ? "Update" : "Add"} race results for ${
          race.RaceName
        }`}
        forceRender
        visible={modalVisible}
        onOk={() => {
          //OnSubmit(selectedDay);
        }}
        onCancel={onCloseModal}
        okText="Confirm Results"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          hidden: true,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        {isReadOnly && resultsSet < 1 && <div>Race Results are awaited</div>}
        <div hidden={isReadOnly && resultsSet < 1}>
          <DragDropContext onDragEnd={onDragEnd} key={race.RaceName}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {raceOrder.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                      isDragDisabled={
                        isReadOnly ||
                        Boolean(item.dq) ||
                        Boolean(item.isDisqualified)
                      }
                    >
                      {(provided, snapshot) => (
                        <div
                          hidden={item.isDisqualified}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          /* style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )} */

                          className={`${styles.SchoolRow} ${
                            snapshot.isDragging ? styles.isDragging : ""
                          }`}
                        >
                          <div
                            className={`${styles.PositionNumber} ${
                              item.dq ? styles.DQNumber : ""
                            }`}
                          >
                            {item.dq ? item.dq.ShortType : index + 1}
                          </div>
                          <div>
                            {item.schoolname}{" "}
                            {item.dq?.Type ? `(${item.dq.Type})` : ""}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Popconfirm
          placement="bottomLeft"
          title={
            "Are you sure you want to add these race results. This action cannot be undone"
          }
          onConfirm={() => {
            AddRaceResults({
              raceName: race.RaceName,
              raceResults: raceOrder.map((r) => r._id),
            }).then(() => {
              OnSubmit();
            });
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button disabled={isReadOnly} hidden={isReadOnly} type="primary">
            {resultsSet ? "Update Results" : "Add Results"}
          </Button>
        </Popconfirm>
      </Modal>
    </div>
  );
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.sort((a, b) => {
    var DQA = a.dq || a.isDisqualified;
    var DQB = b.dq || b.isDisqualified;

    if (DQA && !DQB) {
      return 1; // If 'a' has DQ but 'b' doesn't, 'a' comes after 'b'
    } else if (!DQA && DQB) {
      return -1; // If 'b' has DQ but 'a' doesn't, 'b' comes after 'a'
    } else {
      return 0; // If both have DQ or neither have DQ, maintain their order
    }
  });

  return result;
};
