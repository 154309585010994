import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Checkbox,
  Divider,
} from "antd";

const { Option } = Select;

const RaceDefinitionForm = () => {
  const [raceDefinition, setRaceDefinition] = useState({
    name_long: "",
    name_short: "",
    event_name: "",
    duration_type: "",
    duration: 0,
    race_type: "",
    team_size: null,
    team_scoring: "",
    split_type: "",
    split_value: null,
    race_id: "",
    sound_horn_at_splits: null,
    segments: [],
    classes: [],
    boats: [],
  });

  const handleAddClass = () => {
    setRaceDefinition({
      ...raceDefinition,
      classes: [...raceDefinition.classes, { name: "", score: "", year: "" }],
    });
  };

  const handleAddBoat = () => {
    setRaceDefinition({
      ...raceDefinition,
      boats: [
        ...raceDefinition.boats,
        {
          class_name: "",
          lane_number: null,
          name: "",
          machine_type: "",
          affiliation: "",
          id: "",
        },
      ],
    });
  };

  const handleFormChange = (field, value) => {
    setRaceDefinition({ ...raceDefinition, [field]: value });
  };

  const handleClassChange = (index, field, value) => {
    const updatedClasses = [...raceDefinition.classes];
    updatedClasses[index][field] = value;
    setRaceDefinition({ ...raceDefinition, classes: updatedClasses });
  };

  const handleBoatChange = (index, field, value) => {
    const updatedBoats = [...raceDefinition.boats];
    updatedBoats[index][field] = value;
    setRaceDefinition({ ...raceDefinition, boats: updatedBoats });
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Long Race Name">
        <Input
          onChange={(e) => handleFormChange("name_long", e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Short Race Name">
        <Input
          onChange={(e) => handleFormChange("name_short", e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Event Name">
        <Input
          onChange={(e) => handleFormChange("event_name", e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Duration Type">
        <Select onChange={(value) => handleFormChange("duration_type", value)}>
          <Option value="meters">Meters</Option>
          <Option value="time">Time</Option>
          <Option value="calories">Calories</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Duration">
        <InputNumber
          onChange={(value) => handleFormChange("duration", value)}
        />
      </Form.Item>

      <Form.Item label="Race Type">
        <Select onChange={(value) => handleFormChange("race_type", value)}>
          <Option value="individual">Individual</Option>
          <Option value="team">Team</Option>
          <Option value="relay">Relay</Option>
          <Option value="mixed erg sequential">Mixed Erg Sequential</Option>
          <Option value="biathlon">Biathlon</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Team Size">
        <InputNumber
          onChange={(value) => handleFormChange("team_size", value)}
        />
      </Form.Item>

      <Form.Item label="Split Type">
        <Select onChange={(value) => handleFormChange("split_type", value)}>
          <Option value="none">None</Option>
          <Option value="even">Even</Option>
          <Option value="random">Random</Option>
        </Select>
      </Form.Item>

      <Divider>Classes</Divider>
      {raceDefinition.classes.map((_, index) => (
        <div key={index}>
          <Form.Item label="Class Name">
            <Input
              onChange={(e) => handleClassChange(index, "name", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Score">
            <Input
              onChange={(e) =>
                handleClassChange(index, "score", e.target.value)
              }
            />
          </Form.Item>
          <Form.Item label="Year">
            <Input
              onChange={(e) => handleClassChange(index, "year", e.target.value)}
            />
          </Form.Item>
        </div>
      ))}
      <Button onClick={handleAddClass}>Add Class</Button>

      <Divider>Boats</Divider>
      {raceDefinition.boats.map((_, index) => (
        <div key={index}>
          <Form.Item label="Class Name">
            <Input
              onChange={(e) =>
                handleBoatChange(index, "class_name", e.target.value)
              }
            />
          </Form.Item>
          <Form.Item label="Lane Number">
            <InputNumber
              onChange={(value) =>
                handleBoatChange(index, "lane_number", value)
              }
            />
          </Form.Item>
          <Form.Item label="Boat Name">
            <Input
              onChange={(e) => handleBoatChange(index, "name", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Machine Type">
            <Select
              onChange={(value) =>
                handleBoatChange(index, "machine_type", value)
              }
            >
              <Option value="bike">Bike</Option>
              <Option value="ski">Ski</Option>
              <Option value="row">Row</Option>
            </Select>
          </Form.Item>
        </div>
      ))}
      <Button onClick={handleAddBoat}>Add Boat</Button>

      <Divider />
      <Button type="primary" onClick={() => console.log(raceDefinition)}>
        Generate JSON
      </Button>
    </Form>
  );
};

export default RaceDefinitionForm;
