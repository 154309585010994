import React, { useState, useContext, useEffect } from "react";
import styles from "./LoginPage.module.css";
import { Login, getLoggedInUser } from "../../APIManager";
import { UserContext } from "../../UserContext";
import { useHistory } from "react-router";
import loginIcon from "../../Static/isr_logo.png";
import { Form, Input, Button, Alert } from "antd";

const LoginPage = () => {
  // eslint-disable-next-line
  let history = useHistory();
  let [userContext, SetUserContext] = useContext(UserContext);
  const [errorMessage, SetErrorMessage] = useState();
  const [showErrorMessage, SetShowErrorMessage] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    var username = form.getFieldValue("username");
    var password = form.getFieldValue("password");
    SetWaitingOnResponse(true);
    Login(username, password)
      .then((res) => {
        if (!res.data.success) {
          SetShowErrorMessage(true);
          SetErrorMessage(res.data.status);
          SetWaitingOnResponse(false);
        } else {
          SetWaitingOnResponse(false);
          getLoggedInUser().then((res) => {
            SetUserContext(res.data);
            history.push("/");
          });
        }
      })
      .catch(() => {
        SetShowErrorMessage(true);
        SetErrorMessage(
          "A Network/Server error has occured. Please check your internet connection"
        );
        SetWaitingOnResponse(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles.MainDiv}>
      <div className={styles.Container}>
        <div className={styles.LoginBox}>
          <img alt="ISR Logo" className={styles.IconImage} src={loginIcon} />
          <div className={styles.LoginHeading}>
            <h1>Admin Login</h1>
          </div>
          <div hidden={!showErrorMessage} style={{ width: "100%" }}>
            <div
              key={showErrorMessage.toString() + "alertdiv"}
              className={styles.ErrorAlert}
            >
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                showIcon
                closable
                afterClose={() => {
                  SetShowErrorMessage(false);
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Form
              name="basic"
              form={form}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout={"vertical"}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                  /* {
                    validator(rule, value, callback) {
                      console.log(rule)
                      if (value === "lol") {
                        callback("Lol is not valid");
                      }
            
                      callback();
                    } }*/
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item shouldUpdate={true}>
                {() => {
                  console.log(
                    form.getFieldsError().filter(({ errors }) => errors.length)
                  );
                  return (
                    <Button
                      size={"large"}
                      block
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length ||
                        waitingOnResponse
                      }
                    >
                      Submit
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
