import { useCallback } from "react";
import { Handle, Position } from "react-flow-renderer";

import styles from "./CustomNode.module.css";

function TextUpdaterNode(props) {
  const onChange = useCallback((evt) => {}, []);

  //console.log(props);

  const { id, data } = props;

  const { RaceLabel, School, IsEditable, OnEdit, niPosition, Positions } = data;
  if (RaceLabel.includes("Heat 1")) {
  }
  function GetNodeStyle(id) {
    if (id.includes("Heat")) {
      return styles.Heats;
    }
    if (id.includes("Repecharge")) {
      return styles.Repercharge;
    }
    if (id.includes("Quarter")) {
      return styles.QF;
    }
    if (id.includes("Semi")) {
      return styles.SF;
    }
    if (id.includes("Final")) {
      return styles.Finals;
    }
  }

  return (
    <div className={`${styles.CustomNode} ${GetNodeStyle(id)}`}>
      <Handle type="source" position={Position.Left} />
      <Handle type="target" position={Position.Right} />
      <div className={styles.Container}>
        <div className={styles.RaceLabel}>{data.RaceLabel}</div>
        {data.SchoolNames.map((s, index) => {
          return (
            <div className={styles.Schools}>
              <div
                onDoubleClick={() => {
                  if (OnEdit) {
                    OnEdit({
                      ...data,
                      positionClicked: parseInt(Positions[index]) - 1,
                    });
                  }
                }}
              >
                {s}
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TextUpdaterNode;
