const ProgressionSystems = [
  {
    ID: "2LE2",
    Lanes: 2,
    Entries: 2,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "Finals", SchoolNames: ["1/P1", "2/P1"] },
      },
    ],
  },
  {
    ID: "2LE3",
    Lanes: 2,
    Entries: 3,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: {
          RaceLabel: "Heat 1 ",
          SchoolNames: ["1", "2"],
        },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 90 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H1", "1/H2"] },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 200, y: 0 },
        data: { RaceLabel: "Finals", SchoolNames: ["1/H1", "1/R1"] },
      },
    ],
    edges: [
      {
        id: "edge-1",
        source: "Heat 1",
        target: "Finals",
        sourceHandle: "a",
        type: "smoothstep",
        label: "1st (1 Boat)",
        zIndex: -10,
      },
      {
        id: "edge-2",
        source: "Heat 2",
        target: "Repecharge 1",
        sourceHandle: "a",
        type: "smoothstep",
        label: "1st (1 Boat)",
        zIndex: -10,
      },
      {
        id: "edge-2",
        source: "Repecharge 1",
        target: "Finals",
        sourceHandle: "a",
        type: "smoothstep",
        label: "1st (1 Boat)",
        zIndex: -10,
      },
    ],
  },
  {
    ID: "2LE4",
    Lanes: 2,
    Entries: 4,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -75, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/H1", "2/H2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -75, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/H2", "2/H1"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 170, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 170, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF2", "2/SF1"] },
      },
    ],
    edges: [
      {
        id: "edge-1",
        source: "Heat 1",
        target: "SF 1",
        sourceHandle: "a",
        type: "smoothstep",
        label: "1st (1 Boat)",
        zIndex: -10,
      },
      {
        id: "edge-2",
        source: "Heat 1",
        target: "SF 2",
        sourceHandle: "a",
        type: "smoothstep",
        label: "2nd (1 Boat)",
        zIndex: -10,
      },
      {
        id: "edge-3",
        source: "Heat 2",
        target: "SF 1",
        sourceHandle: "a",
        type: "smoothstep",
        label: "2nd (1 Boat)",
        zIndex: -10,
      },
      {
        id: "edge-4",
        source: "Heat 2",
        target: "SF 2",
        sourceHandle: "a",
        type: "smoothstep",
        label: "1st (1 Boat)",
        zIndex: -10,
      },
    ],
  },
  {
    ID: "2LE5",
    Lanes: 2,
    Entries: 5,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -250, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H2", "1/H3"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -100, y: 100 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H1", "2/R1"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/H1", "1/R1"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/H2", "1/R2"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE6",
    Lanes: 2,
    Entries: 6,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -250, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H2", "2/H1"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -100, y: 100 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/R1", "2/H3"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/H1", "1/R2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/H2", "1/H3"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE7",
    Lanes: 2,
    Entries: 7,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -250, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -100, y: 100 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["1/R1", "2/R2"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H3", "1/H4"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -100, y: 200 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["1/R2", "1/R3"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/H1", "1/R4"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/H2", "1/H3"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE8",
    Lanes: 2,
    Entries: 8,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "2/H3"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "2/H4"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H3", "2/H1"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H4", "2/H2"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE9",
    Lanes: 2,
    Entries: 9,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -600, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -600, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -600, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -600, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -600, y: 400 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["9"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H3", "1/H5"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["2/H4", "2/R1"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -250, y: 300 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["2/R2", "2/R3"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "1/R2"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "1/R4"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H3", "1/R3"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H4", "1/R1"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE10",
    Lanes: 2,
    Entries: 10,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -600, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -600, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -600, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -600, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -600, y: 400 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["9", "10"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H4", "2/H5"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["2/R1", "2/H3"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -250, y: 300 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["1/R1", "2/R2"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "1/H5"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "1/R1"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H3", "1/R2"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H4", "1/R4"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE11",
    Lanes: 2,
    Entries: 11,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -600, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -600, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -600, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -600, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -600, y: 400 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["9", "10"] },
      },
      {
        id: "Heat 6",
        type: "textUpdater",
        position: { x: -600, y: 500 },
        data: { RaceLabel: "Heat 6", SchoolNames: ["11"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["1/H6", "2/H5"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["2/H3", "2/R1"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -250, y: 300 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["2/H4", "1/R3"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "1/H5"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "1/R1"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H3", "1/R2"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H4", "1/R4"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE12",
    Lanes: 2,
    Entries: 12,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -600, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -600, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -600, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -600, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -600, y: 400 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["9", "10"] },
      },
      {
        id: "Heat 6",
        type: "textUpdater",
        position: { x: -600, y: 500 },
        data: { RaceLabel: "Heat 6", SchoolNames: ["11", "12"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H6", "2/H5"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -250, y: 100 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["2/H3", "1/R1"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["2/H4", "1/R2"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "1/H4"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "1/R3"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H5", "1/R4"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H6", "1/H3"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE13",
    Lanes: 2,
    Entries: 13,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -600, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -600, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -600, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -600, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -600, y: 400 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["9", "10"] },
      },
      {
        id: "Heat 6",
        type: "textUpdater",
        position: { x: -600, y: 500 },
        data: { RaceLabel: "Heat 6", SchoolNames: ["11", "12"] },
      },
      {
        id: "Heat 7",
        type: "textUpdater",
        position: { x: -600, y: 600 },
        data: { RaceLabel: "Heat 7", SchoolNames: ["13"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H3", "2/H4"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -400, y: 300 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["1/H7", "2/H5"] },
      },
      {
        id: "Repecharge 6",
        type: "textUpdater",
        position: { x: -250, y: 100 },
        data: { RaceLabel: "Repecharge 6", SchoolNames: ["2/H6", "2/R3"] },
      },
      {
        id: "Repecharge 5",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 5", SchoolNames: ["1/R3", "1/R4"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -250, y: 300 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["1/R1", "1/R2"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "1/R5"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "1/H6"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H3", "1/R6"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H4", "1/H5"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE14",
    Lanes: 2,
    Entries: 14,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -600, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -600, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -600, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -600, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -600, y: 400 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["9", "10"] },
      },
      {
        id: "Heat 6",
        type: "textUpdater",
        position: { x: -600, y: 500 },
        data: { RaceLabel: "Heat 6", SchoolNames: ["11", "12"] },
      },
      {
        id: "Heat 7",
        type: "textUpdater",
        position: { x: -600, y: 600 },
        data: { RaceLabel: "Heat 7", SchoolNames: ["13", "14"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H3", "2/H4"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -400, y: 300 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["2/H5", "2/H7"] },
      },
      {
        id: "Repecharge 6",
        type: "textUpdater",
        position: { x: -250, y: 100 },
        data: { RaceLabel: "Repecharge 6", SchoolNames: ["1/R4", "1/R5"] },
      },
      {
        id: "Repecharge 5",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 5", SchoolNames: ["1/R1", "1/R2"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -250, y: 300 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["1/R3", "2/H6"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "1/H5"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "1/H6"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H3", "1/H7"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H4", "1/R6"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "2LE15",
    Lanes: 2,
    Entries: 15,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -600, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -600, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -600, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -600, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -600, y: 400 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["9", "10"] },
      },
      {
        id: "Heat 6",
        type: "textUpdater",
        position: { x: -600, y: 500 },
        data: { RaceLabel: "Heat 6", SchoolNames: ["11", "12"] },
      },
      {
        id: "Heat 7",
        type: "textUpdater",
        position: { x: -600, y: 600 },
        data: { RaceLabel: "Heat 7", SchoolNames: ["13", "14"] },
      },
      {
        id: "Heat 8",
        type: "textUpdater",
        position: { x: -600, y: 700 },
        data: { RaceLabel: "Heat 8", SchoolNames: ["15"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Repecharge 1", SchoolNames: ["2/H1", "2/H2"] },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Repecharge 2", SchoolNames: ["2/H3", "2/H4"] },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -400, y: 300 },
        data: { RaceLabel: "Repecharge 3", SchoolNames: ["2/H5", "2/H6"] },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -400, y: 400 },
        data: { RaceLabel: "Repecharge 4", SchoolNames: ["2/H7", "1/H8"] },
      },
      {
        id: "Repecharge 8",
        type: "textUpdater",
        position: { x: -250, y: 100 },
        data: { RaceLabel: "Repecharge 8", SchoolNames: ["1/R6", "1/R2"] },
      },
      {
        id: "Repecharge 7",
        type: "textUpdater",
        position: { x: -250, y: 200 },
        data: { RaceLabel: "Repecharge 7", SchoolNames: ["1/R3", "1/R4"] },
      },
      {
        id: "Repecharge 6",
        type: "textUpdater",
        position: { x: -250, y: 300 },
        data: { RaceLabel: "Repecharge 6", SchoolNames: ["1/R5", "1/R2"] },
      },
      {
        id: "Repecharge 5",
        type: "textUpdater",
        position: { x: -250, y: 400 },
        data: { RaceLabel: "Repecharge 5", SchoolNames: ["2/R4", "1/R1"] },
      },
      {
        id: "Quarterfinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: { RaceLabel: "QF 1", SchoolNames: ["1/H1", "1/H5"] },
      },
      {
        id: "Quarterfinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: { RaceLabel: "QF 2", SchoolNames: ["1/H2", "1/H6"] },
      },
      {
        id: "Quarterfinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: { RaceLabel: "QF 3", SchoolNames: ["1/H3", "1/H7"] },
      },
      {
        id: "Quarterfinal 4",
        type: "textUpdater",
        position: { x: -50, y: 300 },
        data: { RaceLabel: "QF 4", SchoolNames: ["1/H4", "1/R8"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "SF 1", SchoolNames: ["1/QF1", "1/QF2"] },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 100, y: 100 },
        data: { RaceLabel: "SF 2", SchoolNames: ["1/QF3", "1/QF4"] },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 250, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/SF1", "1/SF2"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 250, y: 100 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/SF1", "2/SF2"] },
      },
    ],
    edges: [],
  },
  {
    ID: "3LE3",
    Lanes: 3,
    Entries: 3,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "Finals", SchoolNames: ["2/P1", "1/P1", "3/P1"] },
      },
    ],
  },
  {
    ID: "3LE4",
    Lanes: 3,
    Entries: 4,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H2", "2/H1"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/H1", "1/H2", "1/R1"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/R2"] },
      },
    ],
  },
  {
    ID: "3LE5",
    Lanes: 3,
    Entries: 5,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H2", "2/H1", "3/H1"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "Final A", SchoolNames: ["1/H1", "1/H2", "1/R1"] },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: { RaceLabel: "Final B", SchoolNames: ["2/R1", "3/R1"] },
      },
    ],
  },
  {
    ID: "3LE6",
    Lanes: 3,
    Entries: 6,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5", "6"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["3/H2", "2/H1"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["3/H1", "2/H2"],
        },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -50, y: 60 },
        data: {
          RaceLabel: "Repecharge 3",
          SchoolNames: ["1/R1", "1/R1"],
        },
      },
      {
        id: "Final",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: { RaceLabel: "Final", SchoolNames: ["1/H2", "1/H1", "1/R3"] },
      },
    ],
  },
  {
    ID: "3LE7",
    Lanes: 3,
    Entries: 7,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 220 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["6", "7"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H3", "2/H1"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["3/H1", "2/H2"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/H3", "1/H2", "1/R2"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 120 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/R1", "1/H1"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF1", "2/SF2"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: { RaceLabel: "Final B", SchoolNames: ["3/SF1", "2/SF2"] },
      },
    ],
  },
  {
    ID: "3LE8",
    Lanes: 3,
    Entries: 8,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 100 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["3", "4"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 200 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["5", "6"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 300 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["7", "8"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H4", "2/H2"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 100 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["2/H3", "2/H1"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/R1", "1/H1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/R2", "1/H2"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/H4", "1/H3"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF1", "1/SF3"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF1", "2/SF3"],
        },
      },
    ],
  },
  {
    ID: "3LE9",
    Lanes: 3,
    Entries: 9,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 220 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["6", "7"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 320 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["8", "9"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H4", "2/H2", "3/H1"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["2/H3", "2/H1"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/R1", "1/H1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/R2", "1/H2"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/H4", "1/H3"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF1", "1/SF3"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF1", "2/SF3"],
        },
      },
    ],
  },
  {
    ID: "3LE10",
    Lanes: 3,
    Entries: 10,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5", "6"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 240 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 340 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["9", "10"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H4", "2/H2", "3/H1"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["2/H3", "2/H1", "3/H2"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/R1", "1/H1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 100 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/R2", "1/H2"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 200 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/H4", "1/H3"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF1", "1/SF3"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF1", "2/SF3"],
        },
      },
    ],
  },
  {
    ID: "3LE11",
    Lanes: 3,
    Entries: 11,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 220 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["6", "7"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 320 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["8", "9"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -400, y: 420 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["10", "11"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["3/H1", "2/H4"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 100 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["2/H5", "2/H2"],
        },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -200, y: 200 },
        data: {
          RaceLabel: "Repecharge 3",
          SchoolNames: ["2/H3", "2/H1"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/H4", "1/H3", "1/R1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 120 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/H5", "1/H2", "1/R3"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 240 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/R2", "1/H1"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF3", "1/SF1"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF3", "2/SF1"],
        },
      },
    ],
  },
  {
    ID: "3LE12",
    Lanes: 3,
    Entries: 12,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5", "6"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 240 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["7", "8"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 340 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["9", "10"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -400, y: 440 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["11", "12"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["3/H1", "2/H4", "3/H2"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["2/H5", "2/H2"],
        },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -200, y: 220 },
        data: {
          RaceLabel: "Repecharge 3",
          SchoolNames: ["2/H3", "2/H1"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/H4", "1/H3", "1/R1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 120 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/H5", "1/H2", "1/R3"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 240 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/R2", "1/H1"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF3", "1/SF1"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF3", "2/SF1"],
        },
      },
    ],
  },
  {
    ID: "3LE13",
    Lanes: 3,
    Entries: 13,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5", "6"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 240 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["7", "8", "9"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 360 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["10", "11"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -400, y: 460 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["12", "13"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["3/H1", "2/H4", "3/H2"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["2/H5", "2/H2", "3/H3"],
        },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -200, y: 240 },
        data: {
          RaceLabel: "Repecharge 3",
          SchoolNames: ["2/H3", "2/H1"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/H4", "1/H3", "1/R1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 120 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/H5", "1/H2", "1/R3"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 240 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/R2", "1/H1"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF3", "1/SF1"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF3", "2/SF1"],
        },
      },
    ],
  },
  {
    ID: "3LE14",
    Lanes: 3,
    Entries: 14,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5", "6"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 240 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["7", "8", "9"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 360 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["10", "11", "12"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -400, y: 480 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["13", "14"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["3/H1", "2/H4", "3/H2"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["2/H5", "2/H2", "3/H3"],
        },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -200, y: 240 },
        data: {
          RaceLabel: "Repecharge 3",
          SchoolNames: ["2/H3", "2/H1", "3/H4"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/H4", "1/H3", "1/R1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 120 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/H5", "1/H2", "1/R3"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 240 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/R2", "1/H1"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF3", "1/SF1"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF3", "2/SF1"],
        },
      },
    ],
  },
  {
    ID: "3LE15",
    Lanes: 3,
    Entries: 15,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5", "6"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 240 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["7", "8", "9"] },
      },
      {
        id: "Heat 4",
        type: "textUpdater",
        position: { x: -400, y: 360 },
        data: { RaceLabel: "Heat 4", SchoolNames: ["10", "11", "12"] },
      },
      {
        id: "Heat 5",
        type: "textUpdater",
        position: { x: -400, y: 480 },
        data: { RaceLabel: "Heat 5", SchoolNames: ["13", "14", "15"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H1", "3/H2", "2/H5"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["3/H1", "2/H3", "3/H5"],
        },
      },
      {
        id: "Repecharge 3",
        type: "textUpdater",
        position: { x: -200, y: 240 },
        data: {
          RaceLabel: "Repecharge 3",
          SchoolNames: ["2/H2", "3/H4"],
        },
      },
      {
        id: "Repecharge 4",
        type: "textUpdater",
        position: { x: -200, y: 340 },
        data: {
          RaceLabel: "Repecharge 4",
          SchoolNames: ["3/H3", "2/H4"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -50, y: 0 },
        data: {
          RaceLabel: "SF 1",
          SchoolNames: ["1/H1", "1/H4", "1/R3"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -50, y: 120 },
        data: {
          RaceLabel: "SF 2",
          SchoolNames: ["1/H2", "1/H5", "1/R2"],
        },
      },
      {
        id: "Semifinal 3",
        type: "textUpdater",
        position: { x: -50, y: 240 },
        data: {
          RaceLabel: "SF 3",
          SchoolNames: ["1/H3", "1/R1", "1/R4"],
        },
      },
      {
        id: "Final A",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final A",
          SchoolNames: ["1/SF2", "1/SF1", "1/SF3"],
        },
      },
      {
        id: "Final B",
        type: "textUpdater",
        position: { x: 100, y: 120 },
        data: {
          RaceLabel: "Final B",
          SchoolNames: ["2/SF2", "2/SF1", "2/SF3"],
        },
      },
    ],
  },
  {
    ID: "4LE4",
    Lanes: 4,
    Entries: 4,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3", "4"] },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Finals",
          SchoolNames: ["3/P1", "1/P1", "2/P1", "4/P1"],
        },
      },
    ],
  },
  {
    ID: "4LE5",
    Lanes: 4,
    Entries: 5,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["2/H1", "2/H2", "3/H1"],
        },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Finals",
          SchoolNames: ["1/R1", "1/H1", "1/H2", "2/R1"],
        },
      },
    ],
  },
  {
    ID: "4LE6",
    Lanes: 4,
    Entries: 6,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 120 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["4", "5", "6"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "SF1",
          SchoolNames: ["3/H1", "2/H2", "2/H1", "3/H2"],
        },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final",
          SchoolNames: ["1/SF1", "1/H1", "1/H2", "2/SF1"],
        },
      },
    ],
  },
  {
    ID: "4LE7",
    Lanes: 4,
    Entries: 7,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3", "4"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 140 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["5", "6", "7"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "SF1",
          SchoolNames: ["3/H2", "2/H1", "4/H1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "SF2",
          SchoolNames: ["3/H1", "2/H2"],
        },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final",
          SchoolNames: ["1/SF1", "1/H1", "1/H2", "1/SF2"],
        },
      },
    ],
  },
  {
    ID: "4LE8",
    Lanes: 4,
    Entries: 8,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3", "4"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 140 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["5", "6", "7", "8"] },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "SF1",
          SchoolNames: ["3/H1", "2/H1", "4/H2"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: -200, y: 120 },
        data: {
          RaceLabel: "SF2",
          SchoolNames: ["3/H1", "2/H2", "4/H1"],
        },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 100, y: 0 },
        data: {
          RaceLabel: "Final",
          SchoolNames: ["1/SF1", "1/H1", "1/H2", "1/SF2"],
        },
      },
    ],
  },
  {
    ID: "4LE10",
    Lanes: 4,
    Entries: 10,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3", "4"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 140 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["5", "6", "7"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 280 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["8", "9", "10"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 140 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["3/H1", "3/H2", "3/H3", "4/H1"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 0, y: 0 },
        data: {
          RaceLabel: "SF1",
          SchoolNames: ["2/H2", "1/H1", "1/H3", "1/R1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 0, y: 140 },
        data: {
          RaceLabel: "SF2",
          SchoolNames: ["2/H1", "1/H2", "2/H3", "2/R1"],
        },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 200, y: 0 },
        data: {
          RaceLabel: "Final",
          SchoolNames: ["2/SF2", "1/SF1", "1/SF2", "2/SF1"],
        },
      },
    ],
  },
  {
    ID: "4LE11",
    Lanes: 4,
    Entries: 11,
    nodes: [
      {
        id: "Heat 1",
        type: "textUpdater",
        position: { x: -400, y: 0 },
        data: { RaceLabel: "Heat 1", SchoolNames: ["1", "2", "3", "4"] },
      },
      {
        id: "Heat 2",
        type: "textUpdater",
        position: { x: -400, y: 140 },
        data: { RaceLabel: "Heat 2", SchoolNames: ["5", "6", "7", "8"] },
      },
      {
        id: "Heat 3",
        type: "textUpdater",
        position: { x: -400, y: 280 },
        data: { RaceLabel: "Heat 3", SchoolNames: ["9", "10", "11"] },
      },
      {
        id: "Repecharge 1",
        type: "textUpdater",
        position: { x: -200, y: 0 },
        data: {
          RaceLabel: "Repecharge 1",
          SchoolNames: ["3/H1", "4/H2"],
        },
      },
      {
        id: "Repecharge 2",
        type: "textUpdater",
        position: { x: -200, y: 140 },
        data: {
          RaceLabel: "Repecharge 2",
          SchoolNames: ["3/H3", "4/H1", "3/H2"],
        },
      },
      {
        id: "Semifinal 1",
        type: "textUpdater",
        position: { x: 0, y: 0 },
        data: {
          RaceLabel: "SF1",
          SchoolNames: ["2/H1", "1/H1", "1/H3", "1/R1"],
        },
      },
      {
        id: "Semifinal 2",
        type: "textUpdater",
        position: { x: 0, y: 140 },
        data: {
          RaceLabel: "SF2",
          SchoolNames: ["2/H1", "1/H2", "2/H3", "1/R1"],
        },
      },
      {
        id: "Finals",
        type: "textUpdater",
        position: { x: 200, y: 0 },
        data: {
          RaceLabel: "Final",
          SchoolNames: ["2/SF1", "1/SF2", "1/SF1", "2/SF2"],
        },
      },
    ],
  },
];

export default ProgressionSystems;
