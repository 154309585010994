import React from "react";

import {
  Modal,
  Input,
  Button,
  Select,
  Form,
  Table,
  Popconfirm,
  DatePicker,
} from "antd";

import { useState, useEffect, useContext } from "react";

import {
  AddNewIndoorRecord,
  GetIndoorRecords,
  DeleteIndoorRecord,
} from "../../APIManager";

import { ListOfEvents } from "../../constants";

export default function IndoorRecordsPage() {
  const [addModalOpen, SetModalOpen] = useState(false);
  const [detailsModalOpen, SetDetailsModalOpen] = useState(false);
  const [indoorRecords, SetIndoorRecords] = useState([]);
  const [selectedEventName, SetSelectedEventName] = useState("");

  const [awaitingResponse, SetAwaitingResponse] = useState(true);

  useEffect(() => {
    FetchData();
  }, []);

  function FetchData() {
    GetIndoorRecords().then((res) => {
      SetIndoorRecords(res.data);
    });
  }

  const columns = [
    {
      title: "Event Name",
      dataIndex: "EventName",
      render: (text, record) => (
        <>
          <span>{record.EventName} </span>

          <span
            style={{ color: "blue" }}
            onClick={() => {
              SetSelectedEventName(record.EventName);
              SetDetailsModalOpen(true);
            }}
          >
            History
          </span>
          <Popconfirm
            placement="bottomLeft"
            title={
              "Are you sure you want to delete these race results. This action cannot be undone"
            }
            onConfirm={() => {}}
            okText="Yes"
            cancelText="No"
          ></Popconfirm>
        </>
      ),
    },
    {
      title: "Current Holder",
      key: "Results",
      render: (text, record) => <>{record.RowerName}</>,
    },
    {
      title: "School",
      key: "Results",
      render: (text, record) => <>{record.SchoolName}</>,
    },
    {
      title: "Year",
      key: "Results",
      render: (text, record) => <>{record.Year}</>,
    },
    {
      title: "Time",
      key: "Results",
      render: (text, record) => <>{record.Time}</>,
    },
  ];

  return (
    <div>
      <h1 style={{ fontSize: "1.5em" }}>Indoor Records</h1>
      <Button
        type="primary"
        onClick={() => {
          SetModalOpen(true);
        }}
      >
        Add New Record
      </Button>
      <Table
        columns={columns}
        dataSource={indoorRecords.CurrentRecords}
        pagination={{ defaultPageSize: 50 }}
      />
      <AddModal
        key={`Indoor Race Results Modal ${addModalOpen}`}
        modalVisible={addModalOpen}
        OnSubmit={(form) => {
          SetModalOpen(false);
          AddNewIndoorRecord(
            form.EventName,
            form.RowerName,
            form.SchoolName,
            form.Time,
            form.Year
          ).then((res) => {
            FetchData();
          });
        }}
        onCloseModal={() => {
          SetModalOpen(false);
        }}
      />
      {detailsModalOpen && indoorRecords.GroupedRecords[selectedEventName] ? (
        <HistoryModal
          modalVisible={detailsModalOpen}
          onCloseModal={() => {
            SetDetailsModalOpen(false);
          }}
          onDeleteRecord={(recordID) => {
            DeleteIndoorRecord(recordID).then((res) => {
              FetchData();
            });
          }}
          EventName={selectedEventName}
          Records={indoorRecords.GroupedRecords}
        />
      ) : (
        <></>
      )}
      <div></div>
    </div>
  );
}

const AddModal = ({ modalVisible, OnSubmit, onCloseModal }) => {
  const [form] = Form.useForm();
  const [formValues, SetFormValues] = useState({});

  const { Option } = Select;

  function handleFormValuesChange(e) {
    SetFormValues(form.getFieldsValue());
  }

  return (
    <>
      {" "}
      <Modal
        title={`Add New Indoor Record`}
        forceRender
        visible={modalVisible}
        onOk={() => {}}
        onCancel={onCloseModal}
        okText="Add"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          hidden: true,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <div>
          <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={() => {
              OnSubmit(form.getFieldsValue());
            }}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="horizontal"
            size="large"
            onValuesChange={handleFormValuesChange}
          >
            <Form.Item
              label="Event Name"
              name="EventName"
              rules={[
                {
                  required: true,
                  message: "Please input an Event Name",
                },
              ]}
            >
              <Select showSearch>
                {IndoorEventNames.sort().map(function (item, i) {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={"Rower Names (One on each line)"}
              name={"RowerName"}
              rules={[
                {
                  required: true,
                  message: "Please enter the rower's name",
                },
              ]}
              s
            >
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item
              label={"School Name"}
              name={"SchoolName"}
              rules={[
                {
                  required: true,
                  message: "Please enter the rower's school ",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Time"}
              name={"Time"}
              rules={[
                {
                  required: true,
                  message: "Input race time",
                },
                {
                  message: "Enter in the format 'MM:SS:S' ",
                  validator: (_, value) => {
                    let re = /^\d{2}:\d{2}:\d$/;
                    if (re.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Enter in the format 'MM:SS:S' ");
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Year of Record"
              name="Year"
              shouldUpdate
              rules={[
                {
                  required: true,
                  message: "Please input Year of record",
                },
              ]}
            >
              <YearSelect />
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              {() => {
                return (
                  <Button
                    size={"large"}
                    block
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !requiredFieldsTouched(form, requiredFields) ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Submit
                  </Button>
                );
              }}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

const HistoryModal = ({
  modalVisible,
  onCloseModal,
  onDeleteRecord,
  EventName,
  Records,
}) => {
  console.log(Records);
  const columns = [
    {
      title: "Rower Name",
      key: "Results",
      render: (text, record) => (
        <>
          <span>{record.RowerName} </span>

          <Popconfirm
            placement="bottomLeft"
            title={
              "Are you sure you want to delete this record. This action cannot be undone"
            }
            onConfirm={() => {
              onDeleteRecord(record._id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <span style={{ color: "blue" }}>Delete</span>
          </Popconfirm>
        </>
      ),
    },
    {
      title: "School",
      key: "Results",
      render: (text, record) => <>{record.SchoolName}</>,
    },
    {
      title: "Year",
      key: "Results",
      render: (text, record) => <>{record.Year}</>,
    },
    {
      title: "Time",
      key: "Results",
      render: (text, record) => <>{record.Time}</>,
    },
  ];
  return (
    <>
      {" "}
      <Modal
        title={`View Record History for ${EventName}`}
        forceRender
        visible={modalVisible}
        onOk={() => {}}
        onCancel={onCloseModal}
        okText="Add"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          hidden: true,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <div>
          <Table
            columns={columns}
            dataSource={Records[EventName].sort((a, b) => {
              const timeA = a.Time;
              const timeB = b.Time;
              return timeA.localeCompare(timeB);
            })}
            pagination={{ defaultPageSize: 50 }}
          />
        </div>
      </Modal>
    </>
  );
};

const IndoorEventNames = [
  "Under 25 Boys 1 x 500m",
  "Under 25 Boys 1 x 2000m",
  "Under 25 Boys 2 x 1000m Relay",
  "Under 25 Boys 4 x 500m Relay",
  "Under 21 Boys 1 x 500m",
  "Under 21 Boys 1 x 2000m",
  "Under 21 Boys 1 x 5000m",
  "Under 21 Boys 2 x 1000m Relay",
  "Under 21 Boys 4 x 500m Relay",
  "Under 18 Boys 1 x 500m",
  "Under 18 Boys 1 x 2000m",
  "Under 18 Boys 1 x 5000m",
  "Under 18 Boys 2 x 1000m Relay",
  "Under 18 Boys 4 x 500m Relay",
  "Under 15 Boys 1 x 500m",
  "Under 15 Boys 2 x 1000m Relay",
  "Under 15 Boys 4 x 500m Relay",
  "Under 21 Girls 1 x 500m",
  "Under 21 Girls 1 x 2000m",
  "Under 21 Girls 1 x 5000m",
  "Under 21 Girls 2 x 1000m Relay",
  "Under 21 Girls 4 x 500m Relay",
  "Under 18 Girls 1 x 500m",
  "Under 18 Girls 1 x 2000m",
  "Under 18 Girls 1 x 5000m",
  "Under 18 Girls 2 x 1000m Relay",
  "Under 18 Girls 4 x 500m Relay",
  "Under 15 Girls 1 x 500m",
  "Under 15 Girls 2 x 1000m Relay",
  "Under 15 Girls 4 x 500m Relay",
  "Under 25 Girls 1 x 500m",
  "Under 25 Girls 1 x 2000m",
  "Under 25 Girls 2 x 1000m Relay",
  "Under 25 Girls 4 x 500m Relay",
];

const YearSelect = ({ value, onChange }) => {
  return (
    <DatePicker
      allowClear={false}
      picker="year"
      onChange={(v) => {
        onChange(v?.year());
      }}
    />
  );
};

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      // console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}

const requiredFields = ["EventName", "RowerName", "SchoolName", "Time", "Year"];
