const RaceConfigs = {
  "2L2E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L3E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      PlacementHeat: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
  ],
  "2L4E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L5E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
      PlacementHeat: true,
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L6E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L7E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
      PlacementHeat: true,
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L8E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L9E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
      PlacementHeat: true,
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 2,
        },
        {
          RaceName: "Repecharge 3",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L10E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Heat 5",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
        {
          RaceName: "Heat 3",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L11E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 6",
      RaceIsPreSet: true,
      RacePositions: [],
      PlacementHeat: true,
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 6",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L12E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 6",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 6",
          Position: 2,
        },
        {
          RaceName: "Heat 5",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 6",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L13E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 6",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 7",
      RaceIsPreSet: true,
      RacePositions: [],
      PlacementHeat: true,
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 7",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 3",
          Position: 2,
        },
        {
          RaceName: "Heat 6",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 5",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 6",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 6",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 6",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L14E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 6",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 7",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 1,
        },
        {
          RaceName: "Heat 7",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
        {
          RaceName: "Heat 6",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 5",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 6",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
        {
          RaceName: "Repecharge 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 6",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 7",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Repecharge 6",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "2L15E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 6",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 7",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 8",
      RaceIsPreSet: true,
      RacePositions: [],
      PlacementHeat: true,
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 2,
        },
        {
          RaceName: "Heat 6",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 7",
          Position: 2,
        },
        {
          RaceName: "Heat 8",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 5",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 4",
          Position: 2,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 6",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 5",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 7",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Repecharge 8",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 6",
          Position: 1,
        },
        {
          RaceName: "Repecharge 7",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 6",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Heat 7",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Quaterfinal 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Repecharge 8",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 1",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Quarterfinal 3",
          Position: 1,
        },
        {
          RaceName: "Quarterfinal 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "3L3E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 3,
        },
      ],
    },
  ],
  "3L4E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
      ],
    },
  ],
  "3L5E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
        {
          RaceName: "Repecharge 1",
          Position: 3,
        },
      ],
    },
  ],
  "3L6E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 3,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
  ],
  "3L7E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 3,
        },
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
      ],
    },
  ],
  "3L8E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
      ],
    },
  ],
  "3L9E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
      ],
    },
  ],
  "3L10E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
      ],
    },
  ],
  "3L11E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "3L12E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "3L13E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 3",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "3L14E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 3",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 4",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 5",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "3L15E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 4",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 5",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
        {
          RaceName: "Heat 5",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Heat 5",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 4",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Repecharge 4",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 3,
        },
        {
          RaceName: "Heat 4",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 4",
          Position: 1,
        },
        {
          RaceName: "Repecharge 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 5",
          Position: 1,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 3",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Repecharge 4",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final A",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 3",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Final B",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
        {
          RaceName: "Semifinal 3",
          Position: 2,
        },
      ],
    },
  ],
  "4L4E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 4,
        },
      ],
    },
  ],
  "4L5E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
      ],
    },
  ],
  "4L6E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "4L7E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 3,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 4,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
  ],
  "4L8E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 3,
        },
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 4,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 4,
        },
      ],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
      ],
    },
  ],
  "4L10E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
        {
          RaceName: "Heat 3",
          Position: 3,
        },
        {
          RaceName: "Heat 1",
          Position: 4,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Repecharge 1",
          Position: 2,
        },
      ],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
      ],
    },
  ],
  "4L11E": [
    {
      RaceName: "Heat 1",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 2",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Heat 3",
      RaceIsPreSet: true,
      RacePositions: [],
    },
    {
      RaceName: "Repecharge 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 3,
        },
        {
          RaceName: "Heat 2",
          Position: 4,
        },
      ],
    },
    {
      RaceName: "Repecharge 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 3",
          Position: 3,
        },
        {
          RaceName: "Heat 1",
          Position: 4,
        },
        {
          RaceName: "Heat 2",
          Position: 3,
        },
      ],
    },
    {
      RaceName: "Semifinal 1",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 2",
          Position: 2,
        },
        {
          RaceName: "Heat 1",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 1,
        },
        {
          RaceName: "Repecharge 1",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Semifinal 2",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Heat 1",
          Position: 2,
        },
        {
          RaceName: "Heat 2",
          Position: 1,
        },
        {
          RaceName: "Heat 3",
          Position: 2,
        },
        {
          RaceName: "Repecharge 2",
          Position: 1,
        },
      ],
    },
    {
      RaceName: "Finals",
      RaceIsPreSet: false,
      RacePositions: [
        {
          RaceName: "Semifinal 1",
          Position: 2,
        },
        {
          RaceName: "Semifinal 2",
          Position: 1,
        },
        {
          RaceName: "Semifinal 1",
          Position: 1,
        },
        {
          RaceName: "Semifinal 2",
          Position: 2,
        },
      ],
    },
  ],
};

export default RaceConfigs;
