import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import React from "react";

const { Paragraph, Text } = Typography;

const NotAuthorizedPage = () => (
  <Result
    status="error"
    title="Not Authorized"
    subTitle="You do not have permissions to view this page. Please contact Abdullah Ahmed or Umar Mahar if you believe this is an error."
   /*  extra={[
      <Button type="primary" key="console">
        Go Console
      </Button>,
      <Button key="buy">Buy Again</Button>,
    ]} */
  >
    {/* <div className="desc">
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
          The content you submitted has the following error:
        </Text>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        account has been frozen. <a>Thaw immediately &gt;</a>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        account is not yet eligible to apply. <a>Apply Unlock &gt;</a>
      </Paragraph>
    </div> */}
  </Result>
);

export default NotAuthorizedPage;
