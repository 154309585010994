import React from "react";
import { useState, useEffect } from "react";

import { Modal, Input, Button } from "antd";

import { UpdateEmployeePassword } from "../../APIManager";

export default function ChangeUserPassword({
  userid,
  username,
  modalVisible,
  onCloseModal,
}) {
  const [newPassword, SetNewPassword] = useState("");
  const [showPassword, SetShowPassword] = useState(false);
  const [newPasswordIsValid, SetNewPasswordIsValid] = useState(false);

  useEffect(() => {
    if (newPassword.length > 8) {
      SetNewPasswordIsValid(true);
    } else {
      SetNewPasswordIsValid(false);
    }
  }, [newPassword]);

  return (
    <div>
      <Modal
        title={`Change Password of ${username}`}
        forceRender
        visible={modalVisible}
        onOk={() => {
          UpdateEmployeePassword(userid, newPassword).then((Res) => {
            onCloseModal();
          });
        }}
        onCancel={onCloseModal}
        okText="Change"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          disabled: !newPasswordIsValid,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <Input
          type={showPassword ? "text" : "password"}
          value={newPassword}
          onChange={(v) => {
            SetNewPassword(v.target.value);
          }}
        />{" "}
        <div style={{ userSelect: "none", color: "red" }}>
          {newPasswordIsValid ? "" : "Password must atleast 9 characters long"}
        </div>
        <div
          onClick={() => {
            SetShowPassword(!showPassword);
          }}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          {showPassword ? "Hide Password" : "Show Password"}
        </div>
        <br />
        <Button
          onClick={() => {
            var password = String(
              Math.floor(100000000 + Math.random() * 900000000)
            );
            SetNewPassword(password);
          }}
        >
          Generate Random Password
        </Button>
      </Modal>
    </div>
  );
}
