import React from "react";

import { useState, useEffect } from "react";

import { GetAdminUsers, DeleteEmployeeAccount } from "../../APIManager";

import { Space, Table, Popconfirm, Button } from "antd";

import ChangeUserPassword from "./ChangeUserPassword";
import AddNewEmployee from "./AddNewEmployee";
import PermissionsModal from "./PermissionsModal";

export default function UserManagement() {
  const [users, SetUsers] = useState([]);

  const [passwordModalVisiible, SetPasswordModalVisible] = useState(false);
  const [addNewEmployeeVisiible, SetAddNewEmployeeVisible] = useState(false);
  const [permissionsModalVisible, SetPermissionsComponentVisible] =
    useState(false);
  useEffect(() => {
    FetchUsers();
  }, []);

  function FetchUsers() {
    GetAdminUsers().then((res) => {
      SetUsers(res.data);
    });
  }

  const [SelectedUserID, SetSelectedUserID] = useState("");

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              SetSelectedUserID(record._id);
              SetPermissionsComponentVisible(true);
            }}
          >
            Permissions
          </a>
          <a
            onClick={() => {
              SetSelectedUserID(record._id);
              SetPasswordModalVisible(true);
            }}
          >
            Change Password
          </a>
          <Popconfirm
            placement="leftTop"
            title={"Are you sure you want to delete?"}
            onConfirm={() => {
              DeleteEmployeeAccount(record._id).then(() => {
                FetchUsers();
              });
            }}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>UserManagement</h1>
      <Button
        type="primary"
        onClick={() => {
          SetAddNewEmployeeVisible(true);
        }}
      >
        Add New User
      </Button>
      <br />
      <br />
      <ChangeUserPassword
        userid={SelectedUserID}
        username={users.filter((u) => u._id == SelectedUserID)[0]?.username}
        onCloseModal={() => {
          SetPasswordModalVisible(false);
        }}
        modalVisible={passwordModalVisiible}
      />
      <PermissionsModal
        user={users.filter((u) => u._id == SelectedUserID)[0]}
        onCloseModal={() => {
          SetPermissionsComponentVisible(false);
          FetchUsers();
        }}
        modalVisible={permissionsModalVisible}
      />
      <AddNewEmployee
        modalVisible={addNewEmployeeVisiible}
        onCloseModal={() => {
          SetAddNewEmployeeVisible(false);
          FetchUsers();
        }}
        existingUsers={users}
      />
      <Table
        columns={columns}
        dataSource={users}
        pagination={{ defaultPageSize: 50 }}
      />
    </div>
  );
}
