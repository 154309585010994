import "./App.css";
import "antd/dist/antd.less";
import { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { UserContext, UserProvider } from "./UserContext";
import { getLoggedInUser } from "./APIManager";
import LoginPage from "./Components/LoginPage/LoginPage";
import MainLayout from "./Components/MainLayout/MainLayout";

function App() {
  const [user, setUser] = useContext(UserContext);
  const [isResolved, setResolved] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  useEffect(() => {
    FetchUserDetails(
      isResolved,
      setUser,
      currentPath,
      setCurrentPath,
      setResolved
    );
  });

  if (!isResolved) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="*">
          <MainLayout />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

function FetchUserDetails(
  isResolved,
  setUser,
  currentPath,
  setCurrentPath,
  setResolved
) {
  //  console.log("hooray");
  if (isResolved !== true) {
    getLoggedInUser().then((res) => {
      // console.log(res);
      setUser(res.data);
      setCurrentPath(window.location.href);
      setResolved(true);
    });
    //setResolved(true);
  } else {
    if (currentPath !== window.location.href) {
      getLoggedInUser().then((res) => {
        // console.log(res);
        setUser(res.data);
        setResolved(true);
      });
      setCurrentPath(window.location.href);
    }
  }
}
