import React from "react";

import { Modal, Input, Button, Select, Form } from "antd";

import { useState, useEffect } from "react";

import { GetDefaultRaceDuration } from "../../utility";

import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default function ChangeTimeModal({
  modalVisible,
  onCloseModal,
  OnSubmit,
  racename,
}) {
  const [timeStart, SetTimeStart] = useState();
  const [timeEnd, SetTimeEnd] = useState();

  useEffect(() => {
    SetTimeStart();
    SetTimeEnd();
  }, [modalVisible]);

  useEffect(() => {
    if (timeStart) {
      var tempTimeStart = timeStart.clone();
      SetTimeEnd(tempTimeStart.add(GetDefaultRaceDuration(racename), "minute"));
      //  console.log(timeStart.toDate().getHours());
    } else {
      SetTimeEnd();
    }
  }, [timeStart, racename]);

  return (
    <div>
      <Modal
        title={`Change Race Time`}
        forceRender
        visible={modalVisible}
        onOk={() => {
          OnSubmit({
            StartHours: timeStart.toDate().getHours(),
            StartMinutes: timeStart.toDate().getMinutes(),
            EndHours: timeEnd.toDate().getHours(),
            EndMinutes: timeEnd.toDate().getMinutes(),
          });
        }}
        onCancel={onCloseModal}
        okText="Change"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          disabled: !timeStart || !timeEnd,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        Start Time:
        <TimePicker
          style={{ marginLeft: "1em" }}
          value={timeStart}
          use12Hours
          format="h:mm a"
          onChange={(e) => {
            SetTimeStart(e);
          }}
        />
        <br />
        <br />
        End Time:
        <TimePicker
          style={{ marginLeft: "1.4em" }}
          value={timeEnd}
          use12Hours
          format="h:mm a"
          disabled
          onChange={(e) => {
            // SetTimeStart(e);
          }}
        />
      </Modal>
    </div>
  );
}
