import { React, useContext } from "react";
import { Table, Button, Modal, Spin } from "antd";
import { UserContext } from "../../UserContext";
import { GetSchoolInfo } from "../../APIManager";

import TeamEventRegistrations from "./SchoolEventRegistrations/TeamEventRegistrations";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";
export default function SchoolEntries({ schoolid, modalVisible, closeModal }) {
  const history = useHistory();
  const [school, SetSchool] = useState({});
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  useEffect(() => {
    if (schoolid) {
      SetWaitingOnResponse(true);
      GetSchoolInfo(schoolid).then((res) => {
        SetWaitingOnResponse(false);
        SetSchool(res.data);
      });
    }
  }, [schoolid]);

  if (!schoolid || !school.username) {
    return null;
  }

  return (
    <Modal
      title="School Entries"
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={closeModal}
      okText="Delete"
      cancelText="cancel"
      width="1000px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <div>
        <div>
          <h2>Registered Events:</h2>
          {waitingOnResponse ? (
            <div>
              <h1 style={{ fontSize: "1.5em" }}>Loading</h1>
              <Spin size="large" />
            </div>
          ) : (
            <div>
              {school.evententries.length > 0 ? <></> : <h2>Not yet filled</h2>}
              <ul>
                {school.evententries.map((race) => {
                  return <li>{race}</li>;
                })}
              </ul>
              <TeamEventRegistrations schoolID={schoolid} />
            </div>
          )}
        </div>
        <br />
      </div>
    </Modal>
  );
}
