import React, { useEffect, useState } from "react";

import {
  Button,
  Space,
  Modal,
  Input,
  Form,
  Checkbox,
  message,
  Select,
  Collapse,
  Descriptions,
  Badge,
  Tag,
} from "antd";

import {
  GetSchools,
  GetRowers,
  GetRaces,
  GetEventEntries,
  GetPenaltyCards,
  AddNewPenaltyCard,
  DeletePenlatyCard,
  AddNewDisqualification,
  GetDisqualifications,
  DeleteDisqualification,
} from "../../APIManager";

import redCardIcon from "../../Static/redcardicon.png";
import yellowCardIcon from "../../Static/yellowcardicon.png";

const { Panel } = Collapse;

const DisqualificationsPage = () => {
  //API Data
  const [schools, SetSchools] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [races, SetRaces] = useState([]);
  const [eventEntries, SetEventEntries] = useState([]);
  const [penaltyCards, SetPenaltyCards] = useState([]);
  const [disqualifications, SetDisqualifications] = useState([]);

  //UI Control
  const [addCardModalVisiible, SetAddCardModalVisible] = useState(false);

  useEffect(() => {
    FetchData();
  }, []);

  function FetchData() {
    GetSchools().then((res) => {
      SetSchools(res.data);
    });
    GetRowers().then((res) => {
      SetRowers(res.data);
    });
    GetRaces().then((res) => {
      SetRaces(res.data);
    });
    GetEventEntries().then((res) => {
      SetEventEntries(res.data);
    });

    GetPenaltyCards().then((res) => {
      SetPenaltyCards(res.data);
    });

    GetDisqualifications().then((res) => {
      SetDisqualifications(res.data);
    });
  }

  function OnCardDelete(cardID) {
    DeletePenlatyCard(cardID).then((res) => {
      FetchData();
    });
  }

  function GetTagColor(type) {
    switch (type) {
      case "Did Not Start":
        return "orange";
      case "Did Not Finish":
        return "purple";
      case "Disqualified":
        return "red";

      default:
        break;
    }
  }

  return (
    <>
      <AddCardModal
        modalVisible={addCardModalVisiible}
        OnCancel={() => {
          SetAddCardModalVisible(false);
        }}
        rowers={rowers}
        schools={schools}
        races={races}
        eventEntries={eventEntries}
        FetchData={FetchData}
        OnSubmit={() => {
          FetchData();
          SetAddCardModalVisible(false);
        }}
      />
      <h1>Penalty Cards Page</h1>
      <div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              SetAddCardModalVisible(true);
            }}
          >
            Add Card
          </Button>
        </Space>
      </div>
      <div style={{ width: "100%" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Collapse accordion>
            {disqualifications.map((dq, index) => (
              <Panel
                key={`Penalty Card Panel ${index}`}
                header={
                  <>
                    <Tag color={GetTagColor(dq.Type)}>
                      <strong>{dq.ShortType}</strong>
                    </Tag>
                    {dq.School.schoolinitials} - {dq.RaceName}
                  </>
                }
              >
                <Descriptions bordered>
                  <Descriptions.Item label="DQ Type" span={1}>
                    {dq.Type}
                  </Descriptions.Item>
                  <Descriptions.Item label="School" span={2}>
                    {dq.School.schoolname}
                  </Descriptions.Item>

                  <Descriptions.Item label="Race Name" span={3}>
                    {dq.RaceName}
                  </Descriptions.Item>

                  <Descriptions.Item label="Reason" span={6}>
                    {dq.Reason.split("\n").map((str) => (
                      <p>{str}</p>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item label="Signed By">
                    {dq.SignedBy}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date and Time">
                    {`${new Date(dq.createdAt).toDateString(
                      "en-US"
                    )} ${new Date(dq.createdAt).toLocaleTimeString("en-US")}`}
                  </Descriptions.Item>
                </Descriptions>{" "}
                <br />
                <Button
                  onClick={() => {
                    OnCardDelete(dq._id);
                  }}
                >
                  Delete
                </Button>
              </Panel>
            ))}
          </Collapse>
        </Space>
      </div>
    </>
  );
};

const AddCardModal = ({
  modalVisible,
  OnSubmit,
  OnCancel,
  rowers,
  schools,
  races,
  eventEntries,
}) => {
  const [form] = Form.useForm();

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const [selectedEventName, SetSelectedEventName] = useState("");
  const [selectedSchoolID, SetSelectedSchoolID] = useState("");

  const [requiredFields, SetRequiredFields] = useState([
    "CardType",
    "AssignType",
  ]);

  const handleFormValuesChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];

    console.log(fieldName);

    if (fieldName === "SchoolID") {
      const value = changedValues[fieldName];
      SetSelectedSchoolID(value);
      form.setFieldsValue({
        Application: null,
        RaceName: null,
        EventName: null,
      });
    }

    if (fieldName === "EventName") {
      const value = changedValues[fieldName];
      SetSelectedEventName(value);
      form.setFieldsValue({
        RaceName: null,
      });
    }
  };

  function OnFinish() {
    const formFields = form.getFieldsValue();
    const { Type, EventName, RaceName, SchoolID, Reason, SignName } =
      formFields;

    AddNewDisqualification(
      Type,
      EventName,
      RaceName,
      Reason,
      SignName,
      SchoolID
    ).then(() => {
      form.resetFields();
      OnSubmit();
    });
  }

  function GetSchoolRaces(schoolID, eventName) {
    if (!schoolID) {
      return [];
    }
    console.log(schoolID);

    //Get All races with school
    const filteredRaces = races
      .filter((r) => {
        if (r.Participants.map((p) => p.school).includes(schoolID)) {
          return true;
        }
        return false;
      })
      .filter((r) => r.EventName == eventName);

    return filteredRaces;
  }

  function GetSchoolEvents(schoolID) {
    if (!schoolID) {
      return [];
    }

    const filteredEntries = eventEntries.filter((e) => e.Team == schoolID);

    return filteredEntries;
  }

  function AllFieldsFilled() {
    const formFields = form.getFieldsValue();
    var isComplete = true;
    try {
      Object.keys(formFields).forEach(function (key, index) {
        if (!formFields[key]) {
          isComplete = false;
        }
      });
    } catch (error) {
      isComplete = false;
      console.log(error);
    }

    return isComplete;
  }

  return (
    <Modal
      title={`Add new penalty card`}
      forceRender
      visible={modalVisible}
      onCancel={OnCancel}
      okText="Add User"
      cancelText="Cancel"
      width="600px"
      okButtonProps={{ hidden: true }}
      // cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={OnFinish}
        //onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={"horizontal"}
        size="large"
        onChange={() => {
          // SetMessage(form.getFieldsValue());
          console.log(form.getFieldsValue());
          //console.log(form.getFieldsError())
          // console.log(form.isFieldsTouched(["SchoolColor2"]));
        }}
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item
          label="Type of Disqualification"
          name="Type"
          rules={[
            {
              required: true,
              message: "Please enter type of disqualification",
            },
          ]}
        >
          <Select
            options={[
              { value: "Did Not Start", label: "Did Not Start (DNS)" },
              { value: "Did Not Finish", label: "Did Not Finish (DNF)" },
              { value: "Disqualified", label: "Disqualified (DSQ)" },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Select School"
          name="SchoolID"
          rules={[
            {
              required: true,
              message: "Please select a school",
            },
          ]}
        >
          <Select
            showSearch
            options={schools.map((s) => {
              return {
                value: s._id,
                label: `${s.schoolname}`,
              };
            })}
            filterOption={(e, j) => {
              return j.label
                .toLocaleLowerCase()
                .includes(e.toLocaleLowerCase());
            }}
          />
        </Form.Item>
        <Form.Item
          label="Select Event"
          name="EventName"
          rules={[
            {
              required: true,
              message: "Please select an Event",
            },
          ]}
        >
          <Select
            showSearch
            options={GetSchoolEvents(selectedSchoolID).map((r) => {
              return {
                value: r.RaceName,
                label: `${r.RaceName}`,
              };
            })}
            filterOption={(e, j) => {
              return j.label
                .toLocaleLowerCase()
                .includes(e.toLocaleLowerCase());
            }}
          />
        </Form.Item>
        <Form.Item
          label="Select Race"
          name="RaceName"
          rules={[
            {
              required: true,
              message: "Please select a Race",
            },
          ]}
        >
          <Select
            showSearch
            options={GetSchoolRaces(selectedSchoolID, selectedEventName).map(
              (r) => {
                return {
                  value: r.RaceName,
                  label: `${r.RaceName}`,
                };
              }
            )}
            filterOption={(e, j) => {
              return j.label
                .toLocaleLowerCase()
                .includes(e.toLocaleLowerCase());
            }}
          />
        </Form.Item>

        <Form.Item
          label="Describe reason for awarding disqualification"
          labelCol={{ span: 24 }}
          name="Reason"
          rules={[
            {
              required: true,
              message: "Please describe the reason",
            },
          ]}
        >
          <Input.TextArea spellCheck autoSize />
        </Form.Item>
        <Form.Item
          label="Sign your name "
          name="SignName"
          rules={[
            {
              required: true,
              message: "Please sign your name below",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            // console.log(form.isFieldsTouched(["Declration"]))
            return (
              <Button
                size={"large"}
                block
                type="primary"
                htmlType="submit"
                disabled={!AllFieldsFilled()}
              >
                Submit
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DisqualificationsPage;

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      // console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}
