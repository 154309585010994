import React, { useState, useEffect } from "react";
import {
  GetSchoolPaymentDetails,
  UpdateSchoolPaymentStatus,
  UpdateSchoolPaymentWaivedStatus,
} from "../../APIManager";

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Statistic,
  Table,
  Space,
  message,
  Popconfirm,
} from "antd";

export default function NewPaymentsPage() {
  const [paymentDetails, SetPaymentDetails] = useState({});

  console.log(paymentDetails);

  useEffect(() => {
    FetchDetails();
  }, []);

  function FetchDetails() {
    GetSchoolPaymentDetails().then((res) => {
      SetPaymentDetails(res.data);
    });
  }

  const columns = [
    {
      title: "Schoool Name",
      dataIndex: "schoolname",
      sorter: (a, b) => a.schoolname.localeCompare(b.schoolname),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.schoolname.indexOf(value) === 0,
    },
    {
      title: "Total Fees",
      dataIndex: "TotalFees",
    },
    {
      title: "Payment",
      dataIndex: "PaymentReceived",
      key: "PaymentReceived",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to change the payment status of this school?"
            onConfirm={() => {
              UpdateSchoolPaymentStatus(record._id, !text).then(() => {
                FetchDetails();
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            {CheckRenderer(text)}
          </Popconfirm>
        );
      },
    },
    {
      title: "PaymentWaived",
      dataIndex: "PaymentWaived",
      key: "PaymentWaived",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to change the payment waiver status of this school?"
            onConfirm={() => {
              UpdateSchoolPaymentWaivedStatus(record._id, !text).then(() => {
                FetchDetails();
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            {CheckRenderer(text)}
          </Popconfirm>
        );
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {/*    <a
    //         onClick={() => {
    //           SetSelectedSchoolID(record._id);
    //           SetIsDeleteModalVisible(true);
    //         }}
    //       >
    //         Delete
    //       </a> */}
    //       <a onClick={() => {}}>Details</a>
    //       <a onClick={() => {}}>Entries</a>
    //       <a onClick={() => {}}>Rowers</a>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div>
      <div style={{ padding: "30px", background: " #ececec" }}>
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <Statistic
                title="Total Payments"
                value={paymentDetails.TotalFees}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={"Rs. "}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Payments Collected"
                value={paymentDetails.CollectedFees}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={"Rs. "}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Payments Due"
                value={paymentDetails.UnCollectedFees}
                precision={0}
                valueStyle={{ color: "#cf1322" }}
                prefix={"Rs. "}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <br />
      <div>
        {" "}
        <Table
          columns={columns}
          dataSource={paymentDetails.Schools}
          pagination={{ defaultPageSize: 50 }}
        />
      </div>
    </div>
  );
}

function CheckRenderer(text) {
  if (text === true) {
    return <CheckCircleTwoTone twoToneColor="#52c41a" />;
  } else {
    return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
  }
}
