import React, { useState } from "react";
import { Collapse, Table, Typography, Card, Input } from "antd";

const { Panel } = Collapse;
const { Title } = Typography;

// Editable fields configuration
const editableFieldsConfig = {
  participant: false,
  class: false,
  lane: true,
  place: true,
  score: true,
  calories: false,
  avg_pace: true,
  time: true,
};

// Sample data
const raceData = {
  "2000m Race Individual with 500m splits": {
    participants: [
      {
        avg_pace: "1:38.8",
        bib_number: 0,
        calories: 168,
        class: "Mens Heavyweight",
        lane: 1,
        logged_time: "26/07/2018 09:52:00",
        machine_type: "row",
        participant: "Abe Lincoln",
        place: 1,
        score: 2000,
        serial_number: 430395299,
        splits: [
          {
            split_calories: 42,
            split_distance: 500,
            split_drag_factor: 138,
            split_running_calories: 42,
            split_running_distance: 500,
            split_running_time: 983,
            split_stroke_count: 55,
            split_stroke_rate: 34,
            split_time: 983,
            split_type: "distance_split",
          },
          {
            split_calories: 42,
            split_distance: 500,
            split_drag_factor: 147,
            split_running_calories: 84,
            split_running_distance: 1000,
            split_running_time: 1971,
            split_stroke_count: 55,
            split_stroke_rate: 33,
            split_time: 988,
            split_type: "distance_split",
          },
          {
            split_calories: 41,
            split_distance: 500,
            split_drag_factor: 149,
            split_running_calories: 125,
            split_running_distance: 1500,
            split_running_time: 2975,
            split_stroke_count: 55,
            split_stroke_rate: 33,
            split_time: 1004,
            split_type: "distance_split",
          },
          {
            split_calories: 43,
            split_distance: 500,
            split_drag_factor: 135,
            split_running_calories: 168,
            split_running_distance: 2000,
            split_running_time: 3953,
            split_stroke_count: 55,
            split_stroke_rate: 34,
            split_time: 978,
            split_type: "distance_split",
          },
        ],
        spm: 33,
        time: "6:35.3",
      },
      {
        avg_pace: "1:21.7",
        bib_number: 0,
        calories: 226,
        class: "Lightweight",
        lane: 5,
        logged_time: "26/07/2018 09:51:00",
        machine_type: "row",
        participant: "Lane_5",
        place: 1,
        score: 2000,
        serial_number: 430310809,
        splits: [
          {
            split_calories: 59,
            split_distance: 500,
            split_drag_factor: 219,
            split_running_calories: 59,
            split_running_distance: 500,
            split_running_time: 798,
            split_stroke_count: 59,
            split_stroke_rate: 44,
            split_time: 798,
            split_type: "distance_split",
          },
          {
            split_calories: 53,
            split_distance: 500,
            split_drag_factor: 240,
            split_running_calories: 112,
            split_running_distance: 1000,
            split_running_time: 1651,
            split_stroke_count: 59,
            split_stroke_rate: 41,
            split_time: 853,
            split_type: "distance_split",
          },
          {
            split_calories: 55,
            split_distance: 500,
            split_drag_factor: 231,
            split_running_calories: 167,
            split_running_distance: 1500,
            split_running_time: 2478,
            split_stroke_count: 59,
            split_stroke_rate: 43,
            split_time: 827,
            split_type: "distance_split",
          },
          {
            split_calories: 59,
            split_distance: 500,
            split_drag_factor: 214,
            split_running_calories: 226,
            split_running_distance: 2000,
            split_running_time: 3270,
            split_stroke_count: 59,
            split_stroke_rate: 45,
            split_time: 792,
            split_type: "distance_split",
          },
        ],
        spm: 43,
        time: "5:27.1",
      },
    ],
  },
};

const RaceDetails = () => {
  const race = Object.keys(raceData)[0];
  const [participants, setParticipants] = useState(raceData[race].participants);

  // Editable cell renderer
  const renderEditableCell = (value, record, column) => {
    if (editableFieldsConfig[column]) {
      return (
        <Input
          defaultValue={value}
          onChange={(e) =>
            handleEdit(record.serial_number, column, e.target.value)
          }
        />
      );
    }
    return value;
  };

  // Handle edit and update state
  const handleEdit = (serialNumber, field, value) => {
    setParticipants((prevParticipants) =>
      prevParticipants.map((participant) =>
        participant.serial_number === serialNumber
          ? { ...participant, [field]: value }
          : participant
      )
    );
  };

  // Table columns for participant summary
  const participantColumns = [
    {
      title: "Participant",
      dataIndex: "participant",
      key: "participant",
      render: (text, record) => renderEditableCell(text, record, "participant"),
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      render: (text, record) => renderEditableCell(text, record, "class"),
    },
    {
      title: "Lane",
      dataIndex: "lane",
      key: "lane",
      render: (text, record) => renderEditableCell(text, record, "lane"),
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      render: (text, record) => renderEditableCell(text, record, "place"),
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (text, record) => renderEditableCell(text, record, "score"),
    },
    {
      title: "Calories",
      dataIndex: "calories",
      key: "calories",
      render: (text, record) => renderEditableCell(text, record, "calories"),
    },
    {
      title: "Avg Pace",
      dataIndex: "avg_pace",
      key: "avg_pace",
      render: (text, record) => renderEditableCell(text, record, "avg_pace"),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text, record) => renderEditableCell(text, record, "time"),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Title level={3}>{race}</Title>
      <Collapse accordion>
        {participants.map((participant, index) => (
          <Panel
            header={`${participant.participant} - ${participant.class}`}
            key={index}
          >
            <Card>
              <Table
                columns={participantColumns}
                dataSource={[participant]}
                pagination={false}
                rowKey="serial_number"
                size="small"
                bordered
              />
            </Card>
            <Title level={5} style={{ marginTop: "16px" }}>
              500m Splits
            </Title>
            <Table
              columns={[
                {
                  title: "Distance",
                  dataIndex: "split_distance",
                  key: "split_distance",
                },
                { title: "Time", dataIndex: "split_time", key: "split_time" },
                {
                  title: "Calories",
                  dataIndex: "split_calories",
                  key: "split_calories",
                },
                {
                  title: "Stroke Rate",
                  dataIndex: "split_stroke_rate",
                  key: "split_stroke_rate",
                },
                {
                  title: "Drag Factor",
                  dataIndex: "split_drag_factor",
                  key: "split_drag_factor",
                },
              ]}
              dataSource={participant.splits}
              pagination={false}
              rowKey={(record, i) => i}
              size="small"
              bordered
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default RaceDetails;
