import React from "react";

import { Modal, Input, Button, Select, Form } from "antd";

import { useState, useEffect } from "react";

import { GetDefaultRaceDuration } from "../../utility";

import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default function InsertBreakModal({
  modalVisible,
  onCloseModal,
  OnSubmit,
  isEditMode,
}) {
  const [timeStart, SetTimeStart] = useState();
  const [timeEnd, SetTimeEnd] = useState();

  const [errorText, SetErrorText] = useState("");

  useEffect(() => {
    SetTimeStart();
    SetTimeEnd();
  }, [modalVisible]);

  useEffect(() => {
    if (!timeStart || !timeEnd) {
      SetErrorText("");
      return;
    }

    if (timeStart.toDate() > timeEnd.toDate()) {
      SetErrorText("End time must be after Start Time ");
    } else {
      SetErrorText("");
    }
  }, [timeStart, timeEnd]);

  return (
    <div>
      <Modal
        title={isEditMode ? "Edit Break Time" : `Insert Break`}
        forceRender
        visible={modalVisible}
        onOk={() => {
          OnSubmit({
            StartHours: timeStart.toDate().getHours(),
            StartMinutes: timeStart.toDate().getMinutes(),
            EndHours: timeEnd.toDate().getHours(),
            EndMinutes: timeEnd.toDate().getMinutes(),
          });
        }}
        onCancel={onCloseModal}
        okText={isEditMode ? "Change" : "Add"}
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          disabled: !timeStart || !timeEnd || errorText,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        Start Time:
        <TimePicker
          style={{ marginLeft: "1em" }}
          value={timeStart}
          use12Hours
          format="h:mm a"
          onChange={(e) => {
            SetTimeStart(e);
          }}
        />
        <br />
        <br />
        End Time:
        <TimePicker
          style={{ marginLeft: "1.4em" }}
          value={timeEnd}
          use12Hours
          format="h:mm a"
          onChange={(e) => {
            SetTimeEnd(e);
          }}
        />
        <br />
        <div style={{ color: "red" }}>{errorText}</div>
      </Modal>
    </div>
  );
}
